<template>
  <div class="modal-mask">
    <OkModal
      v-if="showAuthError"
      v-bind:message="authMessage"
      v-bind:header="authHeader"
      v-on:confirm="hideAuthMessage"
    />
    <OkModal
      v-if="showStatusModal"
      v-bind:message="statusMessage"
      v-bind:header="statusHeader"
      v-on:confirm="hideStatusMessage"
    />
    <div class="modal-window">
      <div class="header">
        Добавление нового сопоставления для группы итогов
      </div>
      <div class="body">
        <form>
          <div class="form-group">
            <label>Направление использования</label>
            <select
              class="form-select"
              v-model="selectedUsageId"
              aria-label="Направление использования"
            >
              <option
                v-for="usage in usages"
                v-bind:value="usage.id"
                v-bind:key="usage.description"
              >
                {{ usage.description }}
              </option>
            </select>
          </div>
          <div class="form-group">
            <label>Полезное ископаемое</label>
            <select
              class="form-select"
              v-model="selectedComponentId"
              aria-label="Полезное ископаемое"
            >
              <option
                v-for="component in components"
                v-bind:value="component.id"
                v-bind:key="component.description"
              >
                {{ component.description }}
              </option>
            </select>
          </div>
          <div class="form-group" style="margin-top: 10px">
            <button @click="save" class="btn btn-dark btn-lg btn-block save">
              Сохранить
            </button>
            <button @click="cancel" class="btn btn-dark btn-lg btn-block close">
              Отменить
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
//import BootstrapIcon from "@dvuckovic/vue3-bootstrap-icons";
import OkModal from "./OkModal.vue";

export default {
  name: "SumGroupMappingNewModal",
  props: ["group_id"],
  data() {
    return {
      usages: [],
      components: [],
      selectedComponentId: null,
      selectedUsageId: null,
      showAuthError: false,
      authMessage: "Доступ запрещен",
      authHeader: "Ошибка безопасности",
      showStatusModal: false,
      statusMessage: "Данные были сохранены",
      statusHeader: "Сохранение данных",
    };
  },
  methods: {
    hideStatusMessage() {
      this.showStatusModal = false;
      this.$emit("save", {});
    },
    hideAuthMessage() {
      this.showAuthError = false;
    },
    save(e) {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      var url = this.$BASE_URL + "/api/add_group_mapping/";
      axios
        .post(
          url,
          {
            group_id: this.group_id,
            id_component: this.selectedComponentId,
            id_usage: this.selectedUsageId,
          },
          { headers }
        )
        .then((response) => {
          if (!response.data[0].auth_fail) {
            if (response.data[0].result) {
              this.showStatusModal = false;
              this.$emit("save", {});
            }
          } else {
            this.showAuthError = true;
          }
        });

      e.preventDefault();
    },
    cancel(e) {
      this.$emit("cancel", {});
      e.preventDefault();
    },
    getUsage() {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      var url = this.$BASE_URL + "/api/get_dictionary_contents/";
      axios
        .post(
          url,
          { category: "SPR_PRIM", offset: 0, limit: 10000 },
          { headers }
        )
        .then((response) => {
          if (!response.data.auth_fail) {
            this.usages = response.data.result;
            if (response.data.result.length > 0) {
              this.selectedUsageId = response.data.result[0].id;
            }
          }
        });
    },
    getComponents() {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      var url = this.$BASE_URL + "/api/get_dictionary_contents/";
      axios
        .post(
          url,
          { category: "SPR_POL", offset: 0, limit: 10000 },
          { headers }
        )
        .then((response) => {
          if (!response.data.auth_fail) {
            this.components = response.data.result;
            if (response.data.result.length > 0) {
              this.selectedComponentId = response.data.result[0].id;
            }
          }
        });
    },
  },
  components: {
    //BootstrapIcon,
    OkModal,
  },
  mounted() {
    this.getComponents();
    this.getUsage();
  },
};
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s ease;
}

.modal-window {
  border: rgb(169, 255, 202);
  background-color: white;
  position: fixed;
  width: 500px;
  height: 500px;
  top: 50%;
  left: 50%;
  margin-top: -250px;
  margin-left: -250px;
  z-index: 100;
}

.header {
  height: 30px;
  width: 100%;
  background-color: rgb(143, 150, 150);
  text-align: center;
  font-weight: bold;
}

.body {
  font-weight: bolder;
  color: black;
  text-align: center;
}

.close {
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.save {
  position: absolute;
  bottom: 10px;
  left: 10px;
}
</style>
