<template>
  <div class="modal-mask">
    <div class="modal-window">
      <OkModal
        v-if="showError"
        v-bind:message="errorMessage"
        v-bind:header="header"
        v-on:confirm="hideErrorMessage"
      />
      <OkModal
        v-if="showAuthError"
        v-bind:message="authMessage"
        v-bind:header="authHeader"
        v-on:confirm="hideAuthMessage"
      />
      <div class="header">Добавление новой записи для запасов</div>
      <div class="body">
        <div class="body">
          <form class="form-inline">
            <table style="border: none !important">
              <tr>
                <td>
                  <div class="form-group col-xs-3">
                    <label>Полезное ископаемое</label>
                    <select
                      class="form-select form-control-lg"
                      v-model="selectedMineralId"
                      aria-label="Полезное ископаемое"
                    >
                      <option
                        v-for="mineral in minerals"
                        v-bind:value="mineral.id"
                        v-bind:key="mineral.id"
                      >
                        {{ mineral.description }}
                      </option>
                    </select>
                  </div>
                </td>
                <td>
                  <div class="form-group col-xs-3">
                    <label>Направление использования</label>
                    <select
                      class="form-select form-control-lg"
                      v-model="selectedUsageId"
                      aria-label="Направление использования"
                    >
                      <option
                        v-for="usage in usages"
                        v-bind:value="usage.id"
                        v-bind:key="usage.id"
                      >
                        {{ usage.description }}
                      </option>
                    </select>
                  </div>
                </td>
                <td>
                  <div class="form-group col-xs-3">
                    <label>Группа итогов</label>
                    <select
                      class="form-select form-control-lg"
                      v-model="selectedGroupId"
                      aria-label="Группа итогов"
                    >
                      <option
                        v-for="group in groups"
                        v-bind:value="group.id_group"
                        v-bind:key="group.id_group"
                      >
                        {{ group.group_name }}
                      </option>
                    </select>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="form-group col-xs-3">
                    <label>Еденица измерения руды</label>
                    <select
                      class="form-select form-control-lg"
                      v-model="selectedMeasureOreId"
                      aria-label="Еденица измерения руды"
                    >
                      <option
                        v-for="measure in measuresOre"
                        v-bind:value="measure.id"
                        v-bind:key="measure.id"
                      >
                        {{ measure.description }}
                      </option>
                    </select>
                  </div>
                </td>
                <td>
                  <div class="form-group col-xs-3">
                    <label>A</label>
                    <input
                      type="text"
                      class="form-control form-control-lg"
                      v-model="A"
                      @keypress="checkNumber(event, A)"
                    />
                  </div>
                </td>
                <td>
                  <div class="form-group col-xs-3">
                    <label>B</label>
                    <input
                      type="text"
                      class="form-control form-control-lg"
                      v-model="B"
                      @keypress="checkNumber(event, B)"
                    />
                  </div>
                </td>
                <td>
                  <div class="form-group col-xs-3">
                    <label>C1</label>
                    <input
                      type="text"
                      class="form-control form-control-lg"
                      v-model="C1"
                      @keypress="checkNumber(event, C1)"
                    />
                  </div>
                </td>
                <td>
                  <div class="form-group col-xs-3">
                    <label>C2</label>
                    <input
                      type="text"
                      class="form-control form-control-lg"
                      v-model="C2"
                      @keypress="checkNumber(event, C2)"
                    />
                  </div>
                </td>
                <td>
                  <div class="form-group col-xs-3">
                    <label>Забаланс</label>
                    <input
                      type="text"
                      class="form-control form-control-lg"
                      v-model="outOfBalance"
                      @keypress="checkNumber(event, outOfBalance)"
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="form-group col-xs-3">
                    <label>Не указывать запасы для полезного ископаемого</label>
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      v-model="doNotSpecifyReservesForMineral"
                    />
                  </div>
                </td>
              </tr>
              <tr v-if="!doNotSpecifyReservesForMineral">
                <td>
                  <div class="form-group col-xs-3">
                    <label>Еденица измерения пол. иск.</label>
                    <select
                      class="form-select form-control-lg"
                      v-model="selectedMeasureMId"
                      aria-label="Еденица измерения полезного ископаемого"
                    >
                      <option
                        v-for="measure in measuresM"
                        v-bind:value="measure.id"
                        v-bind:key="measure.id"
                      >
                        {{ measure.description }}
                      </option>
                    </select>
                  </div>
                </td>
                <td>
                  <div class="form-group col-xs-3">
                    <label>A (полезное ископаемое)</label>
                    <input
                      type="text"
                      class="form-control form-control-lg"
                      v-model="A_M"
                      @keypress="checkNumber(event, A_M)"
                    />
                  </div>
                </td>
                <td>
                  <div class="form-group col-xs-3">
                    <label>B (полезное ископаемое)</label>
                    <input
                      type="text"
                      class="form-control form-control-lg"
                      v-model="B_M"
                      @keypress="checkNumber(event, B_M)"
                    />
                  </div>
                </td>
                <td>
                  <div class="form-group col-xs-3">
                    <label>C1 (полезное ископаемое)</label>
                    <input
                      type="text"
                      class="form-control form-control-lg"
                      v-model="C1_M"
                      @keypress="checkNumber(event, C1_M)"
                    />
                  </div>
                </td>
                <td>
                  <div class="form-group col-xs-3">
                    <label>C2 (полезное ископаемое)</label>
                    <input
                      type="text"
                      class="form-control form-control-lg"
                      v-model="C2_M"
                      @keypress="checkNumber(event, C2_M)"
                    />
                  </div>
                </td>
                <td>
                  <div class="form-group col-xs-3">
                    <label>Забаланс (полезное ископаемое)</label>
                    <input
                      type="text"
                      class="form-control form-control-lg"
                      v-model="outOfBalance_M"
                      @keypress="checkNumber(event, outOfBalance_M)"
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="form-group col-xs-4">
                    <label>ГОСТ</label>
                    <select
                      class="form-select form-control-lg"
                      v-model="selectedStandardId"
                      aria-label="ГОСТ"
                    >
                      <option
                        v-for="standard in standards"
                        v-bind:value="standard.id"
                        v-bind:key="standard.id"
                      >
                        {{ standard.description }}
                      </option>
                    </select>
                  </div>
                </td>
                <td>
                  <div class="form-group col-xs-4">
                    <label>Химический компонент</label>
                    <select
                      class="form-select form-control-lg"
                      v-model="selectedChemId"
                      aria-label="Химический компонент"
                    >
                      <option
                        v-for="chemical in chemicals"
                        v-bind:value="chemical.id"
                        v-bind:key="chemical.id"
                      >
                        {{ chemical.description }}
                      </option>
                    </select>
                  </div>
                </td>
                <td>
                  <div class="form-group col-xs-4">
                    <label>Химическая формула</label>
                    <select
                      class="form-select form-control-lg"
                      v-model="selectedChemFormulaId"
                      aria-label="Химическая формула"
                    >
                      <option
                        v-for="formula in formulas"
                        v-bind:value="formula.id"
                        v-bind:key="formula.id"
                      >
                        {{ formula.description }}
                      </option>
                    </select>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="form-group col-xs-4">
                    <label>Год утверждения</label>
                    <input
                      type="text"
                      class="form-control form-control-lg"
                      v-model="year"
                    />
                  </div>
                </td>
                <td>
                  <div class="form-group col-xs-4">
                    <label>Номер протокола</label>
                    <input
                      type="text"
                      class="form-control form-control-lg"
                      v-model="protocol"
                    />
                  </div>
                </td>
                <td>
                  <div class="form-group col-xs-4">
                    <label>Утвердившая организация</label>
                    <!-- input
                      type="text"
                      class="form-control form-control-lg"
                      v-model="organization"
                    / -->
                    <select
                      class="form-select form-control-lg"
                      v-model="organization"
                      aria-label="Организация"
                    >
                      <option
                        v-for="o in organizations"
                        v-bind:value="o"
                        v-bind:key="o"
                      >
                        {{ o }}
                      </option>
                    </select>
                  </div>
                </td>
              </tr>
            </table>
            <div class="form-group" style="margin-top: 10px">
              <button @click="save" class="btn btn-dark btn-lg btn-block save">
                Сохранить
              </button>
              <button
                @click="cancel"
                class="btn btn-dark btn-lg btn-block close"
              >
                Отменить
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import OkModal from "../components/OkModal.vue";
export default {
  name: "ReserveForDepositNewModal",
  props: ["deposit_id", "mine_area_id"],
  data() {
    return {
      organizations: ["ГКЗ", "ТКЗ", "НТС", "ПДКЗ"],
      doNotSpecifyReservesForMineral: true,
      showError: false,
      header: "Не удалось сохранить данные",
      errorMessage: "",
      failed: false,
      depositId: this.deposit_id,
      mineAreaId: this.mine_area_id,
      selectedMineralId: null,
      selectedUsageId: null,
      selectedMeasureOreId: null,
      selectedMeasureMId: null,
      selectedStandardId: null,
      selectedGroupId: null,
      A: 0,
      B: 0,
      C1: 0,
      C2: 0,
      outOfBalance: 0,
      A_M: 0,
      B_M: 0,
      C1_M: 0,
      C2_M: 0,
      outOfBalance_M: 0,
      selectedChemId: null,
      selectedChemFormulaId: null,
      year: "",
      protocol: "",
      organization: "",
      minerals: [],
      usages: [],
      measuresOre: [],
      measuresM: [],
      chemicals: [],
      formulas: [],
      standards: [],
      showAuthError: false,
      authMessage: "Доступ запрещен",
      authHeader: "Ошибка безопасности",
    };
  },
  methods: {
    checkNumber(evt, value) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 44
      ) {
        evt.preventDefault();
      } else {
        if (
          (charCode === 44 || charCode == 46) &&
          (new String(value).includes(",") || new String(value).includes("."))
        ) {
          evt.preventDefault();
        } else {
          return true;
        }
      }
    },
    hideAuthMessage() {
      this.showAuthError = false;
    },
    getMinerals() {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      const url = this.$BASE_URL + "/api/get_dictionary_contents/";
      axios
        .post(
          url,
          { category: "SPR_POL", limit: 10000, offset: 0 },
          { headers }
        )
        .then((response) => {
          if (!response.data.auth_fail) {
            this.minerals = response.data.result;
          }
        });
    },
    getUsages() {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      const url = this.$BASE_URL + "/api/get_dictionary_contents/";
      axios
        .post(
          url,
          { category: "SPR_PRIM", limit: 10000, offset: 0 },
          { headers }
        )
        .then((response) => {
          if (!response.data.auth_fail) {
            this.usages = response.data.result;
          }
        });
    },
    getMeasuresOre() {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      const url = this.$BASE_URL + "/api/get_dictionary_contents/";
      axios
        .post(
          url,
          { category: "SPR_ED_IZM", limit: 10000, offset: 0 },
          { headers }
        )
        .then((response) => {
          if (!response.data.auth_fail) {
            this.measuresOre = response.data.result;
          }
        });
    },
    getMeasuresM() {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      const url = this.$BASE_URL + "/api/get_dictionary_contents/";
      axios
        .post(
          url,
          { category: "SPR_ED_IZM", limit: 10000, offset: 0 },
          { headers }
        )
        .then((response) => {
          if (!response.data.auth_fail) {
            this.measuresM = response.data.result;
          }
        });
    },
    getChemicals() {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      const url = this.$BASE_URL + "/api/get_dictionary_contents/";
      axios
        .post(
          url,
          { category: "SPR_HIM_KOMP", limit: 10000, offset: 0 },
          { headers }
        )
        .then((response) => {
          if (!response.data.auth_fail) {
            this.chemicals = response.data.result;
          }
        });
    },
    getChemicalFormula() {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      const url = this.$BASE_URL + "/api/get_dictionary_contents/";
      axios
        .post(
          url,
          { category: "SPR_HIM_FORMULA", limit: 10000, offset: 0 },
          { headers }
        )
        .then((response) => {
          if (!response.data.auth_fail) {
            this.formulas = response.data.result;
          }
        });
    },
    getStandards() {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      const url = this.$BASE_URL + "/api/get_dictionary_contents/";
      axios
        .post(
          url,
          { category: "SPR_GOST", limit: 10000, offset: 0 },
          { headers }
        )
        .then((response) => {
          if (!response.data.auth_fail) {
            this.standards = response.data.result;
          }
        });
    },
    getGroups() {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      const url = this.$BASE_URL + "/api/get_groups/";
      axios
        .post(url, { group_name: "", limit: 10000, offset: 0 }, { headers })
        .then((response) => {
          if (!response.data[0].auth_fail) {
            this.groups = response.data[0].result;
          }
        });
    },
    save(e) {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      const url = this.$BASE_URL + "/api/add_reserves_for_mine_area/";
      axios
        .post(
          url,
          {
            deposit_id: this.depositId,
            mine_area_id: this.mineAreaId,
            group_id: this.selectedGroupId,
            id_mineral: this.selectedMineralId,
            id_usage: this.selectedUsageId,
            id_measure_ore: this.selectedMeasureOreId,
            A: this.A,
            B: this.B,
            C1: this.C1,
            C2: this.C2,
            out_of_balance: this.outOfBalance,
            use_reserves_for_mineral: !this.doNotSpecifyReservesForMineral,
            id_measure_mineral: this.selectedMeasureMId,
            A_M: this.A_M,
            B_M: this.B_M,
            C1_M: this.C1_M,
            C2_M: this.C2_M,
            M_out_of_balance: this.outOfBalance_M,
            id_chem_component: this.selectedChemId,
            id_chem_formula: this.selectedChemFormulaId,
            id_standard: this.selectedStandardId,
            confirm_year: this.year,
            protocol_number: this.protocol,
            confirm_organization: this.organization,
          },
          { headers }
        )
        .then((response) => {
          if (!response.data[0].auth_fail) {
            if (!response.data[0].result) {
              this.errorMessage = response.data[0].reason;
              this.showError = true;
            } else {
              this.$emit("add-deposit-reserves-close");
            }
          } else {
            this.showAuthError = true;
          }
        });

      e.preventDefault();
    },
    hideErrorMessage() {
      this.showError = false;
    },
    cancel(e) {
      this.$emit("add-deposit-reserves-close");
      e.preventDefault();
    },
  },
  components: {
    OkModal,
  },
  mounted() {
    this.getMinerals();
    this.getMinerals();
    this.getMeasuresOre();
    this.getMeasuresM();
    this.getChemicals();
    this.getUsages();
    this.getChemicalFormula();
    this.getStandards();
    this.getGroups();
  },
};
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9997;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s ease;
}

.modal-window {
  border: rgb(169, 255, 202);
  background-color: white;
  position: fixed;
  width: 1040px;
  height: 700px;
  z-index: 100;
  top: 10%;
}

.header {
  height: 30px;
  width: 100%;
  background-color: rgb(143, 150, 150);
  text-align: center;
  font-weight: bold;
}

.body {
  font-weight: bolder;
  color: black;
  text-align: center;
}

.close {
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.save {
  position: absolute;
  bottom: 10px;
  left: 10px;
}
td {
  margin-left: 100px !important;
}
table {
  border-spacing: 20px !important;
}
</style>
