<template>
  <div class="main_content">
    <div class="5gr">
      <div style="font-weight: bold; text-align: center">Список лицензий</div>
      <F5GRLicensesTable v-if="loaded" />
    </div>
  </div>
</template>

<script>
import F5GRLicensesTable from "../components/F5GRLicensesTable.vue";

export default {
  name: "F5GR",
  data() {
    return {
      loaded: true,
    };
  },
  methods: {},
  components: {
    F5GRLicensesTable,
  },
  mounted() {},
};
</script>

<style scoped>
.5gr {
  width: 100%;
  height: 100%;
  float: left;
  display: inline;
  margin-left: 0px;
  margin-right: 0px;
}
</style>
