<template>
  <div class="modal-mask">
    <div class="modal-window">
      <div class="header">Добавление нового комментария</div>
      <div class="body">
        <form>
          <div class="form-group">
            <label>Комментарий для столбца</label>
            <select
              class="form-select"
              v-model="selectedColumnId"
              aria-label="Столбец"
            >
              <option
                v-for="col in commentColumns"
                v-bind:value="col.column"
                v-bind:key="col.column"
              >
                {{ col.value }}
              </option>
            </select>
          </div>
          <div class="form-group">
            <label>Комментарий</label>
            <input
              type="text"
              class="form-control form-control-lg"
              v-model="comment"
            />
          </div>
          <div class="form-group" style="margin-top: 10px">
            <button @click="save" class="btn btn-dark btn-lg btn-block save">
              Сохранить
            </button>
            <button @click="cancel" class="btn btn-dark btn-lg btn-block close">
              Отменить
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NewCommentModal",
  props: ["deposit_id", "mine_area_id", "license_id", "year"],
  data() {
    return {
      commentColumns: [
        { column: 5, value: "Балансовые запасы A+B+C1" },
        { column: 6, value: "Балансовые запасы С2" },
        { column: 7, value: "Добыча A+B+C1" },
        { column: 8, value: "Добыча C2" },
        { column: 9, value: "Потери при добыче A+B+C1" },
        { column: 10, value: "Потери при добыче C2" },
        { column: 11, value: "Разведка и переоценка A+B+C1" },
        { column: 12, value: "Разведка и переоценка C2" },
        { column: 13, value: "Изменения тех. границ A+B+C1" },
        { column: 14, value: "Изменения тех. границ C2" },
        { column: 17, value: "Состояния запасов по категрии A" },
        { column: 18, value: "Состояния запасов по категрии B" },
        { column: 19, value: "Состояния запасов по категрии C1" },
        { column: 20, value: "Состояния запасов по категрии A+B+C1" },
        { column: 21, value: "Состояния запасов по категрии C2" },
        { column: 22, value: "Состояния запасов по категрии забаланс" },
      ],
      comment: "",
    };
  },
  methods: {
    save(e) {
      console.log(
        JSON.stringify({
          deposit_id: this.deposit_id,
          mine_area_id: this.mine_area_id,
          license_id: this.license_id,
          year: this.year,
          column_number: this.selectedColumnId,
          comment: this.comment,
        })
      );

      this.$emit("save", {
        deposit_id: this.deposit_id,
        mine_area_id: this.mine_area_id,
        license_id: this.license_id,
        year: this.year,
        column_number: this.selectedColumnId,
        comment: this.comment,
      });
      e.preventDefault();
    },
    cancel(e) {
      this.$emit("cancel", {});
      e.preventDefault();
    },
  },
  mounted() {},
};
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s ease;
}

.modal-window {
  border: rgb(169, 255, 202);
  background-color: white;
  position: fixed;
  width: 500px;
  height: 500px;
  top: 50%;
  left: 50%;
  margin-top: -250px;
  margin-left: -250px;
  z-index: 100;
}

.header {
  height: 30px;
  width: 100%;
  background-color: rgb(143, 150, 150);
  text-align: center;
  font-weight: bold;
}

.body {
  font-weight: bolder;
  color: black;
  text-align: center;
}

.close {
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.save {
  position: absolute;
  bottom: 10px;
  left: 10px;
}
</style>
