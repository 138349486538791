<template>
  <div class="item">
    <OkModal
      v-if="showAuthError"
      v-bind:message="authMessage"
      v-bind:header="authHeader"
      v-on:confirm="hideAuthMessage"
    />
    <table style="border-style: none !important; width: 100%">
      <tr style="border-style: none !important; width: 100%">
        <td style="width: 50px; border-style: none !important">
          <button
            @click="addItem"
            class="btn btn-dark btn-lg btn-block btn-add"
            style="float: left; width: 300px"
          >
            <BootstrapIcon icon="plus-square-fill" />
            Добавить новую запись
          </button>
        </td>
        <td style="width: 50%">
          <input
            type="text"
            class="form-control form-control-lg"
            style="display: inline; width: calc(100%)"
            v-model="itemDescription"
          />
        </td>
        <td style="width: 50%">
          <input
            type="text"
            class="form-control form-control-lg"
            style="display: inline; width: calc(100%)"
            v-model="itemCode"
          />
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import axios from "axios";
import BootstrapIcon from "@dvuckovic/vue3-bootstrap-icons";
import OkModal from "./OkModal.vue";

export default {
  name: "SumGroupNewItem",
  props: [],
  data() {
    return {
      itemDescription: "",
      itemCode: "",
      showAuthError: false,
      authMessage: "Доступ запрещен",
      authHeader: "Ошибка безопасности",
    };
  },
  watch: {},
  methods: {
    hideAuthMessage() {
      this.showAuthError = false;
    },
    addItem(e) {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      const url = this.$BASE_URL + "/api/add_group/";
      axios
        .post(
          url,
          { group_name: this.itemDescription, group_code: this.itemCode },
          { headers }
        )
        .then((response) => {
          if (response.data[0].auth_fail) {
            this.showAuthError = true;
            return;
          }
          this.$emit("updated", {});
          this.itemDescription = "";
          this.itemCode = "";
        });
      e.preventDefault();
    },
  },
  components: {
    BootstrapIcon,
    OkModal,
  },
  mounted() {},
};
</script>

<style scoped>
.item {
  width: calc(100%);
  margin-top: 20px;
  height: 50px;
  font-weight: bolder;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  border-style: none !important;
}
table {
  width: 65% !important;
  margin: 0 auto;
}
</style>
