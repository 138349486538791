<template>
  <div class="modal-mask">
    <div class="modal-window">
      <Spinner v-if="showSpinner" />
      <OkModal
        v-if="showError"
        v-bind:message="errorMessage"
        v-bind:header="header"
        v-on:confirm="hideErrorMessage"
      />
      <OkModal
        v-if="showAuthError"
        v-bind:message="authMessage"
        v-bind:header="authHeader"
        v-on:confirm="hideAuthMessage"
      />
      <div class="header">Изменение информации о ресурсе</div>
      <div class="body">
        <div class="body">
          <form class="form-inline">
            <table style="border: none !important; width: 100%">
              <tr>
                <td>
                  <div class="form-group col-xs-3">
                    <label>Среднее содержание</label>
                    <input
                      type="text"
                      class="form-control form-control-lg"
                      v-model="information.avg_concentration"
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="form-group col-xs-3">
                    <label>Производительность</label>
                    <input
                      type="text"
                      class="form-control form-control-lg"
                      v-model="information.productivity"
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="form-group col-xs-3">
                    <label>Глубина</label>
                    <input
                      type="text"
                      class="form-control form-control-lg"
                      v-model="information.depth"
                    />
                  </div>
                </td>
              </tr>
            </table>
            <div class="form-group" style="margin-top: 10px">
              <button @click="save" class="btn btn-dark btn-lg btn-block save">
                Сохранить
              </button>
              <button
                @click="cancel"
                class="btn btn-dark btn-lg btn-block close"
              >
                Отменить
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import OkModal from "../components/OkModal.vue";
import Spinner from "../components/Spinner.vue";

export default {
  name: "ReserveInformationItem",
  props: ["reserve_id", "deposit_id", "mine_area_id"],
  data() {
    return {
      showError: false,
      header: "Не удалось сохранить данные",
      errorMessage: "",
      failed: false,
      information: {
        avg_concentration: "",
        productivity: "",
        depth: "",
      },
      showSpinner: true,
      showAuthError: false,
      authMessage: "Доступ запрещен",
      authHeader: "Ошибка безопасности",
    };
  },
  methods: {
    hideAuthMessage() {
      this.showAuthError = false;
    },
    getReserveInformation() {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      const url = this.$BASE_URL + "/api/get_reserves_description/";
      axios
        .post(
          url,
          {
            reserve_id: this.reserve_id,
          },
          { headers }
        )
        .then((response) => {
          this.showSpinner = false;
          if (!response.data[0].auth_fail) {
            if (response.data[0].result) {
              this.information = response.data[0].result;
            } else {
              this.showError = true;
              this.header = "Не удалось получить данные";
              this.errorMessage =
                "Ошибка сервера! Не удалось получить данные с сервера";
            }
          }
        });
    },

    save(e) {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      const url = this.$BASE_URL + "/api/add_or_update_reserves_description/";
      axios
        .post(
          url,
          {
            reserve_id: this.reserve_id,
            avg_concentration: this.information.avg_concentration,
            productivity: this.information.productivity,
            depth: this.information.depth,
          },
          { headers }
        )
        .then((response) => {
          if (!response.data[0].auth_fail) {
            if (!response.data[0].result) {
              this.errorMessage = response.data[0].reason;
              this.showError = true;
            } else {
              this.$emit("edit-reserve-information-close");
            }
          } else {
            //this.$emit("edit-reserve-information-close");
            this.showAuthError = true;
          }
        });

      e.preventDefault();
    },
    hideErrorMessage() {
      this.showError = false;
      this.$emit("edit-reserve-information-close");
    },
    cancel(e) {
      e.preventDefault();
      this.showError = false;
      this.$emit("edit-reserve-information-close");
    },
  },
  components: {
    OkModal,
    Spinner,
  },
  mounted() {
    this.getReserveInformation();
  },
};
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9997;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s ease;
}

.modal-window {
  border: rgb(169, 255, 202);
  background-color: white;
  position: fixed;
  width: 300px;
  height: 400px;
  top: calc(50% - 200px);
  left: calc(50% - 150px);
  z-index: 100;
}

.header {
  height: 30px;
  width: 100%;
  background-color: rgb(143, 150, 150);
  text-align: center;
  font-weight: bold;
}

.body {
  font-weight: bolder;
  color: black;
  text-align: center;
}

.close {
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.save {
  position: absolute;
  bottom: 10px;
  left: 10px;
}
td {
  margin-left: 100px !important;
}
table {
  border-spacing: 20px !important;
  width: 100%;
}
</style>
