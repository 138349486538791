<template>
  <div class="item">
    <ConfirmModal
      v-if="showConfirmModal"
      v-bind:message="confirmMessage"
      v-bind:header="confirmHeader"
      v-on:confirm="onConfirm"
      v-on:cancel="onCancel"
    />
    <ReservesForMineAreaModal
      :deposit_id="depositId"
      :mine_area_id="mineAreaId"
      v-on:deposit-reserves-close="closeEditReserves"
      v-if="showEditReserves"
    />
    <EditMineAreaModal
      v-bind:deposit_id="depositId"
      v-bind:mine_area_id="mineAreaId"
      v-on:save-edit-mine-area="saveMineAreaEdit"
      v-on:close-edit-mine-area="closeMineAreaEdit"
      v-if="showEditMineAreaModal"
    />
    <FileManager
      v-if="showFileManger"
      v-on:file-manager-close="closeFileManger"
      v-bind:deposit_id="depositId"
      v-bind:mine_area_id="mineAreaId"
    />
    <OkModal
      v-if="showAuthError"
      v-bind:message="authMessage"
      v-bind:header="authHeader"
      v-on:confirm="hideAuthMessage"
    />
    <table style="border-style: none !important; width: 100%">
      <tr style="border-style: none !important; width: 100%">
        <td style="width: 50px; border-style: none !important">
          <button
            @click="removeItem"
            class="btn btn-dark btn-lg btn-block btn-delete"
            style="float: left; width: 55px"
            title="Удалить запись"
          >
            <BootstrapIcon icon="trash" />
          </button>
        </td>
        <td style="width: 50px">
          <button
            @click="editItem"
            class="btn btn-dark btn-lg btn-block btn-edit"
            style="float: left; width: 55px"
            title="Редактировать запись"
          >
            <BootstrapIcon icon="pencil" />
          </button>
        </td>
        <td style="width: 50px">
          <button
            @click="showFileMangerHandler"
            class="btn btn-dark btn-lg btn-block btn-file"
            style="float: left; width: 55px"
            title="Загрузить файл"
          >
            <BootstrapIcon icon="cloud-arrow-up" />
          </button>
        </td>
        <td style="width: 50px">
          <button
            @click="editReserves"
            class="btn btn-dark btn-lg btn-block btn-reserves"
            style="float: left; width: 150px; margin-right: 15px"
          >
            <BootstrapIcon icon="columns-gap" />
            Запасы
          </button>
        </td>
        <td style="width: 100%">
          <input
            type="text"
            class="form-control form-control-lg"
            style="display: inline; width: calc(100%)"
            v-model="mineAreaName"
            disabled
          />
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import axios from "axios";

import BootstrapIcon from "@dvuckovic/vue3-bootstrap-icons";
import EditMineAreaModal from "../components/EditMineAreaModal.vue";
import ReservesForMineAreaModal from "../components/ReservesForMineAreaModal.vue";
import ConfirmModal from "../components/ConfirmModal.vue";
import OkModal from "./OkModal.vue";
import FileManager from "../components/FileManager.vue";

export default {
  name: "MineAreaItem",
  props: ["deposit_id", "mine_area_id", "mine_area_name"],
  data() {
    return {
      depositId: this.deposit_id,
      mineAreaId: this.mine_area_id,
      mineAreaName: this.mine_area_name,
      showEditMineAreaModal: false,
      showEditReserves: false,
      confirmMessage: "",
      confirmHeader: "",
      showConfirmModal: false,
      showAuthError: false,
      authMessage: "Доступ запрещен",
      authHeader: "Ошибка безопасности",
      showFileManger: false,
    };
  },
  methods: {
    showFileMangerHandler() {
      this.showFileManger = true;
    },
    closeFileManger() {
      this.showFileManger = false;
    },
    hideAuthMessage() {
      this.showAuthError = false;
    },
    onConfirm() {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      const url = this.$BASE_URL + "/api/delete_mine_area/";
      axios
        .post(
          url,
          { deposit_id: this.depositId, mine_area_id: this.mine_area_id },
          { headers }
        )
        .then((response) => {
          if (!response.data[0].auth_fail) {
            this.$emit("mine-area-deleted", {});
          } else {
            this.showAuthError = true;
          }
        });
    },
    onCancel() {
      this.showConfirmModal = false;
    },
    removeItem(e) {
      this.confirmMessage =
        "Удалить участок? Это приведет к потере данных (лицензий, запасов, а также всех движений и исторических данных!)";
      this.confirmHeader = "Удаление данных!";
      this.showConfirmModal = true;
      e.preventDefault();
    },
    editItem(e) {
      this.showEditMineAreaModal = true;
      /*this.$emit('mine-area-edited', {
          "deposit_id": this.depositId,
          "mine_area_id": this.mineAreaId
        });
      */
      e.preventDefault();
    },
    editReserves(e) {
      //this.$emit('reserves-edited-for-deposit', {"deposit_id": this.depositId});
      this.showEditReserves = true;
      e.preventDefault();
    },
    closeEditReserves() {
      this.showEditReserves = false;
      //e.preventDefault();
    },
    closeMineAreaEdit() {
      this.showEditMineAreaModal = false;
    },
    saveMineAreaEdit(mineArea) {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      const url = this.$BASE_URL + "/api/update_mine_area/";
      axios.post(url, mineArea, { headers }).then(() => {
        this.$emit("mine-area-edited");
      });
      this.showEditMineAreaModal = false;
    },
  },
  components: {
    EditMineAreaModal,
    ReservesForMineAreaModal,
    BootstrapIcon,
    ConfirmModal,
    OkModal,
    FileManager,
  },
  mounted() {},
};
</script>

<style scoped>
img {
  height: 30px;
  width: 30px;
}

.item {
  width: calc(100%);
  height: 50px;
  font-weight: bolder;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  border-style: none !important;
}
.delete-btn {
  height: 30px;
  display: inline;
  /*margin-right: 5px;*/
}
.save-btn {
  height: 30px;
  display: inline-block;
  /*margin-right: 5px;*/
}
.inputfield {
  display: inline;
  height: 30px;
  width: 330px;
}
</style>
