<template>
  <div class="modal-mask">
    <div class="modal-window">
      <OkModal
        v-if="showError"
        v-bind:message="errorMessage"
        v-bind:header="header"
        v-on:confirm="hideErrorMessage"
      />
      <MovementFillModal
        v-if="showMovementModal"
        v-bind:license_id="license_id"
        v-bind:year="selectedMomentYear"
        v-on:movement-close="hideModal"
      />
      <div class="header">Добавление новой записи движения запасов</div>
      <div class="body">
        <form class="form-inline">
          <table style="border: none !important; width: 100%">
            <tr>
              <td>
                <div class="form-group col-xs-12">
                  <label>На какой год производится движение запасов</label>
                  <select
                    class="form-select form-control-lg"
                    v-model="selectedMomentYear"
                    aria-label="Год"
                  >
                    <option
                      v-for="year in years"
                      v-bind:value="year"
                      v-bind:key="year"
                    >
                      {{ year }}
                    </option>
                  </select>
                </div>
              </td>
            </tr>
          </table>
          <div class="form-group" style="margin-top: 10px">
            <button
              @click="choose"
              class="btn btn-dark btn-lg btn-block choose"
            >
              Выбрать
            </button>
          </div>
          <div class="form-group" style="margin-top: 10px">
            <button @click="close" class="btn btn-dark btn-lg btn-block close">
              Отменить
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import OkModal from "../components/OkModal.vue";
import MovementFillModal from "../components/MovementFillModal.vue";

export default {
  name: "MovementYearModal",
  props: ["license_id"],
  data() {
    return {
      showMovementModal: false,
      showError: false,
      header: "Ошибка при выборке данных",
      errorMessage: "",
      failed: false,
      selectedMomentYear: new Date().getFullYear(),
      years: [new Date().getFullYear()],
    };
  },
  methods: {
    hideModal() {
      //this.$emit("movements-close");
      this.showMovementModal = false;
      //e.preventDefault();
    },
    hideErrorMessage() {
      this.showError = false;
    },
    close(e) {
      this.$emit("movements-close");
      e.preventDefault();
    },
    choose(e) {
      //this.$emit("movements-close");
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      var url = this.$BASE_URL + "/api/get_movement/";
      axios
        .post(
          url,
          { license_id: this.license_id, year: this.selectedMomentYear },
          { headers }
        )
        .then((response) => {
          if (!response.data[0].auth_fail) {
            if (!response.data[0].result) {
              this.showError = true;
              this.errorMessage = response.data[0].reason;
            } else {
              this.showMovementModal = true;
            }
          }
        });
      e.preventDefault();
    },
  },
  components: {
    OkModal,
    MovementFillModal,
  },
  mounted() {},
};
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 100009;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s ease;
}

.modal-window {
  border: rgb(169, 255, 202);
  background-color: white;
  position: fixed;
  width: 400px;
  height: 200px;
  top: 50%;
  left: 50%;
  margin-top: -100px;
  margin-left: -200px;
  z-index: 1000092;
}

.header {
  height: 30px;
  width: 100%;
  background-color: rgb(143, 150, 150);
  text-align: center;
  font-weight: bold;
}

td {
  margin-left: 100px !important;
}
table {
  border-spacing: 20px !important;
}
</style>
