<template>
  <div class="main_content" style="margin-top: 100px">
    <div class="deposits">
      <MSRTable v-if="loaded" />
    </div>
  </div>
</template>

<script>
import MSRTable from "../components/MSRTable.vue";

export default {
  name: "MSR",
  data() {
    return {
      loaded: true,
    };
  },
  methods: {},
  components: {
    MSRTable,
  },
  mounted() {},
};
</script>

<style scoped></style>
