<template>
  <div>
    <OkModal
      v-if="showAuthError"
      v-bind:message="authMessage"
      v-bind:header="authHeader"
      v-on:confirm="hideAuthMessage"
    />
    <Spinner v-if="showSpinner" />
    <div class="msr_header" style="width: 100%">ПОИСК</div>
    <div class="msr_title_header" style="width: 100%; margin-bottom: 40px">
      Фильтры для запроса
    </div>
    <form class="form-inline" style="margin-bottom: 30px; margin-top: 30px">
      <table style="border: none !important; width: 100%">
        <tr>
          <td style="width: 20%">
            <label for="area" class="col-xs-2">Область</label>
          </td>
          <td>
            <select
              id="area"
              class="form-select form-control-lg"
              v-model="selectedAreaId"
              aria-label="Область"
              style="width: 100%"
            >
              <option
                v-for="area in areas"
                v-bind:value="area.id_area"
                v-bind:key="area.id_area"
              >
                {{ area.area_name }}
              </option>
            </select>
          </td>
        </tr>
        <tr>
          <td style="width: 20%">
            <label for="deposit_name" class="col-xs-2"
              >Название месторождения</label
            >
          </td>
          <td style="width: 50%">
            <input
              type="text"
              id="deposit_name"
              class="form-control form-control-lg"
              v-model="deposit_name"
              aria-label="Название месторождения"
              style="width: 100%; float: right"
            />
          </td>
        </tr>
        <tr>
          <td style="width: 20%">
            <label for="org" class="col-xs-2">Ведомство</label>
          </td>
          <td style="width: 50%">
            <input
              type="text"
              id="org"
              class="form-control form-control-lg"
              v-model="organization"
              aria-label="Ведомство"
              style="width: 100%; float: right"
            />
          </td>
        </tr>
        <tr>
          <td style="width: 20%">
            <label for="taxnumber" class="col-xs-2">ИНН</label>
          </td>
          <td style="width: 50%">
            <input
              type="text"
              id="taxnumber"
              class="form-control form-control-lg"
              v-model="taxnumber"
              aria-label="ИНН"
              style="width: 100%; float: right"
            />
          </td>
        </tr>
        <tr>
          <td style="width: 20%">
            <label for="license" class="col-xs-2">Номер лицензии</label>
          </td>
          <td style="width: 50%">
            <input
              type="text"
              id="license"
              class="form-control form-control-lg"
              v-model="license_number"
              aria-label="Номер лицензии"
              style="width: 100%; float: right"
            />
          </td>
        </tr>
        <tr>
          <td style="width: 20%">
            <label for="license" class="col-xs-2">Полезное ископаемое</label>
          </td>
          <td style="width: 50%">
            <DropDownSearchBox items="[]" aria-label="Полезное ископаемое" />
          </td>
        </tr>
        <tr style="margin-top: 20px">
          <td style="width: 30%"></td>
          <td>
            <table style="width: 100%">
              <tr style="width: 100%">
                <td style="width: 30%"></td>
                <td style="width: 25%">
                  <button
                    @click="clear($event)"
                    class="btn btn-dark btn-lg btn-block btn-clear"
                    style="float: right"
                  >
                    <BootstrapIcon icon="arrow-clockwise" />
                    Очистить
                  </button>
                </td>
                <td style="width: 23%">
                  <button
                    @click="makeQuery($event)"
                    class="btn btn-dark btn-lg btn-block btn-query"
                    style="float: right"
                  >
                    <BootstrapIcon icon="search" />
                    Выполнить запрос
                  </button>
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </table>
    </form>
    <div class="msr_title_header" style="width: 100%; margin-bottom: 40px">
      МИНЕРАЛЬНО-СЫРЬЕВЫЕ РЕСУРСЫ ({{ area }}) <br />
      по данным Государственного баланса запасов полезных ископаемых Республики
      Узбекистан (состояния на {{ currentDate.toLocaleDateString() }})
    </div>
    <table>
      <tr class="msr_header">
        <td style="width: calc(2%)">№ ПП</td>
        <td style="width: calc(18%)">
          Название месторождений, их местоположение
        </td>
        <td style="width: calc(5%)">
          <table style="width: 100%">
            <tr style="width: 100%">
              <td colspan="2" style="text-align: center; width: 100%">
                Состояние запасов
              </td>
            </tr>
            <tr style="width: 100%">
              <td style="width: 50%">A+B+C1</td>
              <td style="width: 50%">C2</td>
            </tr>
          </table>
        </td>
        <td style="width: calc(20%)">
          <table style="width: 100%">
            <tr style="width: 100%">
              <td colspan="2" style="text-align: center">
                Сведения об эксплуатации
              </td>
            </tr>
            <tr style="width: 100%">
              <td style="width: 50%">Степень освоения</td>
              <td style="width: 50%">№ и дата лицензии</td>
            </tr>
          </table>
        </td>
        <td style="width: calc(5%)">Объем добычи</td>
        <td style="width: calc(10%)">Ведомственная принадлежность</td>
        <td style="width: calc(10%)">Год утверждения запасов</td>
        <td style="width: calc(20%)">Примечание</td>
        <td style="width: calc(10%)">Район</td>
      </tr>
      <tr class="msr_header">
        <td style="width: 10px">1</td>
        <td>2</td>
        <td>
          <table style="width: 100%">
            <tr style="width: 100%">
              <td style="width: 50%">3</td>
              <td style="width: 50%">4</td>
            </tr>
          </table>
        </td>
        <td>
          <table style="width: 100%">
            <tr style="width: 100%">
              <td style="width: 50%">5</td>
              <td style="width: 50%">6</td>
            </tr>
          </table>
        </td>
        <td>7</td>
        <td>8</td>
        <td>9</td>
        <td>10</td>
        <td>11</td>
      </tr>
      <tr
        v-for="(items, usage) in msr"
        v-bind:key="usage + ',' + selectedAreaId"
      >
        <td colspan="11">
          <table width="100%">
            <tr>
              <td colspan="11" class="usage">
                {{ usage }}
              </td>
            </tr>
            <tr>
              <td colspan="11">
                <span
                  v-for="(deposit, depositname) in msr[usage]"
                  v-bind:key="
                    depositname + usage + deposit['properties']['index']
                  "
                >
                  <MSRItemNoMineAreas
                    v-if="!deposit['properties']['has_mine_areas']"
                    v-bind:key="
                      depositname + usage + deposit['properties']['index']
                    "
                    v-bind:deposit="deposit"
                    v-bind:depositname="depositname"
                    v-bind:has_mine_areas="false"
                    v-bind:depositIndex="deposit['properties']['index']"
                    v-on:loaded="onCount"
                  />
                  <MSRItem
                    v-if="deposit['properties']['has_mine_areas']"
                    v-bind:key="
                      depositname + usage + deposit['properties']['index']
                    "
                    v-bind:deposit="deposit"
                    v-bind:depositname="depositname"
                    v-bind:has_mine_areas="true"
                    v-bind:depositIndex="deposit['properties']['index']"
                    v-on:loaded="onCount"
                  />
                </span>
              </td>
            </tr>
          </table>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import axios from "axios";

import MSRItem from "../components/MSRItem.vue";
import MSRItemNoMineAreas from "../components/MSRItemNoMineAreas.vue";
import Spinner from "../components/Spinner.vue";
import OkModal from "./OkModal.vue";
import DropDownSearchBox from "../components/DropDownSearchBox.vue";
import BootstrapIcon from "@dvuckovic/vue3-bootstrap-icons";

export default {
  name: "MSRTable",
  data() {
    return {
      deposit_name: "",
      license_number: "",
      organization: "",
      area: "",
      taxnumber: "",
      group: "",
      component_id: "",
      license_date_from: "",
      showSpinner: true,
      showAuthError: false,
      authMessage: "Доступ запрещен",
      authHeader: "Ошибка безопасности",
      sumIndex: 0,
      sum: 0,
      selectedAreaId: "",
      areas: [],
      currentDate: new Date(),
    };
  },
  methods: {
    clear(e) {
      this.taxnumber = "";
      this.license_number = "";
      this.selectedAreaId = "";
      this.organization = "";
      this.deposit_name = "";
      this.showSpinner = true;
      this.getMSR();
      e.preventDefault();
    },
    onCount() {
      this.sumIndex += 1;
      console.log("TALBE INDEX :" + this.sumIndex);
    },
    makeQuery(e) {
      for (var i in this.areas) {
        if (this.areas[i].id_area == this.selectedAreaId) {
          this.area = this.areas[i].area_name;
        }
      }
      this.showSpinner = true;
      this.getMSR();
      e.preventDefault();
    },
    getAreas() {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      var url = this.$BASE_URL + "/api/get_areas/";
      axios
        .post(url, { offset: 0, limit: 20 }, { headers })
        .then((response) => {
          if (!response.data.auth_fail) {
            this.areas = response.data.result;
            this.areas.push({
              id_area: "",
              area_name: "Все области",
            });

            for (var i in this.areas) {
              if (this.areas[i].id_area == this.selectedAreaId) {
                this.area = this.areas[i].area_name;
              }
            }

            this.getMSR();
          }
        });
    },
    getMSR() {
      this.sumIndex = 0;
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      var url = this.$BASE_URL + "/api/get_msr_report/";
      axios
        .post(
          url,
          {
            deposit_name: this.deposit_name,
            license_number: this.license_number,
            organization: this.organization,
            area: this.area,
            taxnumber: this.taxnumber,
            group: this.group,
            component_id: this.component_id,
            license_date_from: this.license_date_from,
            area_id: this.selectedAreaId,
          },
          { headers }
        )
        .then((response) => {
          if (!response.data[0].auth_fail) {
            this.msr = response.data[0].result;
            this.$forceUpdate();
            this.showSpinner = false;
          } else {
            this.showAuthError = true;
            this.showSpinner = false;
          }
        });
    },
  },
  components: {
    Spinner,
    OkModal,
    MSRItem,
    MSRItemNoMineAreas,
    BootstrapIcon,
    DropDownSearchBox,
  },
  created() {
    this.sumIndex = 0;
  },
  mounted() {
    this.sum = 0;
    this.getAreas();
    //this.getMSR();
  },
};
</script>

<style scoped>
.msr_header {
  background-color: #372d69;
  color: white;
  font-weight: bold;
  text-align: center;
}

.msr_title_header {
  background-color: white;
  color: black;
  font-weight: bold;
  text-align: center;
}

.msr_header td {
  margin-left: 10px;
}

.usage {
  background-color: #add8e6;
  color: white;
  font-weight: bold;
  text-align: center;
}

.query-btn {
  background-color: #372d69;
  width: calc(100%);
  margin-right: 5px;
  border-style: none !important;
  border-top: none !important;
  border-bottom: none !important;
}
</style>
