<template>
  <div class="modal-mask">
    <div class="modal-window">
      <ReserveForMineAreaNewModal
        v-bind:deposit_id="depositId"
        v-bind:mine_area_id="mineAreaId"
        v-if="showAddNewReserve"
        v-on:add-deposit-reserves-close="closeAddReserve"
      />
      <div class="header">Редактирование запасов для участка</div>
      <div class="body">
        <ReserveItem
          v-for="item in reserves"
          v-bind:key="item.id_reserve"
          v-bind:reserve_id="item.id_reserve"
          v-bind:deposit_id="item.id_deposit"
          v-bind:mine_area_id="item.id_mine_area"
          v-on:reserve-removed="reserveRemoved"
          v-on:reserve-edit="reserveEdit"
          v-bind:description="item.mineral + ', ' + item.usage"
        />
        <button
          @click="reserveAdd"
          class="btn btn-dark btn-lg btn-block btn-add"
          style="float: left; width: 300px"
        >
          <BootstrapIcon icon="plus-square-fill" />
          Добавить новую запись
        </button>
        <div class="form-group" style="margin-top: 10px">
          <button @click="close" class="btn btn-dark btn-lg btn-block close">
            Закрыть
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import ReserveItem from "../components/ReserveItem.vue";
import ReserveForMineAreaNewModal from "../components/ReserveForMineAreaNewModal.vue";
import BootstrapIcon from "@dvuckovic/vue3-bootstrap-icons";

export default {
  name: "ReservesForMineAreaModal",
  props: ["deposit_id", "mine_area_id"],
  data() {
    return {
      reserves: [],
      failed: false,
      showAddNewReserve: false,
      depositId: this.deposit_id,
      mineAreaId: this.mine_area_id,
    };
  },
  methods: {
    getReserves() {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      const url = this.$BASE_URL + "/api/get_reserves_for_mine_area/";
      axios
        .post(
          url,
          { deposit_id: this.depositId, mine_area_id: this.mineAreaId },
          { headers }
        )
        .then((response) => {
          if (!response.data.auth_fail) {
            this.reserves = response.data.result;
          }
        });
    },
    close() {
      this.$emit("deposit-reserves-close");
    },
    reserveRemoved(e) {
      this.getReserves();
      e.preventDefault();
    },
    reserveAdd(e) {
      this.showAddNewReserve = true;
      e.preventDefault();
    },
    closeAddReserve() {
      this.showAddNewReserve = false;
      this.getReserves();
    },
    reserveEdit() {
      this.showAddNewReserve = false;
      this.getReserves();
    },
  },
  components: {
    ReserveForMineAreaNewModal,
    ReserveItem,
    BootstrapIcon,
  },
  mounted() {
    this.getReserves();
  },
};
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9997;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s ease;
}

.modal-window {
  border: rgb(169, 255, 202);
  background-color: white;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 100;
}

.header {
  height: 30px;
  width: 100%;
  background-color: rgb(143, 150, 150);
  text-align: center;
  font-weight: bold;
}

.body {
  font-weight: bolder;
  color: black;
  text-align: center;
}

.close {
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.save {
  position: absolute;
  bottom: 10px;
  left: 10px;
}
</style>
