<template>
  <div style="width: 100%">
    <span v-if="numberOfMineAreas == 0">
      <table style="width: 100%">
        <tr class="msr_content">
          <td style="width: calc(2%)">
            {{ myIndex }}
          </td>
          <td style="width: calc(18%)">
            <span v-html="depositDescription"></span>
          </td>
          <td colspan="9" style="width: 100%" class="msr_content">
            <MSRLicenseItem
              v-bind:key="depositname"
              v-bind:component="deposit['properties']['component']"
              v-bind:depositinfo="deposit['areas'][depositname]"
              v-bind:depositname="depositname"
            />
          </td>
        </tr>
      </table>
    </span>
  </div>
</template>

<script>
//import OkModal from "./OkModal.vue";
import MSRLicenseItem from "../components/MSRLicenseItem.vue";

export default {
  name: "MSRItemNoMineAreas",
  props: ["deposit", "depositname", "depositIndex"],
  data() {
    return {
      numberOfMineAreas: 0,
      depositDescription: "",
      myIndex: 0,
    };
  },
  methods: {},
  components: {
    //OkModal,
    MSRLicenseItem,
  },
  created() {
    this.myIndex = this.depositIndex;
    //console.log("Index: " + this.depositIndex)
  },
  mounted() {
    this.$emit("loaded", {});
    if (this.deposit["properties"]["has_mine_areas"]) {
      this.numberOfMineAreas = Object.keys(this.deposit["areas"]).length;
      //console.log("Number of mine areas: " + this.numberOfMineAreas)
      this.mineareas = Object.keys(this.deposit["areas"]);
      var that = this;
      var mineareas = this.mineareas.filter(function (e) {
        return e !== that.depositname;
      });
      this.mineareas = mineareas;
    } else {
      this.numberOfMineAreas = 0;
    }
    this.depositDescription =
      "<span style='font-weight: bold'>" + this.depositname + "</span><br/>";
    if (this.deposit["properties"]["coordinates"]["longitude"] != undefined) {
      this.depositDescription +=
        "\n Координаты месторождения (догота: " +
        this.deposit["properties"]["coordinates"]["longitude"];
      this.depositDescription +=
        ", широта: " +
        this.deposit["properties"]["coordinates"]["latitude"] +
        ") <br/>";
    }
    if (this.deposit["properties"]["train_station"]["name"] != undefined) {
      this.depositDescription +=
        "Ближайшая железнодорожная станция " +
        this.deposit["properties"]["train_station"]["name"];
      this.depositDescription +=
        " (" +
        this.deposit["properties"]["train_station"]["distance"] +
        ") <br/>";
    }
    if (this.deposit["properties"]["inhab_area"]["name"] != undefined) {
      this.depositDescription +=
        "Ближайший населенный пункт " +
        this.deposit["properties"]["inhab_area"]["name"];
      this.depositDescription +=
        " (" +
        this.deposit["properties"]["inhab_area"]["direction"] +
        ", <br/>";
      this.depositDescription +=
        this.deposit["properties"]["inhab_area"]["distance"] + ") <br/>";
    }
    if (this.deposit["properties"]["expedition"]["description"] != undefined) {
      this.depositDescription +=
        "Экспедиция " + this.deposit["properties"]["expedition"]["description"];
      this.depositDescription +=
        " (" +
        this.deposit["properties"]["expedition"]["organization"] +
        ", <br/>";
      this.depositDescription +=
        new Date(
          this.deposit["properties"]["expedition"]["date_from"]
        ).toLocaleDateString() + ") <br/>";
    }
  },
};
</script>

<style scoped>
.msr_content {
  background-color: #ffffe6;
  color: black;
  text-align: center;
  min-height: 50px;
  width: 100%;
}
table,
th,
td {
  border: 1px solid;
}
</style>
