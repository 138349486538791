<template>
  <div class="table">
    <Spinner v-if="showSpinner" />
    <Filter v-on:filter="filterTable" v-bind:filterVal="filter" />
    <LicenseItem
      v-for="item in items"
      v-bind:key="item.license_id"
      v-bind:id="item.license_id"
      v-bind:mine_area_id="item.mine_area_id"
      v-bind:license_id="item.license_id"
      v-on:license-updated="onLicenseUpdated"
      v-on:loaded="onItemLoaded"
    />
    <LicenseNewItem
      v-if="showAddNewLicense"
      v-on:add-new-license="onAddNewLicense"
    />
    <div class="separator"></div>
    <div class="add">
      <button
        @click="onNew"
        class="btn btn-dark btn-lg btn-block btn-add"
        style="width: 250px"
      >
        <BootstrapIcon icon="plus-square-fill" />
        Добавить лицензию
      </button>
    </div>
    <div class="separator"></div>
    <Paginator
      v-bind:count="totalItems"
      v-bind:ipp="ipp"
      v-bind:currentPage="page"
      v-on:page-click="changePage"
      v-bind:autoMargin="true"
    />
  </div>
</template>

<script>
import BootstrapIcon from "@dvuckovic/vue3-bootstrap-icons";
import axios from "axios";
import LicenseItem from "../components/LicenseItem.vue";
import LicenseNewItem from "../components/LicenseNewItem.vue";
import Paginator from "../components/Paginator.vue";
import Filter from "../components/Filter.vue";
import Spinner from "../components/Spinner.vue";

export default {
  name: "LicenseTable",
  props: [],
  watch: {},
  data() {
    return {
      showAddNewLicense: false,
      items: [],
      totalItems: 0,
      page: 1,
      ipp: 10,
      filter: "",
      loadedItems: 0,
      showSpinner: true,
    };
  },
  methods: {
    countItems() {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      var url = this.$BASE_URL + "/api/get_license_count/";
      axios
        .post(
          url,
          { license: this.filter, include_special_licenses: false },
          { headers }
        )
        .then((response) => {
          if (!response.data.auth_fail) {
            this.totalItems = response.data.result;
            if (this.totalItems == 0) {
              this.loadedItems = 0;
              this.showSpinner = false;
            }
          }
        });
    },
    loadItems() {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      var url = this.$BASE_URL + "/api/get_licenses/";
      const offset = (this.page - 1) * this.ipp;
      axios
        .post(
          url,
          {
            license: this.filter,
            offset: offset,
            limit: this.ipp,
            include_special_licenses: false,
          },
          { headers }
        )
        .then((response) => {
          if (!response.data.auth_fail) {
            console.log(response.data.result);
            this.items = response.data.result;
          }
        });
    },
    onItemLoaded() {
      this.loadedItems++;
      if (this.loadedItems == this.ipp) {
        this.loadedItems = 0;
        this.showSpinner = false;
      }
      if (
        this.loadedItems ==
        this.totalItems - this.ipp * Math.floor(this.totalItems / this.ipp)
      ) {
        this.loadedItems = 0;
        this.showSpinner = false;
      }
    },
    changePage(o) {
      this.page = o.page;
      this.loadItems();
    },
    onLicenseUpdated() {
      this.page = 1;
      this.filter = "";
      this.countItems();
      this.loadItems();
    },
    filterTable(filter) {
      this.page = 1;
      this.filter = filter;
      this.countItems();
      this.loadItems();
    },
    onNew(e) {
      this.showAddNewLicense = true;
      e.preventDefault();
    },
    onAddNewLicense() {
      this.showAddNewLicense = false;
      this.page = 1;
      this.filter = "";
      this.countItems();
      this.loadItems();
    },
  },
  components: {
    LicenseItem,
    LicenseNewItem,
    Paginator,
    Filter,
    BootstrapIcon,
    Spinner,
  },
  mounted() {
    this.countItems();
    this.loadItems();
  },
};
</script>

<style scoped>
.table {
  width: 100%;
  height: 100%;
  font-weight: bolder;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  border-style: none !important;
  border-top: none !important;
  border-bottom: none !important;
}

.add {
  width: 90%;
  margin: 0 auto;
  border-top: none !important;
  border-bottom: none !important;
}

.separator {
  padding: 10px;
  margin-left: 10px;
  border-top: none !important;
  border-bottom: none !important;
}

img {
  height: 30px;
  width: 30px;
}

/*
.bi {
    font-size: 2rem;
}
*/
</style>
