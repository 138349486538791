<template>
  <div class="modal-mask">
    <div class="modal-window">
      <Spinner v-if="showSpinner" />
      <div class="header">
        Изменение сопоставление для группы итогов {{ group_name }}
      </div>
      <div class="body">
        <!-- Filter v-on:filter="filterTable" v-bind:filterVal="filter" / -->
        <SumGroupMappingItem
          v-for="item in items"
          v-bind:key="item.id"
          v-bind:id_group="item.id_group"
          v-bind:group_name="item.group_name"
          v-bind:id="item.id"
          v-bind:component="item.component"
          v-bind:usage="item.usage"
          v-on:updated="updated"
          v-on:loaded="onItemLoaded"
        />
        <SumGroupMappingNewModal
          v-if="showNewMappingModal"
          v-bind:group_id="group_id"
          v-on:save="onMappingAdded"
          v-on:cancel="onCloseNewMappingModal"
        />

        <Paginator
          v-bind:count="totalItems"
          v-bind:ipp="ipp"
          v-bind:currentPage="page"
          v-on:page-click="changePage"
        />
      </div>
      <form class="form-inline">
        <div class="form-group" style="margin-top: 10px">
          <button
            @click="addItem"
            class="btn btn-dark btn-lg btn-block"
            style="float: left; width: 300px"
          >
            <BootstrapIcon icon="database-add" />
            Добавить новую запись
          </button>
        </div>
        <div class="form-group" style="margin-top: 10px">
          <button @click="cancel" class="btn btn-dark btn-lg btn-block close">
            Закрыть форму
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import SumGroupMappingItem from "../components/SumGroupMappingItem.vue";
import SumGroupMappingNewModal from "../components/SumGroupMappingNewModal.vue";
import Paginator from "../components/Paginator.vue";
//import Filter from "../components/Filter.vue";
import Spinner from "../components/Spinner.vue";

export default {
  name: "SumGroupMappingModal",
  props: ["group_id"],
  watch: {},
  data() {
    return {
      items: [],
      totalItems: 0,
      page: 1,
      ipp: 10,
      filter: "",
      loadedItems: 0,
      showSpinner: true,
      showNewMappingModal: false,
    };
  },
  methods: {
    cancel(e) {
      this.$emit("close");
      e.preventDefault();
    },
    onMappingAdded() {
      this.showNewMappingModal = false;
      this.countItems();
    },
    onCloseNewMappingModal() {
      this.showNewMappingModal = false;
    },
    addItem(e) {
      this.showNewMappingModal = true;
      e.preventDefault();
    },
    countItems() {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      var url = this.$BASE_URL + "/api/get_group_mappings_count/";
      axios
        .post(url, { group_id: this.group_id }, { headers })
        .then((response) => {
          if (!response.data[0].auth_fail) {
            this.totalItems = response.data[0].result;
            this.loadItems();
          }
        });
    },
    loadItems() {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      var url = this.$BASE_URL + "/api/get_group_mappings/";
      const offset = (this.page - 1) * this.ipp;
      axios
        .post(
          url,
          {
            group_id: this.group_id,
            offset: offset,
            limit: this.ipp,
          },
          { headers }
        )
        .then((response) => {
          if (!response.data[0].auth_fail) {
            this.items = response.data[0].result;
            if (this.loadedItems == 0) {
              this.loadedItems = 0;
              this.showSpinner = false;
            }
          }
        });
    },
    onItemLoaded() {
      this.loadedItems++;
      //alert(this.totalItems + " " + this.loadedItems)
      if (this.loadedItems == this.ipp) {
        this.loadedItems = 0;
        this.showSpinner = false;
      }
      if (
        this.loadedItems == 0 &&
        this.totalItems - this.ipp * Math.floor(this.totalItems / this.ipp)
      ) {
        this.loadedItems = 0;
        this.showSpinner = false;
      }
      if (this.loadedItems == this.totalItems) {
        this.loadedItems = 0;
        this.showSpinner = false;
      }
    },
    changePage(o) {
      this.page = o.page;
      this.loadItems();
    },
    updated() {
      this.page = 1;
      this.filter = "";
      this.countItems();
      //this.loadItems();
    },
    filterTable(filter) {
      this.filter = filter;
      this.countItems();
      //this.loadItems();
    },
  },
  components: {
    SumGroupMappingItem,
    SumGroupMappingNewModal,
    Paginator,
    //Filter,
    Spinner,
  },
  mounted() {
    this.countItems();
    this.loadItems();
  },
};
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9997;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s ease;
}

.modal-window {
  border: rgb(169, 255, 202);
  background-color: white;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 100;
}

.header {
  height: 30px;
  width: 100%;
  background-color: rgb(143, 150, 150);
  text-align: center;
  font-weight: bold;
}

.body {
  font-weight: bolder;
  color: black;
  text-align: center;
}

.close {
  position: absolute;
  bottom: 10px;
  right: 10px;
}
</style>
