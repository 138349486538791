<template>
  <div class="item">
    <OkModal
      v-if="showAuthError"
      v-bind:message="authMessage"
      v-bind:header="authHeader"
      v-on:confirm="hideAuthMessage"
    />
    <OkModal
      v-if="showError"
      v-bind:message="errorMessage"
      v-bind:header="errorHeader"
      v-on:confirm="hideErrorMessage"
    />
    <ReserveForDepositEditModal
      v-if="showEditReserve"
      v-bind:reserve_id="reserveId"
      v-bind:deposit_id="depositId"
      v-bind:mine_area_id="mineAreaId"
      v-on:edit-deposit-reserves-close="onUpdateReserve"
    />
    <ReserveInformationItem
      v-if="showReserveInformation"
      v-bind:reserve_id="reserveId"
      v-bind:deposit_id="depositId"
      v-bind:mine_area_id="mineAreaId"
      v-on:edit-reserve-information-close="onUpdateReserveInformation"
    />
    <table style="border-style: none !important; width: 100%">
      <tr style="border-style: none !important; width: 100%">
        <td style="width: 50px; border-style: none !important">
          <button
            @click="removeItem"
            class="btn btn-dark btn-lg btn-block btn-delete"
            style="float: left; width: 55px"
            title="Удалить запись"
          >
            <BootstrapIcon icon="trash" />
          </button>
        </td>
        <td style="width: 50px">
          <button
            @click="editItem"
            class="btn btn-dark btn-lg btn-block btn-edit"
            style="float: left; width: 55px"
            title="Редактировать запись"
          >
            <BootstrapIcon icon="pencil" />
          </button>
        </td>
        <td style="width: 50px">
          <button
            @click="addOrEditReserveInformation"
            class="btn btn-dark btn-lg btn-block btn-addinfo"
            style="float: left; width: 215px"
          >
            <BootstrapIcon icon="info-square" />
            Доп. информация
          </button>
        </td>
        <td style="width: 100%">
          <input
            type="text"
            class="form-control form-control-lg"
            enabled="false"
            style="display: inline; width: calc(100%); float: left"
            v-model="itemDescription"
          />
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import axios from "axios";
import BootstrapIcon from "@dvuckovic/vue3-bootstrap-icons";
import ReserveForDepositEditModal from "../components/ReserveForDepositEditModal.vue";
import ReserveInformationItem from "../components/ReserveInformationItem.vue";
import OkModal from "./OkModal.vue";

export default {
  name: "ReserveItem",
  props: ["description", "deposit_id", "mine_area_id", "reserve_id"],
  data() {
    return {
      showEditReserve: false,
      reserveId: this.reserve_id,
      itemDescription: this.description,
      depositId: this.deposit_id,
      mineAreaId: this.mine_area_id,
      showReserveInformation: false,
      showAuthError: false,
      authMessage: "Доступ запрещен",
      authHeader: "Ошибка безопасности",
      showError: false,
      errorMessage: "",
      errorHeader: "Ошибка при выполнение запроса",
    };
  },
  methods: {
    hideErrorMessage() {
      this.showError = false;
    },
    hideAuthMessage() {
      this.showAuthError = false;
    },
    removeItem(e) {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      const url = this.$BASE_URL + "/api/delete_reserve/";
      axios
        .post(
          url,
          {
            reserve_id: this.reserveId,
            deposit_id: this.depositId,
            mine_area_id: this.mineAreaId,
          },
          { headers }
        )
        .then((response) => {
          if (!response.data[0].auth_fail) {
            if (response.data[0].result) {
              this.$emit("reserve-removed", {});
            } else {
              this.showError = true;
              this.errorMessage = response.data[0].reason;
            }
          } else {
            this.showAuthError = true;
          }
        });
      e.preventDefault();
    },
    saveItem(e) {
      this.$emit("reserve-edit", {
        reserve_id: this.reserveId,
        mine_area_id: this.mineAreaId,
        deposit_id: this.depositId,
      });
      e.preventDefault();
    },
    addOrEditReserveInformation(e) {
      this.showReserveInformation = true;
      e.preventDefault();
    },
    editItem(e) {
      this.showEditReserve = true;
      e.preventDefault();
    },
    onUpdateReserve() {
      this.showEditReserve = false;
      this.$emit("reserve-edit", {
        reserve_id: this.reserveId,
        mine_area_id: this.mineAreaId,
        deposit_id: this.depositId,
      });
    },
    onUpdateReserveInformation() {
      this.showReserveInformation = false;
    },
  },
  components: {
    BootstrapIcon,
    ReserveForDepositEditModal,
    ReserveInformationItem,
    OkModal,
  },
  mounted() {},
};
</script>

<style scoped>
img {
  height: 30px;
  width: 30px;
}

.item {
  margin: 12px auto;
  width: calc(100%);
  height: 50px;
  font-weight: bolder;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  border-style: none !important;
}
.delete-btn {
  height: 30px;
  display: inline;
  /*margin-right: 5px;*/
}
.save-btn {
  height: 30px;
  display: inline-block;
  /*margin-right: 5px;*/
}
.inputfield {
  display: inline;
  height: 30px;
  width: 330px;
}
</style>
