<template>
  <div class="table">
    <Filter v-on:filter="filterTable" v-bind:filterVal="filter" />
    <Spinner v-if="showSpinner" />
    <F5GRLicenseItem
      v-for="item in items"
      v-bind:key="item.license_id"
      v-bind:license_id="item.license_id"
      v-on:loaded="onItemLoaded"
    />
    <Paginator
      v-bind:count="totalItems"
      v-bind:ipp="ipp"
      v-bind:currentPage="page"
      v-on:page-click="changePage"
      v-bind:autoMargin="true"
    />
  </div>
</template>

<script>
import axios from "axios";
import F5GRLicenseItem from "../components/F5GRLicenseItem.vue";
import Paginator from "../components/Paginator.vue";
import Filter from "../components/Filter.vue";
import Spinner from "../components/Spinner.vue";

export default {
  name: "5GRLicenseTable",
  props: [],
  watch: {},
  data() {
    return {
      items: [],
      totalItems: 0,
      page: 1,
      ipp: 10,
      filter: "",
      loadedItems: 0,
      showSpinner: true,
    };
  },
  methods: {
    countItems() {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      var url = this.$BASE_URL + "/api/get_license_count/";
      axios
        .post(
          url,
          { license: this.filter, include_special_licenses: false },
          { headers }
        )
        .then((response) => {
          if (!response.data.auth_fail) {
            this.totalItems = response.data.result;
            console.log("COUNTING ITEMS " + this.totalItems);
            if (this.totalItems == 0) {
              this.loadedItems = 0;
              this.showSpinner = false;
            }
          }
        });
    },
    loadItems() {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      var url = this.$BASE_URL + "/api/get_licenses/";
      const offset = (this.page - 1) * this.ipp;
      axios
        .post(
          url,
          {
            license: this.filter,
            offset: offset,
            limit: this.ipp,
            include_special_licenses: false,
          },
          { headers }
        )
        .then((response) => {
          console.log(response.data.result);
          if (!response.data.auth_fail) {
            console.log(this.items);
            this.items = response.data.result;
          }
        });
    },
    onItemLoaded() {
      console.log("--------------------------------------");
      console.log("Total items " + this.totalItems);
      console.log(this.ipp * Math.floor(this.totalItems / this.ipp));

      this.loadedItems++;
      console.log("Loaded items " + this.loadedItems);
      if (this.loadedItems == this.ipp) {
        this.loadedItems = 0;
        this.showSpinner = false;
      } else if (
        this.loadedItems ==
        this.totalItems - this.ipp * Math.floor(this.totalItems / this.ipp)
      ) {
        this.loadedItems = 0;
        this.showSpinner = false;
      } else if (this.totalItems == 0) {
        this.loadedItems = 0;
        this.showSpinner = false;
      }
    },
    changePage(o) {
      this.page = o.page;
      this.loadItems();
      if (this.totalItems > 0) {
        this.showSpinner = true;
      }
    },
    filterTable(filter) {
      console.log("++++++++++++++++++++++++++++++++++++");
      console.log("Calling filtering....");
      this.items = [];
      this.page = 1;
      this.loadedItems = 0;
      this.filter = filter;
      this.showSpinner = true;
      this.countItems();
      this.loadItems();
    },
  },
  components: {
    F5GRLicenseItem,
    Paginator,
    Filter,
    Spinner,
  },
  mounted() {
    this.showSpinner = true;
    this.countItems();
    this.loadItems();
  },
};
</script>

<style scoped>
.table {
  width: 100%;
  height: 100%;
}
</style>
