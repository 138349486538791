<template>
  <div class="main_content">
    <div class="info">
      <div style="font-weight: bold; text-align: center">
        Аттрибутивная информация для ведомств
      </div>
      <OrgTable v-if="loaded" />
    </div>
  </div>
</template>

<script>
import OrgTable from "../components/OrgTable.vue";

export default {
  name: "F5GR",
  data() {
    return {
      loaded: true,
    };
  },
  methods: {},
  components: {
    OrgTable,
  },
  mounted() {},
};
</script>

<style scoped>
.info {
  width: 100%;
  height: 100%;
  float: right;
  display: inline;
  margin-left: 0px;
  margin-right: 0px;
}
</style>
