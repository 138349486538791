<template>
  <div class="item">
    <MovementYearModal
      v-if="showYearSelectionModal"
      v-bind:license_id="license_id"
      v-on:movements-close="closeMovementModal"
    />
    <CommentsYearModal
      v-if="showCommentsYearSelectionModal"
      v-bind:license_id="license_id"
      v-on:comments-close="closeCommentYearModal"
    />
    <MovementFillModal
      v-if="showMovementModal"
      v-bind:license_id="license_id"
      v-bind:year="selectedMomentYear"
      v-on:movement-close="closeMovementModal"
    />
    <table style="border-style: none !important; width: 100%">
      <tr style="border-style: none !important; width: 100%">
        <td style="width: 50px">
          <button
            @click="editMovements"
            class="btn btn-dark btn-lg btn-block btn-edit"
            style="float: left; width: 55px"
            title="Движение запасов"
          >
            <BootstrapIcon icon="calculator" />
          </button>
        </td>
        <!-- td style="width:50px">
          <button
            @click="editComments"
            class="btn btn-dark btn-lg btn-block btn-comments"
            style="float: left; width: 55px"
          >
            <BootstrapIcon icon="chat-square-dots-fill" />
          </button>
        </td -->
        <td style="width: 100%">
          <input
            type="text"
            class="form-control form-control-lg"
            style="display: inline; width: calc(100%)"
            v-model="license_description.description"
            disabled
          />
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import axios from "axios";

import BootstrapIcon from "@dvuckovic/vue3-bootstrap-icons";
import MovementYearModal from "../components/MomementYearModal.vue";
import CommentsYearModal from "../components/CommentsYearModal.vue";
import MovementFillModal from "../components/MovementFillModal.vue";

export default {
  name: "F5GRLicenseItem",
  props: ["license_id"],
  data() {
    return {
      license: {
        license: "",
      },
      license_description: {
        description: "",
      },
      showYearSelectionModal: false,
      showCommentsYearSelectionModal: false,
      showMovementModal: false,
      selectedMomentYear: new Date().getFullYear(),
    };
  },
  methods: {
    closeCommentYearModal() {
      this.showCommentsYearSelectionModal = false;
    },
    /*closeMovementModal() {
      this.showYearSelectionModal = false;
    },*/
    editMovements() {
      console.log("Editing the movements");
      /*this.showYearSelectionModal = true;*/
      this.showMovementModal = true;
    },
    closeMovementModal() {
      this.showMovementModal = false;
    },
    editComments() {
      this.showCommentsYearSelectionModal = true;
    },
    getLicense() {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      var url = this.$BASE_URL + "/api/get_license/";
      axios
        .post(url, { license_id: this.license_id }, { headers })
        .then((response) => {
          if (!response.data.auth_fail) {
            this.license = response.data.result;
            this.getLicenseDescription();
          }
        });
    },
    getLicenseDescription() {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      var url = this.$BASE_URL + "/api/get_license_description/";
      axios
        .post(url, { license_id: this.license_id }, { headers })
        .then((response) => {
          if (!response.data.auth_fail) {
            this.license_description = response.data.result;
            this.$emit("loaded");
          }
        });
    },
  },
  components: {
    MovementYearModal,
    BootstrapIcon,
    CommentsYearModal,
    MovementFillModal,
  },
  mounted() {
    this.getLicense();
  },
};
</script>

<style scoped>
img {
  height: 30px;
  width: 30px;
}

.item {
  width: calc(100%);
  height: 50px;
  font-weight: bolder;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  border-style: none !important;
}
.delete-btn {
  height: 30px;
  display: inline;
  /*margin-right: 5px;*/
}
.save-btn {
  height: 30px;
  display: inline-block;
  /*margin-right: 5px;*/
}
.inputfield {
  display: inline;
  height: 30px;
  width: 330px;
}
table {
  width: 90% !important;
  margin: 0 auto;
}
</style>
