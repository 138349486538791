<template>
  <div class="modal-mask">
    <div class="modal-window">
      <OkModal
        v-if="showError"
        v-bind:message="errorMessage"
        v-bind:header="header"
        v-on:confirm="hideErrorMessage"
      />
      <OkModal
        v-if="showAuthError"
        v-bind:message="authMessage"
        v-bind:header="authHeader"
        v-on:confirm="hideAuthMessage"
      />
      <div class="header">Добавление новой лицензии</div>
      <div class="body">
        <div class="body">
          <form class="form-inline">
            <table style="border: none !important">
              <tr>
                <td>
                  <div class="form-group col-xs-3">
                    <label>Лицензия для месторождения</label>
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      v-model="isLicenseForDeposit"
                      @click="onLicenseTypeChanged"
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="form-group col-xs-3">
                    <label>Месторождение</label>
                    <select
                      class="form-select form-control-lg"
                      v-model="selectedDepositId"
                      aria-label="Месторождение"
                      @change="onDepositChanged"
                      @keypress="fetchWeather"
                    >
                      <option
                        v-for="deposit in deposits"
                        v-bind:value="deposit.deposit_id"
                        v-bind:key="deposit.deposit_id"
                      >
                        {{ deposit.deposit_name }}
                      </option>
                    </select>
                  </div>
                </td>
                <td v-if="!isLicenseForDeposit">
                  <div class="form-group col-xs-3">
                    <label>Участок</label>
                    <select
                      class="form-select form-control-lg"
                      v-model="selectedMineAreaId"
                      aria-label="Участок"
                      @change="onMineAreaChanged"
                    >
                      <option
                        v-for="mineArea in mineAreas"
                        v-bind:value="mineArea.mine_area_id"
                        v-bind:key="mineArea.mine_area_id"
                      >
                        {{ mineArea.mine_area_name }}
                      </option>
                    </select>
                  </div>
                </td>
                <td>
                  <div class="form-group col-xs-3">
                    <label>Запасы</label>
                    <select
                      class="form-select form-control-lg"
                      v-model="selectedReserveId"
                      aria-label="Запасы"
                      @change="onReservesChanged"
                    >
                      <option
                        v-for="reserve in reserves"
                        v-bind:value="reserve.id_reserve"
                        v-bind:key="reserve.id_reserve"
                      >
                        {{ reserve.mineral }}, {{ reserve.usage }}
                      </option>
                    </select>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="form-group col-xs-3">
                    <label>Номер лицензии</label>
                    <input
                      type="text"
                      class="form-control form-control-lg"
                      v-model="licenseNumber"
                    />
                  </div>
                </td>
                <td>
                  <div class="form-group col-xs-3">
                    <label>Ведомство</label>
                    <select
                      class="form-select form-control-lg"
                      v-model="selectedOgranizationId"
                      aria-label="Запасы"
                    >
                      <option
                        v-for="organization in organizations"
                        v-bind:value="organization.id"
                        v-bind:key="organization.id"
                      >
                        {{ organization.description }}
                      </option>
                    </select>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="form-group col-xs-3">
                    <label>A</label>
                    <input
                      type="text"
                      class="form-control form-control-lg"
                      v-model="A"
                      @keypress="checkNumber(event, A)"
                    />
                  </div>
                </td>
                <td>
                  <div class="form-group col-xs-3">
                    <label>B</label>
                    <input
                      type="text"
                      class="form-control form-control-lg"
                      v-model="B"
                      @keypress="checkNumber(event, B)"
                    />
                  </div>
                </td>
                <td>
                  <div class="form-group col-xs-3">
                    <label>C1</label>
                    <input
                      type="text"
                      class="form-control form-control-lg"
                      v-model="C1"
                      @keypress="checkNumber(event, C1)"
                    />
                  </div>
                </td>
                <td>
                  <div class="form-group col-xs-3">
                    <label>C2</label>
                    <input
                      type="text"
                      class="form-control form-control-lg"
                      v-model="C2"
                      @keypress="checkNumber(event, C2)"
                    />
                  </div>
                </td>
                <td>
                  <div class="form-group col-xs-3">
                    <label>Забаланс</label>
                    <input
                      type="text"
                      class="form-control form-control-lg"
                      v-model="outOfBalance"
                      @keypress="checkNumber(event, outOfBalance)"
                    />
                  </div>
                </td>
              </tr>
              <!-- tr>
                <td>
                  <div class="form-group col-xs-3">
                    <label>Не указывать запасы для полезного ископаемого</label>
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      v-model="doNotSpecifyReservesForMineral"
                    />
                  </div>
                </td>
              </tr -->
              <tr v-if="!doNotSpecifyReservesForMineral">
                <td>
                  <div class="form-group col-xs-3">
                    <label>A (полезное ископаемое)</label>
                    <input
                      type="text"
                      class="form-control form-control-lg"
                      v-model="A_M"
                      @keypress="checkNumber(event, A_M)"
                    />
                  </div>
                </td>
                <td>
                  <div class="form-group col-xs-3">
                    <label>B (полезное ископаемое)</label>
                    <input
                      type="text"
                      class="form-control form-control-lg"
                      v-model="B_M"
                      @keypress="checkNumber(event, B_M)"
                    />
                  </div>
                </td>
                <td>
                  <div class="form-group col-xs-3">
                    <label>C1 (полезное ископаемое)</label>
                    <input
                      type="text"
                      class="form-control form-control-lg"
                      v-model="C1_M"
                      @keypress="checkNumber(event, C1_M)"
                    />
                  </div>
                </td>
                <td>
                  <div class="form-group col-xs-3">
                    <label>C2 (полезное ископаемое)</label>
                    <input
                      type="text"
                      class="form-control form-control-lg"
                      v-model="C2_M"
                      @keypress="checkNumber(event, C2_M)"
                    />
                  </div>
                </td>
                <td>
                  <div class="form-group col-xs-3">
                    <label>Забаланс (полезное ископаемое)</label>
                    <input
                      type="text"
                      class="form-control form-control-lg"
                      v-model="outOfBalance_M"
                      @keypress="checkNumber(event, outOfBalance_M)"
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="form-group col-xs-6">
                    <label>Дата от</label>
                    <input
                      type="date"
                      class="form-control form-control-lg"
                      v-model="dateFrom"
                    />
                  </div>
                </td>
                <td>
                  <div class="form-group col-xs-6">
                    <label>Дата до</label>
                    <input
                      type="date"
                      class="form-control form-control-lg"
                      v-model="dateTo"
                    />
                  </div>
                </td>
              </tr>
              <tr class="descr">
                <td colspan="6">
                  <div class="form-group col-xs-6">
                    <label>Описание</label>
                    <textarea
                      type="text"
                      class="form-control form-control-lg"
                      v-model="description"
                    >
                    </textarea>
                  </div>
                </td>
              </tr>
            </table>
            <div class="form-group" id="form-group2">
              <button
                @click="save"
                class="btn btn-dark btn-lg btn-block save"
                style="height: 55px"
              >
                Сохранить
              </button>
              <button
                @click="cancel"
                class="btn btn-dark btn-lg btn-block close"
                style="height: 55px"
              >
                Отменить
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import OkModal from "./OkModal.vue";
export default {
  name: "LicenseNewItem",
  props: [],
  data() {
    return {
      isLicenseForDeposit: true,
      doNotSpecifyReservesForMineral: true,
      showError: false,
      header: "Не удалось сохранить данные",
      errorMessage: "",
      failed: false,
      depositId: null,
      mineAreaId: null,
      licenseNumber: "",
      selectedOgranizationId: null,
      selectedReserveId: null,
      selectedDepositId: null,
      selectedMineAreaId: null,
      licenseId: null,
      A: 0,
      B: 0,
      C1: 0,
      C2: 0,
      outOfBalance: 0,
      A_M: 0,
      B_M: 0,
      C1_M: 0,
      C2_M: 0,
      outOfBalance_M: 0,
      dateFrom: "",
      dateTo: "",
      description: "",
      reserves: [],
      organizations: [],
      deposits: [],
      mineAreas: [],
      showAuthError: false,
      authMessage: "Доступ запрещен",
      authHeader: "Ошибка безопасности",
    };
  },
  methods: {
    checkNumber(evt, value) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 44
      ) {
        evt.preventDefault();
      } else {
        if (
          (charCode === 44 || charCode == 46) &&
          (new String(value).includes(",") || new String(value).includes("."))
        ) {
          evt.preventDefault();
        } else {
          return true;
        }
      }
    },
    hideAuthMessage() {
      this.showAuthError = false;
    },
    getDeposits() {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      const url = this.$BASE_URL + "/api/get_deposits/";
      axios
        .post(url, { limit: 100000, offset: 0 }, { headers })
        .then((response) => {
          if (!response.data[0].auth_fail) {
            this.deposits = response.data[0].result;
            if (response.data[0].result.length > 0) {
              this.selectedDepositId = response.data[0].result[0].deposit_id;
              console.log(this.selectedDepositId);
              this.deposits = response.data[0].result;
              if (!this.isLicenseForDeposit) {
                this.getMineAreas();
              } else {
                this.getReserves();
              }
            }
          }
        });
    },
    getMineAreas() {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      const url = this.$BASE_URL + "/api/get_mine_areas/";
      axios
        .post(
          url,
          { deposit_id: this.selectedDepositId, limit: 10000, offset: 0 },
          { headers }
        )
        .then((response) => {
          if (!response.data.auth_fail) {
            this.selectedMineAreaId = response.data.result[0].deposit_id;
            this.mineAreas = response.data.result;
            this.getReserves();
          }
        });
    },
    getReserves() {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      if (this.isLicenseForDeposit) {
        const url = this.$BASE_URL + "/api/get_reserves_for_deposit/";
        axios
          .post(url, { deposit_id: this.selectedDepositId }, { headers })
          .then((response) => {
            if (!response.data.auth_fail) {
              this.reserves = response.data.result;
            }
          });
      } else {
        const url = this.$BASE_URL + "/api/get_reserves_for_mine_area/";
        axios
          .post(
            url,
            {
              deposit_id: this.selectedDepositId,
              mine_area_id: this.selectedMineAreaId,
            },
            { headers }
          )
          .then((response) => {
            if (!response.data.auth_fail) {
              this.reserves = response.data.result;
            }
          });
      }
    },
    getReserve() {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      if (this.isLicenseForDeposit) {
        const url = this.$BASE_URL + "/api/get_reserve_for_deposit/";
        axios
          .post(
            url,
            {
              deposit_id: this.selectedDepositId,
              mine_area_id: this.selectedDepositId,
              reserve_id: this.selectedReserveId,
            },
            { headers }
          )
          .then((response) => {
            if (!response.data.auth_fail) {
              this.doNotSpecifyReservesForMineral =
                !response.data.result.use_reserves_for_mineral;
            }
          });
      } else {
        const url = this.$BASE_URL + "/api/get_reserve_for_deposit/";
        axios
          .post(
            url,
            {
              deposit_id: this.selectedDepositId,
              mine_area_id: this.selectedMineAreaId,
              reserve_id: this.selectedReserveId,
            },
            { headers }
          )
          .then((response) => {
            if (!response.data.auth_fail) {
              this.doNotSpecifyReservesForMineral =
                !response.data.result.use_reserves_for_mineral;
            }
          });
      }
    },
    getOrganizations() {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      const url = this.$BASE_URL + "/api/get_dictionary_contents/";
      axios
        .post(
          url,
          { category: "SPR_VED", limit: 100000, offset: 0 },
          { headers }
        )
        .then((response) => {
          if (!response.data.auth_fail) {
            this.organizations = response.data.result;
          }
        });
    },
    save(e) {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      const url = this.$BASE_URL + "/api/add_license/";
      axios
        .post(
          url,
          {
            deposit_id: this.selectedDepositId,
            mine_area_id: this.isLicenseForDeposit
              ? this.selectedDepositId
              : this.selectedMineAreaId,
            A: new String(this.A).replace(",", "."),
            B: new String(this.B).replace(",", "."),
            C1: new String(this.C1).replace(",", "."),
            C2: new String(this.C2).replace(",", "."),
            out_of_balance: new String(this.outOfBalance).replace(",", "."),
            id_measure_mineral: this.selectedMeasureMId,
            A_M: new String(this.A_M).replace(",", "."),
            B_M: new String(this.B_M).replace(",", "."),
            C1_M: new String(this.C1_M).replace(",", "."),
            C2_M: new String(this.C2_M).replace(",", "."),
            M_out_of_balance: new String(this.outOfBalance_M).replace(",", "."),
            license: this.licenseNumber,
            reserve_id: this.selectedReserveId,
            organization_id: this.selectedOgranizationId,
            description: this.description,
            date_from: this.dateFrom,
            date_to: this.dateTo,
          },
          { headers }
        )
        .then((response) => {
          if (!response.data[0].auth_fail) {
            if (!response.data[0].result) {
              this.errorMessage = response.data[0].reason;
              this.showError = true;
            } else {
              this.$emit("add-new-license");
            }
          } else {
            this.showAuthError = true;
          }
        });
      e.preventDefault();
    },
    onDepositChanged() {
      if (this.isLicenseForDeposit) {
        this.getReserves();
      } else {
        this.getMineAreas();
      }
    },
    onMineAreaChanged() {
      this.getReserves();
    },
    onLicenseTypeChanged() {
      if (this.isLicenseForDeposit) {
        this.selectedMineAreaId = null;
        this.selectedReserveId = null;
      }
      this.getDeposits();
    },
    hideErrorMessage() {
      this.showError = false;
    },
    cancel(e) {
      this.$emit("add-new-license");
      e.preventDefault();
    },
    onReservesChanged() {
      this.getReserve();
    },
  },
  components: {
    OkModal,
  },
  mounted() {
    this.getDeposits();
    this.getOrganizations();
  },
};
</script>

<style scoped>
.modal-mask {
  position: fixed;
  display: flex;
  justify-content: center;
  z-index: 9997;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s ease;
}

.modal-window {
  border: rgb(169, 255, 202);
  background-color: white;
  position: fixed;
  width: 85%;
  height: 35rem;
  z-index: 100;
  top: 5%;
  height: 700px !important;
}

.header {
  height: 30px;
  width: 100%;
  background-color: rgb(143, 150, 150);
  text-align: center;
  font-weight: bold;
}

.body {
  font-weight: bolder;
  color: black;
  text-align: center;
}

.close {
  position: relative;
  top: 85px;
  left: -40px;
}

.save {
  position: relative;
  top: 85px;
  left: -65px;
}
td {
  margin-left: 100px !important;
}
table {
  border-spacing: 20px !important;
}

#form-group2 {
  /*margin-top: 10px;*/
  position: absolute;
  justify-content: right;
  /*display: flex;*/
  bottom: 100px;
  right: -0.7rem;
}

/*.descr {
  position: relative;
  left: 5rem;
}*/
</style>
