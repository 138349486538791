<template>
  <div class="modal-mask">
    <div class="modal-window">
      <Spinner v-if="showSpinner" />
      <OkModal
        v-if="showError"
        v-bind:message="errorMessage"
        v-bind:header="header"
        v-on:confirm="hideErrorMessage"
      />
      <OkModal
        v-if="showAuthError"
        v-bind:message="authMessage"
        v-bind:header="authHeader"
        v-on:confirm="hideAuthMessage"
      />
      <div class="header">
        Свойства месторождения {{ deposit.deposit_name }}
      </div>
      <div class="body">
        <form class="form-inline">
          <table style="border: none !important">
            <tr>
              <td>Координаты месторождения</td>
              <td></td>
            </tr>
            <tr>
              <td>
                <div class="form-group col-xs-3">
                  <label>Долгота</label>
                  <input
                    type="text"
                    class="form-control form-control-lg"
                    v-model="longitude"
                    @keypress="checkNumber(event, longitude)"
                  />
                </div>
              </td>
              <td>
                <div class="form-group col-xs-3">
                  <label>Широта</label>
                  <input
                    type="text"
                    class="form-control form-control-lg"
                    v-model="latitude"
                    @keypress="checkNumber(event, latitude)"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td>Ближайший населенный пункт</td>
              <td></td>
            </tr>
            <tr>
              <td>
                <div class="form-group col-xs-3">
                  <label>Наименование</label>
                  <input
                    type="text"
                    class="form-control form-control-lg"
                    v-model="inhab_area.name"
                  />
                </div>
              </td>
              <td>
                <div class="form-group col-xs-3">
                  <label>Направление</label>
                  <input
                    type="text"
                    class="form-control form-control-lg"
                    v-model="inhab_area.direction"
                  />
                </div>
              </td>
              <td>
                <div class="form-group col-xs-3">
                  <label>Расстояние</label>
                  <input
                    type="text"
                    class="form-control form-control-lg"
                    v-model="inhab_area.distance"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td>Ближайшая железнодорожная станция</td>
              <td></td>
            </tr>
            <tr>
              <td>
                <div class="form-group col-xs-3">
                  <label>Наименование</label>
                  <input
                    type="text"
                    class="form-control form-control-lg"
                    v-model="train_station.name"
                  />
                </div>
              </td>
              <td>
                <div class="form-group col-xs-3">
                  <label>Расстояние</label>
                  <input
                    type="text"
                    class="form-control form-control-lg"
                    v-model="train_station.distance"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td>Экспедиции</td>
              <td></td>
            </tr>
            <tr>
              <td>
                <div class="form-group col-xs-3">
                  <label>Описание</label>
                  <input
                    type="text"
                    class="form-control form-control-lg"
                    v-model="expedition.description"
                  />
                </div>
              </td>
              <td>
                <div class="form-group col-xs-3">
                  <label>Ведомство</label>
                  <select
                    class="form-select form-control-lg"
                    v-model="expedition.organization_id"
                    aria-label="Запасы"
                  >
                    <option
                      v-for="organization in organizations"
                      v-bind:value="organization.id"
                      v-bind:key="organization.id"
                    >
                      {{ organization.description }}
                    </option>
                  </select>
                </div>
              </td>
              <td>
                <div class="form-group col-xs-3">
                  <label>Дата от</label>
                  <input
                    type="date"
                    class="form-control form-control-lg"
                    v-model="expedition.date_from"
                  />
                </div>
              </td>
              <td>
                <div class="form-group col-xs-3">
                  <label>Дата до</label>
                  <input
                    type="date"
                    class="form-control form-control-lg"
                    v-model="expedition.date_to"
                  />
                </div>
              </td>
            </tr>
          </table>
        </form>
        <div class="form-group" style="margin-top: 10px">
          <button @click="save" class="btn btn-dark btn-lg btn-block save">
            Сохранить
          </button>
        </div>
        <div class="form-group" style="margin-top: 10px">
          <button @click="close" class="btn btn-dark btn-lg btn-block close">
            Закрыть
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

import OkModal from "./OkModal.vue";
import Spinner from "../components/Spinner.vue";

export default {
  name: "DepositPropertiesModal",
  props: ["deposit_id"],
  data() {
    return {
      showError: false,
      errorMessage: "",
      header: "Ошибка при сохранение данных",
      deposit: {
        deposit_name: "",
      },
      inhab_area: {
        name: "",
        distance: "",
        direction: "",
      },
      expedition: {
        description: "",
        date_from: null,
        date_to: null,
        organization_id: "",
      },
      train_station: {
        name: "",
        distance: "",
      },
      organizations: [],
      longitude: 0.0,
      latitude: 0.0,
      numberOfCommits: 0,
      neededNumberOfCommits: 4,
      itemsToLoad: 6,
      loadedItems: 0,
      showSpinner: true,
      showAuthError: false,
      authMessage: "Доступ запрещен",
      authHeader: "Ошибка безопасности",
    };
  },
  methods: {
    checkNumber(evt, value) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 44
      ) {
        evt.preventDefault();
      } else {
        if (
          (charCode === 44 || charCode == 46) &&
          (new String(value).includes(",") || new String(value).includes("."))
        ) {
          evt.preventDefault();
        } else {
          return true;
        }
      }
    },
    hideErrorMessage() {
      this.showError = false;
    },
    hideAuthMessage() {
      this.showAuthError = false;
    },
    getDepositInfo() {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      var url = this.$BASE_URL + "/api/get_deposit/";
      axios
        .post(url, { deposit_id: this.deposit_id }, { headers })
        .then((response) => {
          if (!response.data.auth_fail) {
            this.deposit = response.data.result;
            this.loadedItems++;
            if (this.loadedItems == this.itemsToLoad) {
              this.showSpinner = false;
            }
          }
        });
    },
    getOrganizations() {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      const url = this.$BASE_URL + "/api/get_dictionary_contents/";
      axios
        .post(
          url,
          { category: "SPR_VED", limit: 100000, offset: 0 },
          { headers }
        )
        .then((response) => {
          if (!response.data.auth_fail) {
            this.organizations = response.data.result;
            this.loadedItems++;
            if (this.loadedItems == this.itemsToLoad) {
              this.showSpinner = false;
            }
          }
        });
    },
    getCoordinates() {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      const url = this.$BASE_URL + "/api/get_coordinates/";
      axios
        .post(url, { deposit_id: this.deposit_id }, { headers })
        .then((response) => {
          if (!response.data.auth_fail) {
            this.latitude = new String(response.data.result.latitude).replace(
              ".",
              ","
            );
            this.longitude = new String(response.data.result.longitude).replace(
              ".",
              ","
            );
            this.loadedItems++;
            if (this.loadedItems == this.itemsToLoad) {
              this.showSpinner = false;
            }
          }
        });
    },
    saveCoordinates() {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      const url = this.$BASE_URL + "/api/save_or_update_coordinates/";
      axios
        .post(
          url,
          {
            deposit_id: this.deposit_id,
            latitude: this.latitude,
            longitude: this.longitude,
          },
          { headers }
        )
        .then((response) => {
          if (!response.data[0].auth_fail) {
            if (!response.data[0].result) {
              this.errorMessage = response.data[0].reason;
              this.showError = true;
              this.numberOfCommits = 0;
            } else {
              this.numberOfCommits++;
              if (this.numberOfCommits == this.neededNumberOfCommits) {
                this.$emit("deposit-props-save", {});
              }
            }
          } else {
            this.showAuthError = true;
          }
        });
    },
    getExpeditions() {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      const url = this.$BASE_URL + "/api/get_expedition/";
      axios
        .post(url, { deposit_id: this.deposit_id }, { headers })
        .then((response) => {
          if (!response.data.auth_fail) {
            this.expedition = response.data.result;
            this.loadedItems++;
            if (this.loadedItems == this.itemsToLoad) {
              this.showSpinner = false;
            }
          }
        });
    },
    saveExpeditions() {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      const url = this.$BASE_URL + "/api/save_or_update_expedition/";
      axios
        .post(
          url,
          {
            deposit_id: this.deposit_id,
            organization_id: this.expedition.organization_id,
            date_from: this.expedition.date_from,
            date_to: this.expedition.date_to,
            description: this.expedition.description,
          },
          { headers }
        )
        .then((response) => {
          if (!response.data[0].auth_fail) {
            if (!response.data[0].result) {
              this.errorMessage = response.data[0].reason;
              this.showError = true;
              this.numberOfCommits = 0;
            } else {
              this.numberOfCommits++;
              if (this.numberOfCommits == this.neededNumberOfCommits) {
                this.$emit("deposit-props-save", {});
              }
            }
          } else {
            this.showAuthError = true;
          }
        });
    },
    getInhabitedArea() {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      const url = this.$BASE_URL + "/api/get_inhab_area/";
      axios
        .post(url, { deposit_id: this.deposit_id }, { headers })
        .then((response) => {
          if (!response.data.auth_fail) {
            this.inhab_area = response.data.result;
            this.loadedItems++;
            if (this.loadedItems == this.itemsToLoad) {
              this.showSpinner = false;
            }
          }
        });
    },
    saveInhabitedArea() {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      const url = this.$BASE_URL + "/api/save_or_update_inhabited_areas/";
      axios
        .post(
          url,
          {
            deposit_id: this.deposit_id,
            name: this.inhab_area.name,
            direction: this.inhab_area.direction,
            distance: this.inhab_area.distance,
          },
          { headers }
        )
        .then((response) => {
          if (!response.data[0].auth_fail) {
            this.numberOfCommits++;
            if (this.numberOfCommits == this.neededNumberOfCommits) {
              this.$emit("deposit-props-save", {});
            }
          } else {
            this.showAuthError = true;
          }
        });
    },
    getTrainStations() {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      const url = this.$BASE_URL + "/api/get_train_station/";
      axios
        .post(url, { deposit_id: this.deposit_id }, { headers })
        .then((response) => {
          if (!response.data.auth_fail) {
            this.train_station = response.data.result;
            this.loadedItems++;
            if (this.loadedItems == this.itemsToLoad) {
              this.showSpinner = false;
            }
          }
        });
    },
    saveTrainStation() {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      const url = this.$BASE_URL + "/api/save_or_update_train_stations/";
      axios
        .post(
          url,
          {
            deposit_id: this.deposit_id,
            name: this.train_station.name,
            distance: this.train_station.distance,
          },
          { headers }
        )
        .then((response) => {
          if (!response.data[0].auth_fail) {
            this.numberOfCommits++;
            if (this.numberOfCommits == this.neededNumberOfCommits) {
              this.$emit("deposit-props-save", {});
            }
          } else {
            this.showAuthError = true;
          }
        });
    },
    close(e) {
      this.$emit("deposit-props-close", {});
      e.preventDefault();
    },
    save(e) {
      this.saveCoordinates();
      this.saveInhabitedArea();
      this.saveExpeditions();
      this.saveTrainStation();
      e.preventDefault();
    },
  },
  mounted() {
    this.getDepositInfo();
    this.getOrganizations();
    this.getCoordinates();
    this.getInhabitedArea();
    this.getExpeditions();
    this.getTrainStations();
  },
  components: {
    OkModal,
    Spinner,
  },
};
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s ease;
}

.modal-window {
  border: rgb(169, 255, 202);
  background-color: white;
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -300px;
  margin-left: -400px;
  width: 800px;
  height: 600px;
  z-index: 100;
}

.header {
  height: 30px;
  width: 100%;
  background-color: rgb(143, 150, 150);
  text-align: center;
  font-weight: bold;
}

.body {
  font-weight: bolder;
  color: black;
  text-align: center;
}

.close {
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.save {
  position: absolute;
  bottom: 10px;
  left: 10px;
}
</style>
