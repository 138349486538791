<template>
  <div class="item">
    <ConfirmModal
      v-if="showConfirmModal"
      v-bind:message="confirmMessage"
      v-bind:header="confirmHeader"
      v-on:confirm="onConfirm"
      v-on:cancel="onCancel"
    />
    <OkModal
      v-if="showAuthError"
      v-bind:message="authMessage"
      v-bind:header="authHeader"
      v-on:confirm="hideAuthMessage"
    />
    <OkModal
      v-if="showError"
      v-bind:message="errorMessage"
      v-bind:header="errorHeader"
      v-on:confirm="hideErrorMessage"
    />
    <OrgInfoModal
      v-if="showOrgInfoModal"
      v-bind:org="description"
      v-bind:id="id"
      v-on:info-close="hideOrgInfo"
    />
    <table style="border-style: none !important; width: 100%">
      <tr style="border-style: none !important; width: 100%">
        <td style="width: 50px">
          <button
            @click="editItem"
            class="btn btn-dark btn-lg btn-block btn-edit"
            style="float: left; width: 55px"
          >
            <BootstrapIcon icon="pencil" />
          </button>
        </td>
        <td style="width: 100%">
          <input
            type="text"
            class="form-control form-control-lg"
            style="display: inline; width: calc(100%)"
            v-model="company"
            disabled
          />
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
//import axios from "axios";

import BootstrapIcon from "@dvuckovic/vue3-bootstrap-icons";
import OkModal from "./OkModal.vue";
import OrgInfoModal from "../components/OrgInfoModal.vue";

export default {
  name: "OrgItem",
  props: ["id", "description"],
  data() {
    return {
      company: this.description,
      showEditLicenseModal: false,
      showAuthError: false,
      authMessage: "Доступ запрещен",
      authHeader: "Ошибка безопасности",
      showError: false,
      errorMessage: "",
      errorHeader: "Возникла ошибка при выполнение запроса",
      showConfirmModal: false,
      showOrgInfoModal: false,
    };
  },
  methods: {
    editItem() {
      this.showOrgInfoModal = true;
    },
    hideOrgInfo() {
      this.showOrgInfoModal = false;
    },
    hideErrorMessage() {
      this.showError = false;
    },
    hideAuthMessage() {
      this.showAuthError = false;
    },
  },
  components: {
    BootstrapIcon,
    OkModal,
    OrgInfoModal,
  },
  mounted() {
    this.$emit("loaded");
  },
};
</script>

<style scoped>
img {
  height: 30px;
  width: 30px;
}

.item {
  width: calc(100%);
  height: 50px;
  font-weight: bolder;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  border-style: none !important;
}
.delete-btn {
  height: 30px;
  display: inline;
  /*margin-right: 5px;*/
}
.save-btn {
  height: 30px;
  display: inline-block;
  /*margin-right: 5px;*/
}
.inputfield {
  display: inline;
  height: 30px;
  width: 330px;
}
table {
  width: 90% !important;
  margin: 0 auto;
}
</style>
