<template>
  <div style="width: 100%">
    <table style="width: 100%">
      <tr
        class="msr_content"
        v-for="(license, number, index) in licenses"
        v-bind:key="number"
      >
        <td style="width: calc(7%)">
          <table style="width: 100%">
            <tr style="width: 100%; font-size: 8pt">
              <td style="width: calc(50%)">
                {{ license["A_B_C1"] }}
              </td>
              <td style="width: calc(50%)">
                {{ license["C2"] }}
              </td>
            </tr>
          </table>
        </td>
        <td style="width: calc(23%)">
          <table style="width: 100%">
            <tr style="width: 100%">
              <td
                style="width: 50%; vertical-align: top; word-wrap: break-word"
                v-if="index == 0"
              >
                {{ depositinfo["status"] }}
              </td>
              <td style="width: 50%" v-if="index != 0"></td>
              <td
                v-if="number != 'Балансовые запасы ГосКомГеологии'"
                style="width: 50%; font-weight: bold"
              >
                {{ number }}
                <span v-if="license['date_from']">
                  от {{ new Date(license["date_from"]).toLocaleDateString() }}
                </span>
              </td>
              <td
                v-if="number == 'Балансовые запасы ГосКомГеологии'"
                style="width: 50%"
              ></td>
            </tr>
          </table>
        </td>
        <td style="width: calc(8%)"></td>
        <td style="width: calc(13%)">
          {{ license["org"] }}
        </td>
        <td
          v-if="index == 0"
          :rowspan="Object.keys(licenses).length"
          style="width: calc(13%); vertical-align: top; text-align: center"
        >
          {{ depositinfo["org_signed"] }}, {{ depositinfo["year_signed"] }},
          {{ depositinfo["nomer_protocola"] }}
        </td>
        <td
          v-if="index == 0"
          :rowspan="Object.keys(licenses).length"
          style="width: calc(23%); vertical-align: top; text-align: center"
        >
          {{ component }}
        </td>
        <td
          v-if="index == 0"
          :rowspan="Object.keys(licenses).length"
          style="width: calc(13%); vertical-align: top; text-align: center"
        >
          {{ depositinfo["county"] }}
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  name: "MSRLicenseItem",
  props: ["depositinfo", "component", "depositname"],
  data() {
    return {
      numberOfMineAreas: 0,
      depositDescription: "",
      licenses: [],
    };
  },
  methods: {},
  components: {
    //OkModal,
  },
  mounted() {
    console.log(JSON.stringify(this.depositinfo["licenses"]));
    if (!this.depositinfo) {
      console.log("-==+++++++++++++++++++++++---------------");
      console.log(this.depositname);
    }
    this.licenses = this.depositinfo["licenses"];
  },
};
</script>

<style scoped>
.msr_content {
  background-color: #ffffe6;
  color: black;
  text-align: center;
  min-height: 50px;
  width: 100%;
}
table,
th,
td {
  /*border: 1px solid;*/
  height: 100%;
  widht: 100%;
}
</style>
