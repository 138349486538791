<template>
  <div class="item">
    <ConfirmModal
      v-if="showConfirmModal"
      v-bind:message="confirmMessage"
      v-bind:header="confirmHeader"
      v-on:confirm="onConfirm"
      v-on:cancel="onCancel"
    />
    <OkModal
      v-if="showAuthError"
      v-bind:message="authMessage"
      v-bind:header="authHeader"
      v-on:confirm="hideAuthMessage"
    />
    <table style="border-style: none !important; width: 100%">
      <tr style="border-style: none !important; width: 100%">
        <td style="width: 50px; border-style: none !important">
          <button
            @click="removeItem"
            class="btn btn-dark btn-lg btn-block btn-delete"
            style="float: left; width: 55px"
            title="Удалить запись"
          >
            <BootstrapIcon icon="trash" />
          </button>
        </td>
        <td style="width: 50px">
          <button
            @click="editItem"
            class="btn btn-dark btn-lg btn-block btn-edit"
            style="float: left; width: 55px"
            title="Редактировать запись"
          >
            <BootstrapIcon icon="pencil" />
          </button>
        </td>
        <td style="width: 100%">
          <input
            type="text"
            class="form-control form-control-lg"
            style="display: inline; width: calc(100%)"
            v-model="commentFull"
            disabled
          />
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import axios from "axios";
import BootstrapIcon from "@dvuckovic/vue3-bootstrap-icons";
import ConfirmModal from "../components/ConfirmModal.vue";
import OkModal from "../components/OkModal.vue";

export default {
  name: "CommentItem",
  props: [
    "id_deposit",
    "mine_area_id",
    "license_id",
    "comment",
    "column_number",
    "year",
  ],
  data() {
    return {
      commentColumns: {
        5: "Балансовые запасы A+B+C1",
        6: "Балансовые запасы С2",
        7: "Добыча A+B+C1",
        8: "Добыча C2",
        9: "Потери при добыче A+B+C1",
        10: "Потери при добыче C2",
        11: "Разведка и переоценка A+B+C1",
        12: "Разведка и переоценка C2",
        13: "Изменения тех. границ A+B+C1",
        14: "Изменения тех. границ C2",
        17: "Состояния запасов по категрии A",
        18: "Состояния запасов по категрии B",
        19: "Состояния запасов по категрии C1",
        20: "Состояния запасов по категрии A+B+C1",
        21: "Состояния запасов по категрии C2",
        22: "Состояния запасов по категрии забаланс",
      },
      commentFull: "",
      confirmMessage: "",
      confirmHeader: "",
      showConfirmModal: false,
      showAuthError: false,
      authMessage: "Доступ запрещен",
      authHeader: "Ошибка безопасности",
    };
  },
  methods: {
    hideAuthMessage() {
      this.showAuthError = false;
    },
    onConfirm() {
      alert("Confirm");
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      const url = this.$BASE_URL + "/api/delete_comment_5gr/";
      axios
        .post(
          url,
          {
            deposit_id: this.id_deposit,
            mine_area_id: this.mine_area_id,
            license_id: this.license_id,
            column_number: this.column_number,
            year: this.year,
          },
          { headers }
        )
        .then((response) => {
          if (!response.data[0].auth_fail) {
            this.$emit("comment-deleted", {});
          } else {
            this.showAuthError = true;
          }
        });
    },
    onCancel() {
      this.showConfirmModal = false;
    },
    removeItem(e) {
      this.confirmMessage = "Удалить комметарий?";
      this.confirmHeader = "Удаление данных!";
      this.showConfirmModal = true;
      e.preventDefault();
    },
    editItem(e) {
      this.$emit("comment-edited", {
        id_deposit: this.deposit_id,
        mine_area_id: this.mine_area_id,
        license_id: this.license_id,
        column_number: this.column_number,
        year: this.year,
        comment: this.comment,
      });
      e.preventDefault();
    },
  },
  components: {
    BootstrapIcon,
    ConfirmModal,
    OkModal,
  },
  mounted() {
    this.commentFull =
      this.commentColumns[this.column_number] + ", " + this.comment;
  },
};
</script>

<style scoped>
img {
  height: 30px;
  width: 30px;
}

.item {
  width: calc(100%);
  height: 50px;
  font-weight: bolder;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  border-style: none !important;
}
.delete-btn {
  height: 30px;
  display: inline;
  /*margin-right: 5px;*/
}
.save-btn {
  height: 30px;
  display: inline-block;
  /*margin-right: 5px;*/
}
.inputfield {
  display: inline;
  height: 30px;
  width: 330px;
}
</style>
