import { createRouter, createWebHistory } from "vue-router";
import F5GR from "../views/F5GR.vue";
import MSR from "../views/MSR.vue";
import Dicts from "../views/Dicts.vue";
import Reports from "../views/Reports.vue";
import Configuration from "../views/Configuration.vue";
import Districts from "../views/Districts.vue";
import Deposits from "../views/Deposits.vue";
import Licenses from "../views/Licenses.vue";
import SecuritySettings from "../views/SecuritySettings.vue";
import About from "../views/About.vue";
import SumGroups from "../views/SumGroups.vue";
import OrgInfo from "../views/OrgInfo.vue";
import Import from "../views/Import.vue";

const routes = [
  {
    path: "/",
    name: "Форма 5ГР",
    component: F5GR,
  },
  {
    path: "/5gr",
    name: "Форма 5ГР",
    component: F5GR,
  },
  {
    path: "/msr",
    name: "МСР",
    component: MSR,
  },
  {
    path: "/dicts",
    name: "Справочники",
    component: Dicts,
  },
  {
    path: "/reports",
    name: "Отчеты",
    component: Reports,
  },
  {
    path: "/configuration",
    name: "Настройки",
    component: Configuration,
  },
  {
    path: "/districts",
    name: "Районы",
    component: Districts,
  },
  {
    path: "/deposits",
    name: "Месторождения",
    component: Deposits,
  },
  {
    path: "/licenses",
    name: "Лицензии",
    component: Licenses,
  },
  {
    path: "/about",
    name: "О программе",
    component: About,
  },
  {
    path: "/security",
    name: "Безопасность",
    component: SecuritySettings,
  },
  {
    path: "/sum_groups",
    name: "Группы итогов",
    component: SumGroups,
  },
  {
    path: "/org_info",
    name: "Доп. информация о ведомствах",
    component: OrgInfo,
  },
  {
    path: "/import",
    name: "Импорт",
    component: Import,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.replace({ path: "*", redirect: "/" });

export default router;
