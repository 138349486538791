<template>
  <div class="item">
    <LicenseEditItem
      v-bind:license_id="license_id"
      v-if="showEditLicenseModal"
      v-on:edit-updated-close="onEditModalClose"
    />
    <ConfirmModal
      v-if="showConfirmModal"
      v-bind:message="confirmMessage"
      v-bind:header="confirmHeader"
      v-on:confirm="onConfirm"
      v-on:cancel="onCancel"
    />
    <OkModal
      v-if="showAuthError"
      v-bind:message="authMessage"
      v-bind:header="authHeader"
      v-on:confirm="hideAuthMessage"
    />
    <OkModal
      v-if="showError"
      v-bind:message="errorMessage"
      v-bind:header="errorHeader"
      v-on:confirm="hideErrorMessage"
    />
    <table style="border-style: none !important; width: 100%">
      <tr style="border-style: none !important; width: 100%">
        <td style="width: 50px; border-style: none !important">
          <button
            @click="removeItem"
            class="btn btn-dark btn-lg btn-block btn-delete"
            style="float: left; width: 55px"
            title="Удалить запись"
          >
            <BootstrapIcon icon="trash" />
          </button>
        </td>
        <td style="width: 50px">
          <button
            @click="editItem"
            class="btn btn-dark btn-lg btn-block btn-edit"
            style="float: left; width: 55px"
            title="Редактировать запись"
          >
            <BootstrapIcon icon="pencil" />
          </button>
        </td>
        <td style="width: 100%">
          <input
            type="text"
            class="form-control form-control-lg"
            style="display: inline; width: calc(100%)"
            v-model="license_description.description"
            disabled
          />
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import axios from "axios";

import BootstrapIcon from "@dvuckovic/vue3-bootstrap-icons";
import LicenseEditItem from "../components/LicenseEditItem.vue";
import ConfirmModal from "../components/ConfirmModal.vue";
import OkModal from "./OkModal.vue";

export default {
  name: "LicenseItem",
  props: ["license_id"],
  data() {
    return {
      showEditLicenseModal: false,
      license: {
        license: "",
      },
      license_description: {
        description: "",
      },
      showAuthError: false,
      authMessage: "Доступ запрещен",
      authHeader: "Ошибка безопасности",
      showError: false,
      errorMessage: "",
      errorHeader: "Возникла ошибка при выполнение запроса",
      confirmMessage:
        "Удалить лицензию? При удаление будут удалены связанные записи (движение запасов)",
      confirmHeader: "Удаление данных по лицензии",
      showConfirmModal: false,
    };
  },
  methods: {
    hideErrorMessage() {
      this.showError = false;
    },
    hideAuthMessage() {
      this.showAuthError = false;
    },
    onCancel() {
      this.showConfirmModal = false;
    },
    onConfirm() {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      var url = this.$BASE_URL + "/api/delete_license/";
      axios
        .post(url, { license_id: this.license_id }, { headers })
        .then((response) => {
          if (!response.data[0].auth_fail) {
            if (!response.data[0].result) {
              this.showError = true;
              this.errorMessage = response.data[0].reason;
            } else {
              this.$emit("license-updated");
              this.showConfirmModal = false;
            }
          } else {
            this.showAuthError = true;
          }
        });
    },
    getLicense() {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      var url = this.$BASE_URL + "/api/get_license/";
      axios
        .post(url, { license_id: this.license_id }, { headers })
        .then((response) => {
          if (!response.data.auth_fail) {
            this.license = response.data.result;
            this.getLicenseDescription();
          }
        });
    },
    getLicenseDescription() {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      var url = this.$BASE_URL + "/api/get_license_description/";
      axios
        .post(url, { license_id: this.license_id }, { headers })
        .then((response) => {
          if (!response.data.auth_fail) {
            this.license_description = response.data.result;
            this.$emit("loaded");
          }
        });
    },
    removeItem(e) {
      this.showConfirmModal = true;
      e.preventDefault();
    },
    editItem(e) {
      this.showEditLicenseModal = true;
      e.preventDefault();
    },
    onEditModalClose() {
      this.showEditLicenseModal = false;
    },
  },
  components: {
    LicenseEditItem,
    BootstrapIcon,
    OkModal,
    ConfirmModal,
  },
  mounted() {
    this.getLicense();
  },
};
</script>

<style scoped>
img {
  height: 30px;
  width: 30px;
}

.item {
  width: calc(100%);
  height: 50px;
  font-weight: bolder;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  border-style: none !important;
}
.delete-btn {
  height: 30px;
  display: inline;
  /*margin-right: 5px;*/
}
.save-btn {
  height: 30px;
  display: inline-block;
  /*margin-right: 5px;*/
}
.inputfield {
  display: inline;
  height: 30px;
  width: 330px;
}
table {
  width: 90% !important;
  margin: 0 auto;
}
</style>
