<template>
  <div class="modal-mask">
    <div class="modal-window">
      <Spinner v-if="showSpinner" />
      <OkModal
        v-if="showError"
        v-bind:message="errorMessage"
        v-bind:header="header"
        v-on:confirm="hideErrorMessage"
      />
      <OkModal
        v-if="showAuthError"
        v-bind:message="authMessage"
        v-bind:header="authHeader"
        v-on:confirm="hideAuthMessage"
      />
      <CommentsModal
        v-if="showCommentsModal"
        v-bind:deposit_id="depositId"
        v-bind:mine_area_id="mineAreaId"
        v-bind:license_id="license_id"
        v-bind:year="year"
        v-on:comments-close="hideCommentsModal"
      />
      <div class="header">
        Движение запасов для лицензии {{ license_description }} на год
        {{ year }}
      </div>
      <div class="body">
        <div class="controls">
          <!-- div class="year">{{ year }} год</div -->
          <div class="comment">
            <button
              @click="editComments"
              class="btn btn-dark btn-lg btn-block btn-comments"
              style="float: left; width: 55px; height: 55px"
              title="Примечания"
            >
              <BootstrapIcon icon="chat-square-dots-fill" />
            </button>
          </div>
        </div>
        <div class="resources">
          <div class="column">
            <div class="header">Лицензионные запасы</div>
            <div style="margin-top: 5px">
              <div style="width: 80%; text-align: left; margin: auto">
                <div style="font-weight: bold; width: 100%">
                  <div
                    style="
                      float: left;
                      width: 50%;
                      border-bottom: 2px solid black;
                    "
                  >
                    A
                  </div>
                  <div
                    style="
                      float: right;
                      width: 50%;
                      text-align: right;
                      border-bottom: 2px solid black;
                    "
                  >
                    {{ license.A }}
                  </div>
                </div>
              </div>
              <div style="width: 80%; text-align: left; margin: auto">
                <div style="font-weight: bold; width: 100%">
                  <div
                    style="
                      float: left;
                      width: 50%;
                      border-bottom: 2px solid black;
                    "
                  >
                    B
                  </div>
                  <div
                    style="
                      float: right;
                      width: 50%;
                      text-align: right;
                      border-bottom: 2px solid black;
                    "
                  >
                    {{ license.B }}
                  </div>
                </div>
              </div>
              <div style="width: 80%; text-align: left; margin: auto">
                <div style="font-weight: bold; width: 100%">
                  <div
                    style="
                      float: left;
                      width: 50%;
                      border-bottom: 2px solid black;
                    "
                  >
                    C1
                  </div>
                  <div
                    style="
                      float: right;
                      width: 50%;
                      text-align: right;
                      border-bottom: 2px solid black;
                    "
                  >
                    {{ license.C1 }}
                  </div>
                </div>
              </div>
              <div style="width: 80%; text-align: left; margin: auto">
                <div style="font-weight: bold; width: 100%">
                  <div
                    style="
                      float: left;
                      width: 50%;
                      border-bottom: 2px solid black;
                    "
                  >
                    C2
                  </div>
                  <div
                    style="
                      float: right;
                      width: 50%;
                      text-align: right;
                      border-bottom: 2px solid black;
                    "
                  >
                    {{ license.C2 }}
                  </div>
                </div>
              </div>
              <div style="width: 80%; text-align: left; margin: auto">
                <div style="font-weight: bold; width: 100%">
                  <div
                    style="
                      float: left;
                      width: 50%;
                      border-bottom: 2px solid black;
                    "
                  >
                    Забаланс
                  </div>
                  <div
                    style="
                      float: right;
                      width: 50%;
                      text-align: right;
                      border-bottom: 2px solid black;
                    "
                  >
                    {{ license.out_of_balance }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="column">
            <div class="header">Запасы на начало периода</div>
            <div style="margin-top: 5px">
              <div style="width: 80%; text-align: left; margin: auto">
                <div style="font-weight: bold; width: 100%">
                  <div
                    style="
                      float: left;
                      width: 50%;
                      border-bottom: 2px solid black;
                    "
                  >
                    A
                  </div>
                  <div
                    style="
                      float: right;
                      width: 50%;
                      text-align: right;
                      border-bottom: 2px solid black;
                    "
                  >
                    {{ leftoverFromPrevYearA }}
                  </div>
                </div>
              </div>
              <div style="width: 80%; text-align: left; margin: auto">
                <div style="font-weight: bold; width: 100%">
                  <div
                    style="
                      float: left;
                      width: 50%;
                      border-bottom: 2px solid black;
                    "
                  >
                    B
                  </div>
                  <div
                    style="
                      float: right;
                      width: 50%;
                      text-align: right;
                      border-bottom: 2px solid black;
                    "
                  >
                    {{ leftoverFromPrevYearB }}
                  </div>
                </div>
              </div>
              <div style="width: 80%; text-align: left; margin: auto">
                <div style="font-weight: bold; width: 100%">
                  <div
                    style="
                      float: left;
                      width: 50%;
                      border-bottom: 2px solid black;
                    "
                  >
                    C1
                  </div>
                  <div
                    style="
                      float: right;
                      width: 50%;
                      text-align: right;
                      border-bottom: 2px solid black;
                    "
                  >
                    {{ leftoverFromPrevYearC1 }}
                  </div>
                </div>
              </div>
              <div style="width: 80%; text-align: left; margin: auto">
                <div style="font-weight: bold; width: 100%">
                  <div
                    style="
                      float: left;
                      width: 50%;
                      border-bottom: 2px solid black;
                    "
                  >
                    C2
                  </div>
                  <div
                    style="
                      float: right;
                      width: 50%;
                      text-align: right;
                      border-bottom: 2px solid black;
                    "
                  >
                    {{ leftoverFromPrevYearC2 }}
                  </div>
                </div>
              </div>
              <div style="width: 80%; text-align: left; margin: auto">
                <div style="font-weight: bold; width: 100%">
                  <div
                    style="
                      float: left;
                      width: 50%;
                      border-bottom: 2px solid black;
                    "
                  >
                    Забаланс
                  </div>
                  <div
                    style="
                      float: right;
                      width: 50%;
                      text-align: right;
                      border-bottom: 2px solid black;
                    "
                  >
                    {{ leftoverFromPrevYearZ }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="column">
            <div class="header">Запасы на конец периода</div>
            <div style="margin-top: 5px">
              <div style="width: 80%; text-align: left; margin: auto">
                <div style="font-weight: bold; width: 100%">
                  <div
                    style="
                      float: left;
                      width: 50%;
                      border-bottom: 2px solid black;
                    "
                  >
                    A
                  </div>
                  <div
                    style="
                      float: right;
                      width: 50%;
                      text-align: right;
                      border-bottom: 2px solid black;
                    "
                  >
                    {{
                      parseFloat(
                        new String(leftoverFromPrevYearA).replace(",", ".")
                      ) -
                      parseFloat(new String(miningA).replace(",", ".")) -
                      parseFloat(new String(lossesA).replace(",", ".")) +
                      parseFloat(new String(explorationA).replace(",", ".")) +
                      parseFloat(new String(changeA).replace(",", ".")) +
                      parseFloat(new String(outOfUseA).replace(",", ".")) +
                      parseFloat(new String(otherA).replace(",", "."))
                    }}
                  </div>
                </div>
              </div>
              <div style="width: 80%; text-align: left; margin: auto">
                <div style="font-weight: bold; width: 100%">
                  <div
                    style="
                      float: left;
                      width: 50%;
                      border-bottom: 2px solid black;
                    "
                  >
                    B
                  </div>
                  <div
                    style="
                      float: right;
                      width: 50%;
                      text-align: right;
                      border-bottom: 2px solid black;
                    "
                  >
                    {{
                      parseFloat(
                        new String(leftoverFromPrevYearB).replace(",", ".")
                      ) -
                      parseFloat(new String(miningB).replace(",", ".")) -
                      parseFloat(new String(lossesB).replace(",", ".")) +
                      parseFloat(new String(explorationB).replace(",", ".")) +
                      parseFloat(new String(changeB).replace(",", ".")) +
                      parseFloat(new String(outOfUseB).replace(",", ".")) +
                      parseFloat(new String(otherB).replace(",", "."))
                    }}
                  </div>
                </div>
              </div>
              <div style="width: 80%; text-align: left; margin: auto">
                <div style="font-weight: bold; width: 100%">
                  <div
                    style="
                      float: left;
                      width: 50%;
                      border-bottom: 2px solid black;
                    "
                  >
                    C1
                  </div>
                  <div
                    style="
                      float: right;
                      width: 50%;
                      text-align: right;
                      border-bottom: 2px solid black;
                    "
                  >
                    {{
                      parseFloat(
                        new String(leftoverFromPrevYearC1).replace(",", ".")
                      ) -
                      parseFloat(new String(miningC1).replace(",", ".")) -
                      parseFloat(new String(lossesC1).replace(",", ".")) +
                      parseFloat(new String(explorationC1).replace(",", ".")) +
                      parseFloat(new String(changeC1).replace(",", ".")) +
                      parseFloat(new String(outOfUseC1).replace(",", ".")) +
                      parseFloat(new String(otherC1).replace(",", "."))
                    }}
                  </div>
                </div>
              </div>
              <div style="width: 80%; text-align: left; margin: auto">
                <div style="font-weight: bold; width: 100%">
                  <div
                    style="
                      float: left;
                      width: 50%;
                      border-bottom: 2px solid black;
                    "
                  >
                    C2
                  </div>
                  <div
                    style="
                      float: right;
                      width: 50%;
                      text-align: right;
                      border-bottom: 2px solid black;
                    "
                  >
                    {{
                      parseFloat(
                        new String(leftoverFromPrevYearC2).replace(",", ".")
                      ) -
                      parseFloat(new String(miningC2).replace(",", ".")) -
                      parseFloat(new String(lossesC2).replace(",", ".")) +
                      parseFloat(new String(explorationC2).replace(",", ".")) +
                      parseFloat(new String(changeC2).replace(",", ".")) +
                      parseFloat(new String(outOfUseC2).replace(",", ".")) +
                      parseFloat(new String(otherC2).replace(",", "."))
                    }}
                  </div>
                </div>
              </div>
              <div style="width: 80%; text-align: left; margin: auto">
                <div style="font-weight: bold; width: 100%">
                  <div
                    style="
                      float: left;
                      width: 50%;
                      border-bottom: 2px solid black;
                    "
                  >
                    Забаланс
                  </div>
                  <div
                    style="
                      float: right;
                      width: 50%;
                      text-align: right;
                      border-bottom: 2px solid black;
                    "
                  >
                    {{
                      parseFloat(
                        new String(leftoverFromPrevYearZ).replace(",", ".")
                      ) -
                      parseFloat(new String(miningZ).replace(",", ".")) -
                      parseFloat(new String(lossesZ).replace(",", ".")) +
                      parseFloat(new String(explorationZ).replace(",", ".")) +
                      parseFloat(new String(changeZ).replace(",", ".")) +
                      parseFloat(new String(outOfUseZ).replace(",", ".")) +
                      parseFloat(new String(otherZ).replace(",", "."))
                    }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ul class="nav nav-tabs">
          <li class="nav-item">
            <a
              class="nav-link"
              v-bind:class="selected_A ? 'active' : ''"
              @click="selectTab('A')"
              href="#"
              >A</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              v-bind:class="selected_B ? 'active' : ''"
              @click="selectTab('B')"
              href="#"
              >B</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              v-bind:class="selected_C1 ? 'active' : ''"
              @click="selectTab('C1')"
              href="#"
              >C1</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              v-bind:class="selected_C2 ? 'active' : ''"
              @click="selectTab('C2')"
              href="#"
              >C2</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              v-bind:class="selected_Z ? 'active' : ''"
              @click="selectTab('Z')"
              href="#"
              >Забаланс</a
            >
          </li>
        </ul>
        <div class="tab-A" v-if="selected_A">
          <form class="form-inline">
            <table style="border: none !important">
              <tr>
                <td>
                  <div class="form-group col-xs-3">
                    <label style="font-size: 12px"
                      >Остаток на конец предыдущего года</label
                    >
                    <input
                      type="text"
                      class="form-control form-control-lg"
                      v-model="leftoverFromPrevYearA"
                      @keypress="checkNumber(event, leftoverFromPrevYearA)"
                    />
                  </div>
                </td>
                <td>
                  <div class="form-group col-xs-3">
                    <label style="font-size: 12px">Добыто</label>
                    <input
                      type="text"
                      class="form-control form-control-lg"
                      v-model="miningA"
                      @keypress="checkNumber(event, miningA)"
                    />
                  </div>
                </td>
                <td>
                  <div class="form-group col-xs-3">
                    <label style="font-size: 12px">Потери</label>
                    <input
                      type="text"
                      class="form-control form-control-lg"
                      v-model="lossesA"
                      @keypress="checkNumber(event, lossesA)"
                    />
                  </div>
                </td>
                <td>
                  <div class="form-group col-xs-3">
                    <label style="font-size: 12px">Доразведано</label>
                    <input
                      type="text"
                      class="form-control form-control-lg"
                      v-model="explorationA"
                      @keypress="checkNumber(event, explorationA)"
                    />
                  </div>
                </td>
                <td>
                  <div class="form-group col-xs-3">
                    <label style="font-size: 12px">Изменения</label>
                    <input
                      type="text"
                      class="form-control form-control-lg"
                      v-model="changeA"
                      @keypress="checkNumber(event, changeA)"
                    />
                  </div>
                </td>
                <td>
                  <div class="form-group col-xs-3">
                    <label style="font-size: 12px">Списано</label>
                    <input
                      type="text"
                      class="form-control form-control-lg"
                      v-model="outOfUseA"
                      @keypress="checkNumber(event, outOfUseA)"
                    />
                  </div>
                </td>
                <td>
                  <div class="form-group col-xs-3">
                    <label style="font-size: 12px">Другое</label>
                    <input
                      type="text"
                      class="form-control form-control-lg"
                      v-model="otherA"
                      @keypress="checkNumber(event, otherA)"
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="form-group col-xs-3">
                    <label style="font-size: 12px"
                      >Остаток на конец предыдущего года (полезное
                      ископанмое)</label
                    >
                    <input
                      type="text"
                      class="form-control form-control-lg"
                      v-model="leftoverFromPrevYearPIA"
                      :disabled="!useReservesForMineral"
                    />
                  </div>
                </td>
                <td>
                  <div class="form-group col-xs-3">
                    <label style="font-size: 12px"
                      >Добыто (полезное ископанмое)</label
                    >
                    <input
                      type="text"
                      class="form-control form-control-lg"
                      v-model="miningPIA"
                      @keypress="checkNumber(event, miningPIA)"
                      :disabled="!useReservesForMineral"
                    />
                  </div>
                </td>
                <td>
                  <div class="form-group col-xs-3">
                    <label style="font-size: 12px"
                      >Потери (полезное ископанмое)</label
                    >
                    <input
                      type="text"
                      class="form-control form-control-lg"
                      v-model="lossesPIA"
                      @keypress="checkNumber(event, lossesPIA)"
                      :disabled="!useReservesForMineral"
                    />
                  </div>
                </td>
                <td>
                  <div class="form-group col-xs-3">
                    <label style="font-size: 12px"
                      >Доразведано (полезное ископанмое)</label
                    >
                    <input
                      type="text"
                      class="form-control form-control-lg"
                      v-model="explorationPIA"
                      @keypress="checkNumber(event, explorationPIA)"
                      :disabled="!useReservesForMineral"
                    />
                  </div>
                </td>
                <td>
                  <div class="form-group col-xs-3">
                    <label style="font-size: 12px"
                      >Изменения (полезное ископанмое)</label
                    >
                    <input
                      type="text"
                      class="form-control form-control-lg"
                      v-model="changePIA"
                      @keypress="checkNumber(event, changePIA)"
                      :disabled="!useReservesForMineral"
                    />
                  </div>
                </td>
                <td>
                  <div class="form-group col-xs-3">
                    <label style="font-size: 12px"
                      >Списано (полезное ископанмое)</label
                    >
                    <input
                      type="text"
                      class="form-control form-control-lg"
                      v-model="outOfUsePIA"
                      @keypress="checkNumber(event, outOfUsePIA)"
                      :disabled="!useReservesForMineral"
                    />
                  </div>
                </td>
                <td>
                  <div class="form-group col-xs-3">
                    <label style="font-size: 12px">Другое</label>
                    <input
                      type="text"
                      class="form-control form-control-lg"
                      v-model="otherPIA"
                      @keypress="checkNumber(event, otherPIA)"
                      :disabled="!useReservesForMineral"
                    />
                  </div>
                </td>
              </tr>
            </table>
          </form>
        </div>
        <div class="tab-B" v-if="selected_B">
          <form class="form-inline">
            <table style="border: none !important">
              <tr>
                <td>
                  <div class="form-group col-xs-3">
                    <label style="font-size: 12px"
                      >Остаток на конец предыдущего года</label
                    >
                    <input
                      type="text"
                      class="form-control form-control-lg"
                      v-model="leftoverFromPrevYearB"
                      @keypress="checkNumber(event, leftoverFromPrevYearB)"
                    />
                  </div>
                </td>
                <td>
                  <div class="form-group col-xs-3">
                    <label style="font-size: 12px">Добыто</label>
                    <input
                      type="text"
                      class="form-control form-control-lg"
                      v-model="miningB"
                      @keypress="checkNumber(event, miningB)"
                    />
                  </div>
                </td>
                <td>
                  <div class="form-group col-xs-3">
                    <label style="font-size: 12px">Потери</label>
                    <input
                      type="text"
                      class="form-control form-control-lg"
                      v-model="lossesB"
                      @keypress="checkNumber(event, lossesB)"
                    />
                  </div>
                </td>
                <td>
                  <div class="form-group col-xs-3">
                    <label style="font-size: 12px">Доразведано</label>
                    <input
                      type="text"
                      class="form-control form-control-lg"
                      v-model="explorationB"
                      @keypress="checkNumber(event, explorationB)"
                    />
                  </div>
                </td>
                <td>
                  <div class="form-group col-xs-3">
                    <label style="font-size: 12px">Изменения</label>
                    <input
                      type="text"
                      class="form-control form-control-lg"
                      v-model="changeB"
                      @keypress="checkNumber(event, changeB)"
                    />
                  </div>
                </td>
                <td>
                  <div class="form-group col-xs-3">
                    <label style="font-size: 12px">Списано</label>
                    <input
                      type="text"
                      class="form-control form-control-lg"
                      v-model="outOfUseB"
                      @keypress="checkNumber(event, outOfUseB)"
                    />
                  </div>
                </td>
                <td>
                  <div class="form-group col-xs-3">
                    <label style="font-size: 12px">Другое</label>
                    <input
                      type="text"
                      class="form-control form-control-lg"
                      v-model="otherB"
                      @keypress="checkNumber(event, otherB)"
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="form-group col-xs-3">
                    <label style="font-size: 12px"
                      >Остаток на конец предыдущего года (полезное
                      ископанмое)</label
                    >
                    <input
                      type="text"
                      class="form-control form-control-lg"
                      v-model="leftoverFromPrevYearPIB"
                      @keypress="checkNumber(event, leftoverFromPrevYearPIB)"
                      :disabled="!useReservesForMineral"
                    />
                  </div>
                </td>
                <td>
                  <div class="form-group col-xs-3">
                    <label style="font-size: 12px"
                      >Добыто (полезное ископанмое)</label
                    >
                    <input
                      type="text"
                      class="form-control form-control-lg"
                      v-model="miningPIB"
                      @keypress="checkNumber(event, miningPIB)"
                      :disabled="!useReservesForMineral"
                    />
                  </div>
                </td>
                <td>
                  <div class="form-group col-xs-3">
                    <label style="font-size: 12px"
                      >Потери (полезное ископанмое)</label
                    >
                    <input
                      type="text"
                      class="form-control form-control-lg"
                      v-model="lossesPIB"
                      @keypress="checkNumber(event, lossesPIB)"
                      :disabled="!useReservesForMineral"
                    />
                  </div>
                </td>
                <td>
                  <div class="form-group col-xs-3">
                    <label style="font-size: 12px"
                      >Доразведано (полезное ископанмое)</label
                    >
                    <input
                      type="text"
                      class="form-control form-control-lg"
                      v-model="explorationPIB"
                      @keypress="checkNumber(event, explorationPIB)"
                      :disabled="!useReservesForMineral"
                    />
                  </div>
                </td>
                <td>
                  <div class="form-group col-xs-3">
                    <label style="font-size: 12px"
                      >Изменения (полезное ископанмое)</label
                    >
                    <input
                      type="text"
                      class="form-control form-control-lg"
                      v-model="changePIB"
                      @keypress="checkNumber(event, changePIB)"
                      :disabled="!useReservesForMineral"
                    />
                  </div>
                </td>
                <td>
                  <div class="form-group col-xs-3">
                    <label style="font-size: 12px"
                      >Списано (полезное ископанмое)</label
                    >
                    <input
                      type="text"
                      class="form-control form-control-lg"
                      v-model="outOfUsePIB"
                      @keypress="checkNumber(event, outOfUsePIB)"
                      :disabled="!useReservesForMineral"
                    />
                  </div>
                </td>
                <td>
                  <div class="form-group col-xs-3">
                    <label style="font-size: 12px">Другое</label>
                    <input
                      type="text"
                      class="form-control form-control-lg"
                      v-model="otherPIB"
                      @keypress="checkNumber(event, otherPIB)"
                      :disabled="!useReservesForMineral"
                    />
                  </div>
                </td>
              </tr>
            </table>
          </form>
        </div>
        <div class="tab-C1" v-if="selected_C1">
          <form class="form-inline">
            <table style="border: none !important">
              <tr>
                <td>
                  <div class="form-group col-xs-3">
                    <label style="font-size: 12px"
                      >Остаток на конец предыдущего года</label
                    >
                    <input
                      type="text"
                      class="form-control form-control-lg"
                      v-model="leftoverFromPrevYearC1"
                      @keypress="checkNumber(event, leftoverFromPrevYearC1)"
                    />
                  </div>
                </td>
                <td>
                  <div class="form-group col-xs-3">
                    <label style="font-size: 12px">Добыто</label>
                    <input
                      type="text"
                      class="form-control form-control-lg"
                      v-model="miningC1"
                      @keypress="checkNumber(event, miningC1)"
                    />
                  </div>
                </td>
                <td>
                  <div class="form-group col-xs-3">
                    <label style="font-size: 12px">Потери</label>
                    <input
                      type="text"
                      class="form-control form-control-lg"
                      v-model="lossesC1"
                      @keypress="checkNumber(event, lossesC1)"
                    />
                  </div>
                </td>
                <td>
                  <div class="form-group col-xs-3">
                    <label style="font-size: 12px">Доразведано</label>
                    <input
                      type="text"
                      class="form-control form-control-lg"
                      v-model="explorationC1"
                      @keypress="checkNumber(event, explorationC1)"
                    />
                  </div>
                </td>
                <td>
                  <div class="form-group col-xs-3">
                    <label style="font-size: 12px">Изменения</label>
                    <input
                      type="text"
                      class="form-control form-control-lg"
                      v-model="changeC1"
                      @keypress="checkNumber(event, changeC1)"
                    />
                  </div>
                </td>
                <td>
                  <div class="form-group col-xs-3">
                    <label style="font-size: 12px">Списано</label>
                    <input
                      type="text"
                      class="form-control form-control-lg"
                      v-model="outOfUseC1"
                      @keypress="checkNumber(event, outOfUseC1)"
                    />
                  </div>
                </td>
                <td>
                  <div class="form-group col-xs-3">
                    <label style="font-size: 12px">Другое</label>
                    <input
                      type="text"
                      class="form-control form-control-lg"
                      v-model="otherC1"
                      @keypress="checkNumber(event, otherC1)"
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="form-group col-xs-3">
                    <label style="font-size: 12px"
                      >Остаток на конец предыдущего года (полезное
                      ископанмое)</label
                    >
                    <input
                      type="text"
                      class="form-control form-control-lg"
                      v-model="leftoverFromPrevYearPIC1"
                      @keypress="checkNumber(event, leftoverFromPrevYearPIC1)"
                      :disabled="!useReservesForMineral"
                    />
                  </div>
                </td>
                <td>
                  <div class="form-group col-xs-3">
                    <label style="font-size: 12px"
                      >Добыто (полезное ископанмое)</label
                    >
                    <input
                      type="text"
                      class="form-control form-control-lg"
                      v-model="miningPIC1"
                      @keypress="checkNumber(event, miningPIC1)"
                      :disabled="!useReservesForMineral"
                    />
                  </div>
                </td>
                <td>
                  <div class="form-group col-xs-3">
                    <label style="font-size: 12px"
                      >Потери (полезное ископанмое)</label
                    >
                    <input
                      type="text"
                      class="form-control form-control-lg"
                      v-model="lossesPIC1"
                      @keypress="checkNumber(event, lossesPIC1)"
                      :disabled="!useReservesForMineral"
                    />
                  </div>
                </td>
                <td>
                  <div class="form-group col-xs-3">
                    <label style="font-size: 12px"
                      >Доразведано (полезное ископанмое)</label
                    >
                    <input
                      type="text"
                      class="form-control form-control-lg"
                      v-model="explorationPIC1"
                      @keypress="checkNumber(event, explorationPIC1)"
                      :disabled="!useReservesForMineral"
                    />
                  </div>
                </td>
                <td>
                  <div class="form-group col-xs-3">
                    <label style="font-size: 12px"
                      >Изменения (полезное ископанмое)</label
                    >
                    <input
                      type="text"
                      class="form-control form-control-lg"
                      v-model="changePIC1"
                      @keypress="checkNumber(event, changePIC1)"
                      :disabled="!useReservesForMineral"
                    />
                  </div>
                </td>
                <td>
                  <div class="form-group col-xs-3">
                    <label style="font-size: 12px"
                      >Списано (полезное ископанмое)</label
                    >
                    <input
                      type="text"
                      class="form-control form-control-lg"
                      v-model="outOfUsePIC1"
                      @keypress="checkNumber(event, outOfUsePIC1)"
                      :disabled="!useReservesForMineral"
                    />
                  </div>
                </td>
                <td>
                  <div class="form-group col-xs-3">
                    <label style="font-size: 12px">Другое</label>
                    <input
                      type="text"
                      class="form-control form-control-lg"
                      v-model="otherPIC1"
                      @keypress="checkNumber(event, otherPIC1)"
                      :disabled="!useReservesForMineral"
                    />
                  </div>
                </td>
              </tr>
            </table>
          </form>
        </div>
        <div class="tab-C2" v-if="selected_C2">
          <form class="form-inline">
            <table style="border: none !important">
              <tr>
                <td>
                  <div class="form-group col-xs-3">
                    <label style="font-size: 12px"
                      >Остаток на конец предыдущего года</label
                    >
                    <input
                      type="text"
                      class="form-control form-control-lg"
                      v-model="leftoverFromPrevYearC2"
                      @keypress="checkNumber(event, leftoverFromPrevYearC2)"
                    />
                  </div>
                </td>
                <td>
                  <div class="form-group col-xs-3">
                    <label style="font-size: 12px">Добыто</label>
                    <input
                      type="text"
                      class="form-control form-control-lg"
                      v-model="miningC2"
                      @keypress="checkNumber(event, miningC2)"
                    />
                  </div>
                </td>
                <td>
                  <div class="form-group col-xs-3">
                    <label style="font-size: 12px">Потери</label>
                    <input
                      type="text"
                      class="form-control form-control-lg"
                      v-model="lossesC2"
                      @keypress="checkNumber(event, lossesC2)"
                    />
                  </div>
                </td>
                <td>
                  <div class="form-group col-xs-3">
                    <label style="font-size: 12px">Доразведано</label>
                    <input
                      type="text"
                      class="form-control form-control-lg"
                      v-model="explorationC2"
                      @keypress="checkNumber(event, explorationC2)"
                    />
                  </div>
                </td>
                <td>
                  <div class="form-group col-xs-3">
                    <label style="font-size: 12px">Изменения</label>
                    <input
                      type="text"
                      class="form-control form-control-lg"
                      v-model="changeC2"
                      @keypress="checkNumber(event, changeC2)"
                    />
                  </div>
                </td>
                <td>
                  <div class="form-group col-xs-3">
                    <label style="font-size: 12px">Списано</label>
                    <input
                      type="text"
                      class="form-control form-control-lg"
                      v-model="outOfUseC2"
                      @keypress="checkNumber(event, outOfUseC2)"
                    />
                  </div>
                </td>
                <td>
                  <div class="form-group col-xs-3">
                    <label style="font-size: 12px">Другое</label>
                    <input
                      type="text"
                      class="form-control form-control-lg"
                      v-model="otherC2"
                      @keypress="checkNumber(event, otherC2)"
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="form-group col-xs-3">
                    <label style="font-size: 12px"
                      >Остаток на конец предыдущего года (полезное
                      ископанмое)</label
                    >
                    <input
                      type="text"
                      class="form-control form-control-lg"
                      v-model="leftoverFromPrevYearPIC2"
                      :disabled="!useReservesForMineral"
                      @keypress="checkNumber(event, leftoverFromPrevYearPIC2)"
                    />
                  </div>
                </td>
                <td>
                  <div class="form-group col-xs-3">
                    <label style="font-size: 12px"
                      >Добыто (полезное ископанмое)</label
                    >
                    <input
                      type="text"
                      class="form-control form-control-lg"
                      v-model="miningPIC2"
                      :disabled="!useReservesForMineral"
                      @keypress="checkNumber(event, miningPIC2)"
                    />
                  </div>
                </td>
                <td>
                  <div class="form-group col-xs-3">
                    <label style="font-size: 12px"
                      >Потери (полезное ископанмое)</label
                    >
                    <input
                      type="text"
                      class="form-control form-control-lg"
                      v-model="lossesPIC2"
                      :disabled="!useReservesForMineral"
                      @keypress="checkNumber(event, lossesPIC2)"
                    />
                  </div>
                </td>
                <td>
                  <div class="form-group col-xs-3">
                    <label style="font-size: 12px"
                      >Доразведано (полезное ископанмое)</label
                    >
                    <input
                      type="text"
                      class="form-control form-control-lg"
                      v-model="explorationPIC2"
                      :disabled="!useReservesForMineral"
                      @keypress="checkNumber(event, explorationPIC2)"
                    />
                  </div>
                </td>
                <td>
                  <div class="form-group col-xs-3">
                    <label style="font-size: 12px"
                      >Изменения (полезное ископанмое)</label
                    >
                    <input
                      type="text"
                      class="form-control form-control-lg"
                      v-model="changePIC2"
                      :disabled="!useReservesForMineral"
                      @keypress="checkNumber(event, changePIC2)"
                    />
                  </div>
                </td>
                <td>
                  <div class="form-group col-xs-3">
                    <label style="font-size: 12px"
                      >Списано (полезное ископанмое)</label
                    >
                    <input
                      type="text"
                      class="form-control form-control-lg"
                      v-model="outOfUsePIC2"
                      :disabled="!useReservesForMineral"
                      @keypress="checkNumber(event, outOfUsePIC2)"
                    />
                  </div>
                </td>
                <td>
                  <div class="form-group col-xs-3">
                    <label style="font-size: 12px">Другое</label>
                    <input
                      type="text"
                      class="form-control form-control-lg"
                      v-model="otherPIC2"
                      :disabled="!useReservesForMineral"
                      @keypress="checkNumber(event, otherPIC2)"
                    />
                  </div>
                </td>
              </tr>
            </table>
          </form>
        </div>

        <div class="tab-Z" v-if="selected_Z">
          <form class="form-inline">
            <table style="border: none !important">
              <tr>
                <td>
                  <div class="form-group col-xs-3">
                    <label style="font-size: 12px"
                      >Остаток на конец предыдущего года</label
                    >
                    <input
                      type="text"
                      class="form-control form-control-lg"
                      v-model="leftoverFromPrevYearZ"
                      @keypress="checkNumber(event, leftoverFromPrevYearZ)"
                    />
                  </div>
                </td>
                <td>
                  <div class="form-group col-xs-3">
                    <label style="font-size: 12px">Добыто</label>
                    <input
                      type="text"
                      class="form-control form-control-lg"
                      v-model="miningZ"
                      @keypress="checkNumber(event, miningZ)"
                    />
                  </div>
                </td>
                <td>
                  <div class="form-group col-xs-3">
                    <label style="font-size: 12px">Потери</label>
                    <input
                      type="text"
                      class="form-control form-control-lg"
                      v-model="lossesZ"
                      @keypress="checkNumber(event, lossesZ)"
                    />
                  </div>
                </td>
                <td>
                  <div class="form-group col-xs-3">
                    <label style="font-size: 12px">Доразведано</label>
                    <input
                      type="text"
                      class="form-control form-control-lg"
                      v-model="explorationZ"
                      @keypress="checkNumber(event, explorationZ)"
                    />
                  </div>
                </td>
                <td>
                  <div class="form-group col-xs-3">
                    <label style="font-size: 12px">Изменения</label>
                    <input
                      type="text"
                      class="form-control form-control-lg"
                      v-model="changeZ"
                      @keypress="checkNumber(event, changeZ)"
                    />
                  </div>
                </td>
                <td>
                  <div class="form-group col-xs-3">
                    <label style="font-size: 12px">Списано</label>
                    <input
                      type="text"
                      class="form-control form-control-lg"
                      v-model="outOfUseZ"
                      @keypress="checkNumber(event, outOfUseZ)"
                    />
                  </div>
                </td>
                <td>
                  <div class="form-group col-xs-3">
                    <label style="font-size: 12px">Другое</label>
                    <input
                      type="text"
                      class="form-control form-control-lg"
                      v-model="otherZ"
                      @keypress="checkNumber(event, otherZ)"
                    />
                  </div>
                </td>
              </tr>
              <tr class="table-vert-align">
                <td>
                  <div class="form-group col-xs-3">
                    <label style="font-size: 12px"
                      >Остаток на конец предыдущего года (полезное
                      ископанмое)</label
                    >
                    <input
                      type="text"
                      class="form-control form-control-lg"
                      v-model="leftoverFromPrevYearPIZ"
                      :disabled="!useReservesForMineral"
                      @keypress="checkNumber(event, leftoverFromPrevYearPIZ)"
                    />
                  </div>
                </td>
                <td>
                  <div class="form-group col-xs-3">
                    <label style="font-size: 12px"
                      >Добыто (полезное ископанмое)</label
                    >
                    <input
                      type="text"
                      class="form-control form-control-lg"
                      v-model="miningPIZ"
                      :disabled="!useReservesForMineral"
                      @keypress="checkNumber(event, miningPIZ)"
                    />
                  </div>
                </td>
                <td>
                  <div class="form-group col-xs-3">
                    <label style="font-size: 12px"
                      >Потери (полезное ископанмое)</label
                    >
                    <input
                      type="text"
                      class="form-control form-control-lg"
                      v-model="lossesPIZ"
                      :disabled="!useReservesForMineral"
                      @keypress="checkNumber(event, lossesPIZ)"
                    />
                  </div>
                </td>
                <td>
                  <div class="form-group col-xs-3">
                    <label style="font-size: 12px"
                      >Доразведано (полезное ископанмое)</label
                    >
                    <input
                      type="text"
                      class="form-control form-control-lg"
                      v-model="explorationPIZ"
                      :disabled="!useReservesForMineral"
                      @keypress="checkNumber(event, explorationPIZ)"
                    />
                  </div>
                </td>
                <td>
                  <div class="form-group col-xs-3">
                    <label style="font-size: 12px"
                      >Изменения (полезное ископанмое)</label
                    >
                    <input
                      type="text"
                      class="form-control form-control-lg"
                      v-model="changePIZ"
                      :disabled="!useReservesForMineral"
                      @keypress="checkNumber(event, changePIZ)"
                    />
                  </div>
                </td>
                <td>
                  <div class="form-group col-xs-3">
                    <label style="font-size: 12px"
                      >Списано (полезное ископанмое)</label
                    >
                    <input
                      type="text"
                      class="form-control form-control-lg"
                      v-model="outOfUsePIZ"
                      :disabled="!useReservesForMineral"
                      @keypress="checkNumber(event, outOfUsePIZ)"
                    />
                  </div>
                </td>
                <td>
                  <div class="form-group col-xs-3">
                    <label style="font-size: 12px">Другое</label>
                    <input
                      type="text"
                      class="form-control form-control-lg"
                      v-model="otherPIZ"
                      :disabled="!useReservesForMineral"
                      @keypress="checkNumber(event, otherPIZ)"
                    />
                  </div>
                </td>
              </tr>
            </table>
          </form>
        </div>
        <div class="form-group" style="margin-top: 10px">
          <button @click="close" class="btn btn-dark btn-lg btn-block close">
            Закрыть форму
          </button>
        </div>
        <div class="form-group" style="margin-top: 10px">
          <button
            @click="saveMomvements"
            class="btn btn-dark btn-lg btn-block save"
          >
            Сохранить
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import OkModal from "../components/OkModal.vue";
import Spinner from "../components/Spinner.vue";
import BootstrapIcon from "@dvuckovic/vue3-bootstrap-icons";
import CommentsModal from "../components/CommentsModal.vue";

export default {
  name: "MovementFillModal",
  props: ["license_id", "year"],
  data() {
    return {
      showCommentsModal: false,
      showSpinner: true,
      showError: false,
      errorMessage: "",
      header: "",
      movementsLoaded: false,
      license: { license: "" },
      movement: null,
      selected_A: true,
      selected_B: false,
      selected_C1: false,
      selected_C2: false,
      selected_Z: false,
      useReservesForMineral: true,
      miningA: 0.0,
      miningPIA: 0.0,
      miningB: 0.0,
      miningPIB: 0.0,
      miningC1: 0.0,
      miningPIC1: 0.0,
      miningC2: 0.0,
      miningPIC2: 0.0,
      miningZ: 0.0,
      miningPIZ: 0.0,
      leftoverFromPrevYearA: 0.0,
      leftoverFromPrevYearPIA: 0.0,
      leftoverFromPrevYearB: 0.0,
      leftoverFromPrevYearPIB: 0.0,
      leftoverFromPrevYearC1: 0.0,
      leftoverFromPrevYearPIC1: 0.0,
      leftoverFromPrevYearC2: 0.0,
      leftoverFromPrevYearPIC2: 0.0,
      leftoverFromPrevYearZ: 0.0,
      leftoverFromPrevYearPIZ: 0.0,
      leftoverCurrentYearA: 0.0,
      leftoverCurrentYearPIA: 0.0,
      leftoverCurrentYearB: 0.0,
      leftoverCurrentYearPIB: 0.0,
      leftoverCurrentYearC1: 0.0,
      leftoverCurrentYearPIC1: 0.0,
      leftoverCurrentYearC2: 0.0,
      leftoverCurrentYearPIC2: 0.0,
      leftoverCurrentYearZ: 0.0,
      leftoverCurrentYearPIZ: 0.0,
      lossesA: 0.0,
      lossesPIA: 0.0,
      lossesB: 0.0,
      lossesPIB: 0.0,
      lossesC1: 0.0,
      lossesPIC1: 0.0,
      lossesC2: 0.0,
      lossesPIC2: 0.0,
      lossesZ: 0.0,
      lossesPIZ: 0.0,
      explorationA: 0.0,
      explorationPIA: 0.0,
      explorationB: 0.0,
      explorationPIB: 0.0,
      explorationC1: 0.0,
      explorationPIC1: 0.0,
      explorationC2: 0.0,
      explorationPIC2: 0.0,
      explorationZ: 0.0,
      explorationPIZ: 0.0,
      changeA: 0.0,
      changePIA: 0.0,
      changeB: 0.0,
      changePIB: 0.0,
      changeC1: 0.0,
      changePIC1: 0.0,
      changeC2: 0.0,
      changePIC2: 0.0,
      changeZ: 0.0,
      changePIZ: 0.0,
      outOfUseA: 0.0,
      outOfUsePIA: 0.0,
      outOfUseB: 0.0,
      outOfUsePIB: 0.0,
      outOfUseC1: 0.0,
      outOfUsePIC1: 0.0,
      outOfUseC2: 0.0,
      outOfUsePIC2: 0.0,
      outOfUseZ: 0.0,
      outOfUsePIZ: 0.0,
      otherA: 0.0,
      otherPIA: 0.0,
      otherB: 0.0,
      otherPIB: 0.0,
      otherC1: 0.0,
      otherPIC1: 0.0,
      otherC2: 0.0,
      otherPIC2: 0.0,
      otherZ: 0.0,
      otherPIZ: 0.0,
      showAuthError: false,
      authMessage: "Доступ запрещен",
      authHeader: "Ошибка безопасности",
      depositId: null,
      mineAreaId: null,
      license_description: "",
    };
  },
  methods: {
    checkNumber(evt, value) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 44 && charCode != 45
      ) {
        evt.preventDefault();
      } else {
        if (
          (charCode === 44 || charCode == 46) &&
          (new String(value).includes(",") || new String(value).includes("."))
        ) {
          evt.preventDefault();
        } else {
          return true;
        }
      }
    },
    hideCommentsModal() {
      this.showCommentsModal = false;
    },
    editComments() {
      this.showCommentsModal = true;
    },
    hideAuthMessage() {
      this.showAuthError = false;
    },
    getLicense() {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      var url = this.$BASE_URL + "/api/get_license/";
      axios
        .post(url, { license_id: this.license_id }, { headers })
        .then((response) => {
          if (!response.data.auth_fail) {
            if (response.data.result) {
              this.license = response.data.result;
              this.depositId = this.license.deposit_id;
              this.mineAreaId = this.license.mine_area_id;
              this.getMovement();
            } else {
              this.$emit("movement-close");
            }
          } else {
            this.$emit("movement-close");
          }
        });
    },
    getLicenseDescription() {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      var url = this.$BASE_URL + "/api/get_license_description/";
      axios
        .post(url, { license_id: this.license_id }, { headers })
        .then((response) => {
          if (!response.data.auth_fail) {
            this.license_description = response.data.result.description;
            this.$emit("loaded");
          }
        });
    },
    getMovement() {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      var url = this.$BASE_URL + "/api/get_movement/";
      axios
        .post(
          url,
          { license_id: this.license_id, year: this.year },
          { headers }
        )
        .then((response) => {
          if (!response.data[0].auth_fail) {
            if (response.data[0].result) {
              this.movement = response.data[0].result;
              this.useReservesForMineral =
                this.movement["5gr"]["use_reserves_for_mineral"];
              this.miningA = new String(
                this.movement["5gr"]["movements"]["A"]["mining"]
              ).replace(".", ",");

              this.miningB = new String(
                this.movement["5gr"]["movements"]["B"]["mining"]
              ).replace(".", ",");

              this.miningC1 = new String(
                this.movement["5gr"]["movements"]["C1"]["mining"]
              ).replace(".", ",");

              this.miningC2 = new String(
                this.movement["5gr"]["movements"]["C2"]["mining"]
              ).replace(".", ",");

              this.miningZ = new String(
                this.movement["5gr"]["movements"]["zabalans"]["mining"]
              ).replace(".", ",");

              this.otherA = new String(
                this.movement["5gr"]["movements"]["A"]["other"]
              ).replace(".", ",");

              this.otherB = new String(
                this.movement["5gr"]["movements"]["B"]["other"]
              ).replace(".", ",");

              this.otherC1 = new String(
                this.movement["5gr"]["movements"]["C1"]["other"]
              ).replace(".", ",");

              this.otherC2 = new String(
                this.movement["5gr"]["movements"]["C2"]["other"]
              ).replace(".", ",");

              this.otherZ = new String(
                this.movement["5gr"]["movements"]["zabalans"]["other"]
              ).replace(".", ",");

              this.leftoverFromPrevYearA = new String(
                this.movement["5gr"]["movements"]["A"][
                  "leftover_from_prev_year"
                ]
              ).replace(".", ",");

              this.leftoverFromPrevYearB = new String(
                this.movement["5gr"]["movements"]["B"][
                  "leftover_from_prev_year"
                ]
              ).replace(".", ",");

              this.leftoverFromPrevYearC1 = new String(
                this.movement["5gr"]["movements"]["C1"][
                  "leftover_from_prev_year"
                ]
              ).replace(".", ",");

              this.leftoverFromPrevYearC2 = new String(
                this.movement["5gr"]["movements"]["C2"][
                  "leftover_from_prev_year"
                ]
              ).replace(".", ",");

              this.leftoverFromPrevYearZ = new String(
                this.movement["5gr"]["movements"]["zabalans"][
                  "leftover_from_prev_year"
                ]
              ).replace(".", ",");

              this.leftoverCurrentYearA = new String(
                this.movement["5gr"]["movements"]["A"]["leftover_current_year"]
              ).replace(".", ",");

              this.leftoverCurrentYearB = new String(
                this.movement["5gr"]["movements"]["B"]["leftover_current_year"]
              ).replace(".", ",");

              this.leftoverCurrentYearC1 = new String(
                this.movement["5gr"]["movements"]["C1"]["leftover_current_year"]
              ).replace(".", ",");

              this.leftoverCurrentYearC2 = new String(
                this.movement["5gr"]["movements"]["C2"]["leftover_current_year"]
              ).replace(".", ",");

              this.leftoverCurrentYearZ = new String(
                this.movement["5gr"]["movements"]["zabalans"][
                  "leftover_current_year"
                ]
              ).replace(".", ",");

              this.lossesA = new String(
                this.movement["5gr"]["movements"]["A"]["losses"]
              ).replace(".", ",");

              this.lossesB = new String(
                this.movement["5gr"]["movements"]["B"]["losses"]
              ).replace(".", ",");

              this.lossesC1 = new String(
                this.movement["5gr"]["movements"]["C1"]["losses"]
              ).replace(".", ",");

              this.lossesC2 = new String(
                this.movement["5gr"]["movements"]["C2"]["losses"]
              ).replace(".", ",");

              this.lossesZ = new String(
                this.movement["5gr"]["movements"]["zabalans"]["losses"]
              ).replace(".", ",");

              this.explorationA = new String(
                this.movement["5gr"]["movements"]["A"]["exploration"]
              ).replace(".", ",");

              this.explorationB = new String(
                this.movement["5gr"]["movements"]["B"]["exploration"]
              ).replace(".", ",");

              this.explorationC1 = new String(
                this.movement["5gr"]["movements"]["C1"]["exploration"]
              ).replace(".", ",");

              this.explorationC2 = new String(
                this.movement["5gr"]["movements"]["C2"]["exploration"]
              ).replace(".", ",");

              this.explorationZ = new String(
                this.movement["5gr"]["movements"]["zabalans"]["exploration"]
              ).replace(".", ",");

              this.changeA = new String(
                this.movement["5gr"]["movements"]["A"]["change"]
              ).replace(".", ",");

              this.changeB = new String(
                this.movement["5gr"]["movements"]["B"]["change"]
              ).replace(".", ",");

              this.changeC1 = new String(
                this.movement["5gr"]["movements"]["C1"]["change"]
              ).replace(".", ",");

              this.changeC2 = new String(
                this.movement["5gr"]["movements"]["C2"]["change"]
              ).replace(".", ",");

              this.changeZ = new String(
                this.movement["5gr"]["movements"]["zabalans"]["change"]
              ).replace(".", ",");

              this.outOfUseA = new String(
                this.movement["5gr"]["movements"]["A"]["out_of_use"]
              ).replace(".", ",");

              this.outOfUseB = new String(
                this.movement["5gr"]["movements"]["B"]["out_of_use"]
              ).replace(".", ",");

              this.outOfUseC1 = new String(
                this.movement["5gr"]["movements"]["C1"]["out_of_use"]
              ).replace(".", ",");

              this.outOfUseC2 = new String(
                this.movement["5gr"]["movements"]["C2"]["out_of_use"]
              ).replace(".", ",");

              this.outOfUseZ = new String(
                this.movement["5gr"]["movements"]["zabalans"]["out_of_use"]
              ).replace(".", ",");

              if (this.useReservesForMineral) {
                this.miningPIA = new String(
                  this.movement["5gr"]["movements"]["PI_A"]["mining"]
                ).replace(".", ",");
                this.miningPIB = new String(
                  this.movement["5gr"]["movements"]["PI_B"]["mining"]
                ).replace(".", ",");
                this.miningPIC1 = new String(
                  this.movement["5gr"]["movements"]["PI_C1"]["mining"]
                ).replace(".", ",");
                this.miningPIC2 = new String(
                  this.movement["5gr"]["movements"]["PI_C2"]["mining"]
                ).replace(".", ",");
                this.miningPIZ = new String(
                  this.movement["5gr"]["movements"]["PI_zabalans"]["mining"]
                ).replace(".", ",");
                this.leftoverFromPrevYearPIA = new String(
                  this.movement["5gr"]["movements"]["PI_A"][
                    "leftover_from_prev_year"
                  ]
                ).replace(".", ",");
                this.leftoverFromPrevYearPIB = new String(
                  this.movement["5gr"]["movements"]["PI_B"][
                    "leftover_from_prev_year"
                  ]
                ).replace(".", ",");
                this.leftoverFromPrevYearPIC1 = new String(
                  this.movement["5gr"]["movements"]["PI_C1"][
                    "leftover_from_prev_year"
                  ]
                ).replace(".", ",");
                this.leftoverFromPrevYearPIC2 = new String(
                  this.movement["5gr"]["movements"]["PI_C2"][
                    "leftover_from_prev_year"
                  ]
                ).replace(".", ",");
                this.leftoverFromPrevYearPIZ = new String(
                  this.movement["5gr"]["movements"]["PI_zabalans"][
                    "leftover_from_prev_year"
                  ]
                ).replace(".", ",");
                this.leftoverCurrentYearPIA = new String(
                  this.movement["5gr"]["movements"]["PI_A"][
                    "leftover_current_year"
                  ]
                ).replace(".", ",");
                this.leftoverCurrentYearPIB = new String(
                  this.movement["5gr"]["movements"]["PI_B"][
                    "leftover_current_year"
                  ]
                ).replace(".", ",");
                this.leftoverCurrentYearPIC1 = new String(
                  this.movement["5gr"]["movements"]["PI_C1"][
                    "leftover_current_year"
                  ]
                ).replace(".", ",");
                this.leftoverCurrentYearPIC2 = new String(
                  this.movement["5gr"]["movements"]["PI_C2"][
                    "leftover_current_year"
                  ]
                ).replace(".", ",");
                this.leftoverCurrentYearPIZ = new String(
                  this.movement["5gr"]["movements"]["PI_zabalans"][
                    "leftover_current_year"
                  ]
                ).replace(".", ",");
                this.lossesPIA = new String(
                  this.movement["5gr"]["movements"]["PI_A"]["losses"]
                ).replace(".", ",");
                this.lossesPIB = new String(
                  this.movement["5gr"]["movements"]["PI_B"]["losses"]
                ).replace(".", ",");
                this.lossesPIC1 = new String(
                  this.movement["5gr"]["movements"]["PI_C1"]["losses"]
                ).replace(".", ",");
                this.lossesPIC2 = new String(
                  this.movement["5gr"]["movements"]["PI_C2"]["losses"]
                ).replace(".", ",");
                this.lossesPIZ = new String(
                  this.movement["5gr"]["movements"]["PI_zabalans"]["losses"]
                ).replace(".", ",");
                this.explorationPIA = new String(
                  this.movement["5gr"]["movements"]["PI_A"]["exploration"]
                ).replace(".", ",");
                this.explorationPIB = new String(
                  this.movement["5gr"]["movements"]["PI_B"]["exploration"]
                ).replace(".", ",");
                this.explorationPIC1 = new String(
                  this.movement["5gr"]["movements"]["PI_C1"]["exploration"]
                ).replace(".", ",");
                this.explorationPIC2 = new String(
                  this.movement["5gr"]["movements"]["PI_C2"]["exploration"]
                ).replace(".", ",");
                this.explorationPIZ = new String(
                  this.movement["5gr"]["movements"]["PI_zabalans"][
                    "exploration"
                  ]
                ).replace(".", ",");
                this.changePIA = new String(
                  this.movement["5gr"]["movements"]["PI_A"]["change"]
                ).replace(".", ",");
                this.changePIB = new String(
                  this.movement["5gr"]["movements"]["PI_B"]["change"]
                ).replace(".", ",");
                this.changePIC1 = new String(
                  this.movement["5gr"]["movements"]["PI_C1"]["change"]
                ).replace(".", ",");
                this.changePIC2 = new String(
                  this.movement["5gr"]["movements"]["PI_C2"]["change"]
                ).replace(".", ",");
                this.changePIZ = new String(
                  this.movement["5gr"]["movements"]["PI_zabalans"]["change"]
                ).replace(".", ",");
                this.outOfUsePIA = new String(
                  this.movement["5gr"]["movements"]["PI_A"]["out_of_use"]
                ).replace(".", ",");
                this.outOfUsePIB = new String(
                  this.movement["5gr"]["movements"]["PI_B"]["out_of_use"]
                ).replace(".", ",");
                this.outOfUsePIC1 = new String(
                  this.movement["5gr"]["movements"]["PI_C1"]["out_of_use"]
                ).replace(".", ",");
                this.outOfUsePIC2 = new String(
                  this.movement["5gr"]["movements"]["PI_C2"]["out_of_use"]
                ).replace(".", ",");
                this.outOfUsePIZ = new String(
                  this.movement["5gr"]["movements"]["PI_zabalans"]["out_of_use"]
                ).replace(".", ",");

                this.otherPIA = new String(
                  this.movement["5gr"]["movements"]["PI_A"]["other"]
                ).replace(".", ",");

                this.otherPIB = new String(
                  this.movement["5gr"]["movements"]["PI_B"]["other"]
                ).replace(".", ",");

                this.otherPIC1 = new String(
                  this.movement["5gr"]["movements"]["PI_C1"]["other"]
                ).replace(".", ",");

                this.otherPIC2 = new String(
                  this.movement["5gr"]["movements"]["PI_C2"]["other"]
                ).replace(".", ",");

                this.otherPIZ = new String(
                  this.movement["5gr"]["movements"]["PI_zabalans"]["other"]
                ).replace(".", ",");
              }
              this.movementsLoaded = true;
              this.showSpinner = false;
            } else {
              this.showSpinner = false;
              this.showError = true;
              this.header = "Ошибка сервера";
              this.errorMessage = "Нет результата";
              this.$emit("movement-close");
            }
          } else {
            this.showSpinner = false;
            this.showError = true;
            this.header = "Ошибка авторизации";
            this.errorMessage = "Неверный токен";
            this.$emit("movement-close");
          }
        });
    },
    selectTab(tab) {
      if (tab == "A") {
        this.selected_A = true;
        this.selected_B = false;
        this.selected_C1 = false;
        this.selected_C2 = false;
        this.selected_Z = false;
      }
      if (tab == "B") {
        this.selected_A = false;
        this.selected_B = true;
        this.selected_C1 = false;
        this.selected_C2 = false;
        this.selected_Z = false;
      }
      if (tab == "C1") {
        this.selected_A = false;
        this.selected_B = false;
        this.selected_C1 = true;
        this.selected_C2 = false;
        this.selected_Z = false;
      }
      if (tab == "C2") {
        this.selected_A = false;
        this.selected_B = false;
        this.selected_C1 = false;
        this.selected_C2 = true;
        this.selected_Z = false;
      }
      if (tab == "Z") {
        this.selected_A = false;
        this.selected_B = false;
        this.selected_C1 = false;
        this.selected_C2 = false;
        this.selected_Z = true;
      }
    },
    saveMomvements() {
      if (!this.movementsLoaded) {
        this.$emit("movement-close");
        return;
      }
      this.movement["5gr"]["movements"]["A"]["mining"] = this.miningA;
      this.movement["5gr"]["movements"]["B"]["mining"] = this.miningB;
      this.movement["5gr"]["movements"]["C1"]["mining"] = this.miningC1;
      this.movement["5gr"]["movements"]["C2"]["mining"] = this.miningC2;
      this.movement["5gr"]["movements"]["zabalans"]["mining"] = this.miningZ;
      this.movement["5gr"]["movements"]["A"]["other"] = this.otherA;
      this.movement["5gr"]["movements"]["B"]["other"] = this.otherB;
      this.movement["5gr"]["movements"]["C1"]["other"] = this.otherC1;
      this.movement["5gr"]["movements"]["C2"]["other"] = this.otherC2;
      this.movement["5gr"]["movements"]["zabalans"]["other"] = this.otherZ;
      this.movement["5gr"]["movements"]["A"]["leftover_from_prev_year"] =
        this.leftoverFromPrevYearA;
      this.movement["5gr"]["movements"]["B"]["leftover_from_prev_year"] =
        this.leftoverFromPrevYearB;
      this.movement["5gr"]["movements"]["C1"]["leftover_from_prev_year"] =
        this.leftoverFromPrevYearC1;
      this.movement["5gr"]["movements"]["C2"]["leftover_from_prev_year"] =
        this.leftoverFromPrevYearC2;
      this.movement["5gr"]["movements"]["zabalans"]["leftover_from_prev_year"] =
        this.leftoverFromPrevYearZ;
      this.movement["5gr"]["movements"]["A"]["leftover_current_year"] =
        this.leftoverCurrentYearA;
      this.movement["5gr"]["movements"]["B"]["leftover_current_year"] =
        this.leftoverCurrentYearB;
      this.movement["5gr"]["movements"]["C1"]["leftover_current_year"] =
        this.leftoverCurrentYearC1;
      this.movement["5gr"]["movements"]["C2"]["leftover_current_year"] =
        this.leftoverCurrentYearC2;
      this.movement["5gr"]["movements"]["zabalans"]["leftover_current_year"] =
        this.leftoverCurrentYearZ;
      this.movement["5gr"]["movements"]["A"]["losses"] = this.lossesA;
      this.movement["5gr"]["movements"]["B"]["losses"] = this.lossesB;
      this.movement["5gr"]["movements"]["C1"]["losses"] = this.lossesC1;
      this.movement["5gr"]["movements"]["C2"]["losses"] = this.lossesC2;
      this.movement["5gr"]["movements"]["zabalans"]["losses"] = this.lossesZ;
      this.movement["5gr"]["movements"]["A"]["exploration"] = this.explorationA;
      this.movement["5gr"]["movements"]["B"]["exploration"] = this.explorationB;
      this.movement["5gr"]["movements"]["C1"]["exploration"] =
        this.explorationC1;
      this.movement["5gr"]["movements"]["C2"]["exploration"] =
        this.explorationC2;
      this.movement["5gr"]["movements"]["zabalans"]["exploration"] =
        this.explorationZ;
      this.movement["5gr"]["movements"]["A"]["change"] = this.changeA;
      this.movement["5gr"]["movements"]["B"]["change"] = this.changeB;
      this.movement["5gr"]["movements"]["C1"]["change"] = this.changeC1;
      this.movement["5gr"]["movements"]["C2"]["change"] = this.changeC2;
      this.movement["5gr"]["movements"]["zabalans"]["change"] = this.changeZ;
      this.movement["5gr"]["movements"]["A"]["out_of_use"] = this.outOfUseA;
      this.movement["5gr"]["movements"]["B"]["out_of_use"] = this.outOfUseB;
      this.movement["5gr"]["movements"]["C1"]["out_of_use"] = this.outOfUseC1;
      this.movement["5gr"]["movements"]["C2"]["out_of_use"] = this.outOfUseC2;
      this.movement["5gr"]["movements"]["zabalans"]["out_of_use"] =
        this.outOfUseZ;

      if (this.useReservesForMineral) {
        this.movement["5gr"]["movements"]["PI_A"]["mining"] = this.miningPIA;
        this.movement["5gr"]["movements"]["PI_B"]["mining"] = this.miningPIB;
        this.movement["5gr"]["movements"]["PI_C1"]["mining"] = this.miningPIC1;
        this.movement["5gr"]["movements"]["PI_C2"]["mining"] = this.miningPIC2;
        this.movement["5gr"]["movements"]["PI_zabalans"]["mining"] =
          this.miningPIZ;
        this.movement["5gr"]["movements"]["PI_A"]["leftover_from_prev_year"] =
          this.leftoverFromPrevYearPIA;
        this.movement["5gr"]["movements"]["PI_B"]["leftover_from_prev_year"] =
          this.leftoverFromPrevYearPIB;
        this.movement["5gr"]["movements"]["PI_C1"]["leftover_from_prev_year"] =
          this.leftoverFromPrevYearPIC1;
        this.movement["5gr"]["movements"]["PI_C2"]["leftover_from_prev_year"] =
          this.leftoverFromPrevYearPIC2;
        this.movement["5gr"]["movements"]["PI_zabalans"][
          "leftover_from_prev_year"
        ] = this.leftoverFromPrevYearPIZ;
        this.movement["5gr"]["movements"]["PI_A"]["leftover_current_year"] =
          this.leftoverCurrentYearPIA;
        this.movement["5gr"]["movements"]["PI_B"]["leftover_current_year"] =
          this.leftoverCurrentYearPIB;
        this.movement["5gr"]["movements"]["PI_C1"]["leftover_current_year"] =
          this.leftoverCurrentYearPIC1;
        this.movement["5gr"]["movements"]["PI_C2"]["leftover_current_year"] =
          this.leftoverCurrentYearPIC2;
        this.movement["5gr"]["movements"]["PI_zabalans"][
          "leftover_current_year"
        ] = this.leftoverCurrentYearPIZ;
        this.movement["5gr"]["movements"]["PI_A"]["losses"] = this.lossesPIA;
        this.movement["5gr"]["movements"]["PI_B"]["losses"] = this.lossesPIB;
        this.movement["5gr"]["movements"]["PI_C1"]["losses"] = this.lossesPIC1;
        this.movement["5gr"]["movements"]["PI_C2"]["losses"] = this.lossesPIC2;
        this.movement["5gr"]["movements"]["PI_zabalans"]["losses"] =
          this.lossesPIZ;
        this.movement["5gr"]["movements"]["PI_A"]["exploration"] =
          this.explorationPIA;
        this.movement["5gr"]["movements"]["PI_B"]["exploration"] =
          this.explorationPIB;
        this.movement["5gr"]["movements"]["PI_C1"]["exploration"] =
          this.explorationPIC1;
        this.movement["5gr"]["movements"]["PI_C2"]["exploration"] =
          this.explorationPIC2;
        this.movement["5gr"]["movements"]["PI_zabalans"]["exploration"] =
          this.explorationPIZ;
        this.movement["5gr"]["movements"]["PI_A"]["change"] = this.changePIA;
        this.movement["5gr"]["movements"]["PI_B"]["change"] = this.changePIB;
        this.movement["5gr"]["movements"]["PI_C1"]["change"] = this.changePIC1;
        this.movement["5gr"]["movements"]["PI_C2"]["change"] = this.changePIC2;
        this.movement["5gr"]["movements"]["PI_zabalans"]["change"] =
          this.changePIZ;
        this.movement["5gr"]["movements"]["PI_A"]["out_of_use"] =
          this.outOfUsePIA;
        this.movement["5gr"]["movements"]["PI_B"]["out_of_use"] =
          this.outOfUsePIB;
        this.movement["5gr"]["movements"]["PI_C1"]["out_of_use"] =
          this.outOfUsePIC1;
        this.movement["5gr"]["movements"]["PI_C2"]["out_of_use"] =
          this.outOfUsePIC2;
        this.movement["5gr"]["movements"]["PI_zabalans"]["out_of_use"] =
          this.outOfUsePIZ;
        this.movement["5gr"]["movements"]["PI_A"]["other"] = this.otherPIA;
        this.movement["5gr"]["movements"]["PI_B"]["other"] = this.otherPIB;
        this.movement["5gr"]["movements"]["PI_C1"]["other"] = this.otherPIC1;
        this.movement["5gr"]["movements"]["PI_C2"]["other"] = this.otherPIC2;
        this.movement["5gr"]["movements"]["PI_zabalans"]["other"] =
          this.otherPIZ;
      }
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      var url = this.$BASE_URL + "/api/update_movement/";
      axios
        .post(url, { "5gr": this.movement["5gr"] }, { headers })
        .then((response) => {
          if (response.data[0].auth_fail) {
            this.showAuthError = true;
          } else {
            if (!response.data[0].result) {
              this.showError = true;
              this.header = "Ошибка при обработке запроса";
              this.errorMessage = response.data[0].reason;
            } else {
              this.showError = true;
              this.header = "Запрос обработан";
              this.errorMessage = "Запрос обработан успешно";
            }
          }
        });
    },
    close() {
      this.$emit("movement-close");
    },
    hideErrorMessage() {
      this.showError = false;
    },
  },
  mounted() {
    this.getLicense();
    this.getLicenseDescription();
  },
  components: { OkModal, Spinner, BootstrapIcon, CommentsModal },
};
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s ease;
}

.modal-window {
  border: rgb(169, 255, 202);
  background-color: white;
  position: fixed;
  width: calc(100%);
  height: calc(100%);
  z-index: 100;
}

.header {
  height: 30px;
  width: 100%;
  background-color: rgb(143, 150, 150);
  text-align: center;
  font-weight: bold;
  color: white;
}

.body {
  font-weight: bolder;
  color: black;
  text-align: center;
}

.close {
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.save {
  position: absolute;
  bottom: 10px;
  left: 10px;
}

.nav-link {
  /*color: #42b883;*/
  color: rgb(143, 150, 150);
}

.controls {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 20px;
  height: 60px;
}

.year {
  float: left;
  margin-right: 10px;
  height: 55px;
}

.resources {
  display: flex;
  height: 250px;
  margin: 10px;
}

.column {
  flex: 33%;
  margin: 10px;
}

.year {
  height: 55px;
  border-color: black;
  border-style: solid;
  color: black;
  width: 100px;
  font-weight: bold;
  text-align: center;
}

.comment {
  float: left;
  height: 55px;
  width: 55px;
}

td {
  vertical-align: baseline !important;
}
</style>
