<template>
  <div class="main_content">
    <div class="table">
      <Spinner v-if="showSpinner" />
      <Filter v-on:filter="filterTable" v-bind:filterVal="filter" />
      <SumGroupItem
        v-for="item in items"
        v-bind:key="item.id_group"
        v-bind:group_id="item.id_group"
        v-bind:description="item.group_name"
        v-bind:code="item.group_code"
        v-on:updated="updated"
        v-on:loaded="onItemLoaded"
      />
      <SumGroupNewItem v-on:updated="updated" />
      <Paginator
        v-bind:count="totalItems"
        v-bind:ipp="ipp"
        v-bind:currentPage="page"
        v-on:page-click="changePage"
        v-bind:autoMargin="true"
      />
      <OkModal
        v-if="showAuthError"
        v-bind:message="authMessage"
        v-bind:header="authHeader"
        v-on:confirm="hideAuthMessage"
      />
    </div>
  </div>
</template>

<script>
import axios from "axios";

import OkModal from "../components/OkModal.vue";
import Spinner from "../components/Spinner.vue";
import Paginator from "../components/Paginator.vue";
import Filter from "../components/Filter.vue";
import SumGroupNewItem from "../components/SumGroupNewItem.vue";
import SumGroupItem from "../components/SumGroupItem.vue";

export default {
  name: "SumGroups",
  data() {
    return {
      showSpinner: true,
      showError: false,
      errorMessage: "",
      header: "",
      showAuthError: false,
      authMessage: "Доступ запрещен",
      authHeader: "Ошибка безопасности",
      selected_Mappings: false,
      selected_Groups: true,
      totalItems: 0,
      page: 1,
      ipp: 10,
      filter: "",
      items: [],
      loadedItems: 0,
    };
  },
  methods: {
    countItems() {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      var url = this.$BASE_URL + "/api/get_groups_count/";
      axios
        .post(url, { group_name: this.filter }, { headers })
        .then((response) => {
          if (!response.data[0].auth_fail) {
            this.totalItems = response.data[0].result;
            if (this.totalItems == 0) {
              this.showSpinner = false;
            }
            this.loadItems();
          } else {
            this.showAuthError = true;
          }
        });
    },
    loadItems() {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      var url = this.$BASE_URL + "/api/get_groups/";
      const offset = (this.page - 1) * this.ipp;
      axios
        .post(
          url,
          {
            group_name: this.filter,
            offset: offset,
            limit: this.ipp,
          },
          { headers }
        )
        .then((response) => {
          if (!response.data[0].auth_fail) {
            this.items = response.data[0].result;
          } else {
            this.showAuthError = true;
          }
        });
    },
    onItemLoaded() {
      this.loadedItems++;
      if (this.loadedItems == this.ipp) {
        this.loadedItems = 0;
        this.showSpinner = false;
      }
      if (
        this.loadedItems == 0 &&
        this.totalItems - this.ipp * Math.floor(this.totalItems / this.ipp)
      ) {
        this.loadedItems = 0;
        this.showSpinner = false;
      }
      if (this.loadedItems == this.totalItems) {
        this.loadedItems = 0;
        this.showSpinner = false;
      }
    },
    hideAuthMessage() {
      this.showAuthError = false;
    },
    hideErrorMessage() {
      this.showError = false;
    },
    changePage(o) {
      this.page = o.page;
      this.loadItems();
    },
    updated() {
      this.page = 1;
      this.filter = "";
      this.countItems();
    },
    filterTable(filter) {
      this.page = 1;
      this.filter = filter;
      this.countItems();
    },
  },
  components: {
    OkModal,
    Spinner,
    SumGroupItem,
    SumGroupNewItem,
    Paginator,
    Filter,
  },
  mounted() {
    this.countItems();
  },
};
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s ease;
}

.modal-window {
  border: rgb(169, 255, 202);
  background-color: white;
  position: fixed;
  width: calc(100%);
  height: calc(100%);
  z-index: 100;
}

.header {
  height: 30px;
  width: 100%;
  background-color: rgb(143, 150, 150);
  text-align: center;
  font-weight: bold;
}

.body {
  font-weight: bolder;
  color: black;
  text-align: center;
}

.close {
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.save {
  position: absolute;
  bottom: 10px;
  left: 10px;
}

.nav-link {
  color: #42b883;
}
</style>
