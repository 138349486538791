<template>
  <div class="modal-mask">
    <div class="modal-window">
      <div class="header">Редактирование комментариев</div>
      <div class="body">
        <div class="table">
          <Spinner v-if="showSpinner" />
          <OkModal
            v-if="showAuthError"
            v-bind:message="authMessage"
            v-bind:header="authHeader"
            v-on:confirm="hideAuthMessage"
          />
          <EditCommentModal
            v-if="showEditComment"
            v-on:cancel="closeUpdate"
            v-on:save="updateExisting"
            v-bind:deposit_id="deposit_id"
            v-bind:mine_area_id="mine_area_id"
            v-bind:license_id="license_id"
            v-bind:comment="comment"
            v-bind:column_number="column_number"
            v-bind:year="year"
          />
          <NewCommentModal
            v-if="showAddNewComment"
            v-on:cancel="closeAddNew"
            v-on:save="saveNew"
            v-bind:deposit_id="deposit_id"
            v-bind:mine_area_id="mine_area_id"
            v-bind:license_id="license_id"
            v-bind:year="year"
          />
          <CommentItem
            v-for="item in items"
            v-bind:key="item.license_id"
            v-bind:id_deposit="item.deposit_id"
            v-bind:mine_area_id="item.mine_area_id"
            v-bind:license_id="item.license_id"
            v-bind:comment="item.comment"
            v-bind:column_number="item.column_number"
            v-bind:year="item.year"
            v-on:comment-edited="onEdit"
            v-on:comment-deleted="onDeleted"
          />
          <div class="separator"></div>
          <div class="add">
            <button
              @click="onNew"
              class="btn btn-dark btn-lg btn-block btn-add"
              style="width: 200px"
            >
              <BootstrapIcon icon="plus-square-fill" />
              Добавить
            </button>
          </div>
        </div>
        <div class="form-group" id="form-group3" style="margin-top: 10px">
          <button @click="close" class="btn btn-dark btn-lg btn-block close">
            Закрыть форму
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import BootstrapIcon from "@dvuckovic/vue3-bootstrap-icons";

import NewCommentModal from "../components/NewCommentModal.vue";
import EditCommentModal from "../components/EditCommentModal.vue";
import CommentItem from "../components/CommentItem.vue";
import Spinner from "../components/Spinner.vue";
import OkModal from "./OkModal.vue";

export default {
  name: "CommentsModal",
  props: ["deposit_id", "mine_area_id", "license_id", "year"],
  watch: {},
  data() {
    return {
      items: [],
      totalItems: 0,
      showAddNewComment: false,
      showEditComment: false,
      page: 1,
      ipp: 10,
      filter: "",
      loadedItems: 0,
      showSpinner: true,
      showAuthError: false,
      authMessage: "Доступ запрещен",
      authHeader: "Ошибка безопасности",
    };
  },
  methods: {
    hideAuthMessage() {
      this.showAuthError = false;
    },
    loadItems() {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      var url = this.$BASE_URL + "/api/get_comments_5gr/";
      axios
        .post(
          url,
          {
            deposit_id: this.deposit_id,
            mine_area_id: this.mine_area_id,
            license_id: this.license_id,
            year: this.year,
          },
          { headers }
        )
        .then((response) => {
          if (!response.data[0].auth_fail) {
            this.items = response.data[0].result;
            this.showSpinner = false;
          } else {
            this.showAuthError = true;
          }
        });
    },
    close() {
      this.$emit("comments-close");
    },
    onDeleted() {
      this.items = [];
      this.loadItems();
    },
    onEdit(o) {
      this.comment = o.comment;
      this.column_number = o.column_number;
      this.showEditComment = true;
    },
    onNew() {
      this.showAddNewComment = true;
    },
    closeAddNew() {
      this.showAddNewComment = false;
    },
    saveNew(comment) {
      this.showAddNewComment = false;
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      var url = this.$BASE_URL + "/api/add_comment_5gr/";
      axios.post(url, comment, { headers }).then((response) => {
        if (!response.data[0].auth_fail) {
          this.items = [];
          this.loadItems();
        } else {
          this.showAuthError = true;
        }
      });
    },
    updateExisting(comment) {
      this.showEditComment = false;
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      var url = this.$BASE_URL + "/api/update_comment_5gr/";
      axios.post(url, comment, { headers }).then((response) => {
        if (!response.data[0].auth_fail) {
          this.items = [];
          this.loadItems();
        } else {
          this.showAuthError = true;
        }
      });
    },
    closeUpdate() {
      this.showEditComment = false;
    },
  },
  components: {
    BootstrapIcon,
    EditCommentModal,
    NewCommentModal,
    CommentItem,
    Spinner,
    OkModal,
  },
  mounted() {
    this.showSpinner = true;
    this.loadItems();
  },
};
</script>

<style scoped>
.modal-mask {
  position: fixed;
  display: flex;
  justify-content: center;
  z-index: 9997;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s ease;
}
.modal-window {
  border: rgb(169, 255, 202);
  background-color: white;
  position: fixed;
  width: 75%;
  height: 500px;
  z-index: 100;
  top: 5%;
}

.header {
  height: 30px;
  width: 100%;
  background-color: rgb(143, 150, 150);
  text-align: center;
  font-weight: bold;
}

.body {
  font-weight: bolder;
  color: black;
  text-align: center;
}

.table {
  margin: auto;
  width: calc(100% - 20px);
  height: calc(100% - 20px);
  border-top: none !important;
  border-bottom: none !important;
}
.separator {
  padding: 10px;
  margin-left: 10px;
  border-top: none !important;
  border-bottom: none !important;
}

.add {
  border-top: none !important;
  border-bottom: none !important;
  float: left;
}

#form-group3 {
  margin-top: 10px;
  position: absolute;
  justify-content: right;
  display: flex;
  top: 30rem;
  right: 0.7rem;
  width: 20rem;
}
</style>
