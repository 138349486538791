<template>
  <div class="main_content">
    <OkModal
      v-if="showError"
      v-bind:message="errorMessage"
      v-bind:header="header"
      v-on:confirm="hideErrorMessage"
    />
    <div class="reports">
      <div style="font-weight: bold; text-align: center">Доступные отчеты</div>
      <BalansReportParamsModal
        v-if="showBalansReportParamsModal"
        v-on:report-done="doneWithTheReport"
        v-on:report-cancel="hideReportModal"
      />
      <BalansReportWithProvinceParamsModal
        v-if="showBalansReportWithAreaParamsModal"
        v-on:report-done="doneWithTheReportWithSingleArae"
        v-on:report-cancel="hideReportModal"
      />
      <BalansReportWithGroupParamModal
        v-if="showBalansReportWithGroupParamsModal"
        v-on:report-done="doneWithTheReportWithGroup"
        v-on:report-cancel="hideReportModal"
      />
      <BalansReportWithUsageParamModal
        v-if="showBalansReportWithUsageParamModal"
        v-on:report-done="doneWithTheReportWithUsage"
        v-on:report-cancel="hideReportModal"
      />
      <div class="form-group" style="margin-top: 10px">
        <button
          @click="runStandardBalanseReportModal"
          class="btn btn-dark btn-lg btn-block btn-report1 close tip"
        >
          <BootstrapIcon icon="printer" />
          Балансовый отчет
          <span class="tiptext"
            >Выводит на печать в виде Excel файла балансовый отчет</span
          >
        </button>
      </div>
      <div class="form-group" style="margin-top: 10px">
        <button
          @click="runStandardBalanseReportWithUsageModal"
          class="btn btn-dark btn-lg btn-block btn-report1 close tip"
        >
          <BootstrapIcon icon="printer" />
          Балансовый отчет с направлением использования
          <span class="tiptext"
            >Выводит на печать в виде Excel файла балансовый отчет (все полезные
            ископаемые для данного направление использования)</span
          >
        </button>
      </div>
      <div class="form-group" style="margin-top: 10px">
        <button
          @click="runStandardBalanseReportWithGroupModal"
          class="btn btn-dark btn-lg btn-block btn-report2 close tip"
        >
          <BootstrapIcon icon="printer" />
          Балансовый отчет (группа итогов)
          <span class="tiptext"
            >Выводит на печать в виде Excel файла балансовый отчет для группы
            итогов</span
          >
        </button>
      </div>
      <div class="form-group" style="margin-top: 10px">
        <button
          @click="runBalanseSingleAreaReportModal"
          class="btn btn-dark btn-lg btn-block btn-report3 close tip"
        >
          <BootstrapIcon icon="printer" />
          Балансовый отчет по областям
          <span class="tiptext"
            >Выводит на печать в виде Excel файла балансовый отчет только для
            конкретной области</span
          >
        </button>
      </div>
      <div class="form-group" style="margin-top: 10px">
        <button
          @click="runMSRReportModal"
          class="btn btn-dark btn-lg btn-block btn-report4 close tip"
        >
          <BootstrapIcon icon="printer" />
          Отчет по минерально-сырьевым ресурсам
          <span class="tiptext"
            >Выводит на печать в виде Excel файла минерально-сырьевой
            отчет</span
          >
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import BootstrapIcon from "@dvuckovic/vue3-bootstrap-icons";
import BalansReportParamsModal from "../components/BalansReportParamsModal.vue";
import BalansReportWithProvinceParamsModal from "../components/BalansReportWithProvinceParamsModal.vue";
import BalansReportWithGroupParamModal from "../components/BalansReportWithGroupParamModal.vue";
import BalansReportWithUsageParamModal from "../components/BalansReportWithUsageParamModal.vue";
import OkModal from "../components/OkModal.vue";

export default {
  name: "Reports",
  data() {
    return {
      showError: false,
      header: "Внимание! Ограничение на использование",
      errorMessage: "Данный функционал не доступен в бета версии!",
      loaded: true,
      showBalansReportParamsModal: false,
      showBalansReportWithAreaParamsModal: false,
      showBalansReportWithGroupParamsModal: false,
      showBalansReportWithUsageParamModal: false,
    };
  },
  methods: {
    hideErrorMessage() {
      this.showError = false;
    },
    runStandardBalanseReportWithUsageModal() {
      this.showBalansReportWithUsageParamModal = true;
    },
    runStandardBalanseReportWithGroupModal() {
      this.showBalansReportWithGroupParamsModal = true;
    },
    runBalanseSingleAreaReportModal() {
      this.showBalansReportWithAreaParamsModal = true;
    },
    runMSRReportModal() {
      this.showError = true;
    },
    runStandardBalanseReportModal() {
      this.showBalansReportParamsModal = true;
    },
    doneWithTheReportWithSingleArae() {
      this.showBalansReportWithAreaParamsModal = false;
    },
    doneWithTheReportWithGroup() {
      this.showBalansReportWithGroupParamsModal = false;
    },
    doneWithTheReportWithUsage() {
      this.showBalansReportWithUsageParamModal = false;
    },
    doneWithTheReport() {
      this.showBalansReportParamsModal = false;
      this.showBalansReportWithAreaParamsModal = false;
    },
    hideReportModal() {
      this.showBalansReportParamsModal = false;
      this.showBalansReportWithAreaParamsModal = false;
      this.showBalansReportWithGroupParamsModal = false;
      this.showBalansReportWithUsageParamModal = false;
    },
  },
  components: {
    BalansReportParamsModal,
    BalansReportWithProvinceParamsModal,
    BalansReportWithGroupParamModal,
    BalansReportWithUsageParamModal,
    BootstrapIcon,
    OkModal,
  },
  mounted() {},
};
</script>

<style scoped>
.reports {
  width: calc(90%);
  margin: 0 auto;
}

.form-group {
  display: grid;
  width: 40%;
  margin: 14px auto;
}
.tip {
  position: relative;
  display: inline-block;
}

.tip .tiptext {
  visibility: hidden;
  width: 90%;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: -12px;
  left: 115%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tip .tiptext::after {
  content: " ";
  position: absolute;
  top: 50%;
  right: 100%; /* To the left of the tooltip */
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent #555 transparent transparent;
}

.tip:hover .tiptext {
  visibility: visible;
  opacity: 1;
}
</style>
