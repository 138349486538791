<template>
  <div class="modal-mask">
    <div class="modal-window">
      <Spinner v-if="showSpinner" />
      <OkModal
        v-if="showError"
        v-bind:message="errorMessage"
        v-bind:header="header"
        v-on:confirm="hideErrorMessage"
      />
      <OkModal
        v-if="showAuthError"
        v-bind:message="authMessage"
        v-bind:header="authHeader"
        v-on:confirm="hideAuthMessage"
      />
      <div class="header">
        {{ org }}
      </div>
      <div class="body">
        <form class="form-inline">
          <table style="border: none !important; width: 100%">
            <tr>
              <td>
                <div class="form-group">
                  <label>ИНН</label>
                  <input
                    type="text"
                    class="form-control form-control-lg"
                    v-model="taxnumber"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div class="form-group">
                  <label>Учредитель</label>
                  <input
                    type="text"
                    class="form-control form-control-lg"
                    v-model="owner"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div class="form-group">
                  <label>Адрес</label>
                  <input
                    type="text"
                    class="form-control form-control-lg"
                    v-model="address"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div class="form-group">
                  <label>Контактный номер</label>
                  <input
                    type="text"
                    class="form-control form-control-lg"
                    v-model="phone"
                  />
                </div>
              </td>
            </tr>
            <tr style="width: 100%">
              <td style="width: 100%">
                <div class="form-group" style="width: 100%">
                  <label>Адрес электронной почты</label>
                  <input
                    type="text"
                    class="form-control form-control-lg"
                    style="width: 100%"
                    v-model="email"
                  />
                </div>
              </td>
            </tr>
          </table>
        </form>
        <div class="form-group" style="margin-top: 10px">
          <button @click="save" class="btn btn-dark btn-lg btn-block save">
            Сохранить
          </button>
        </div>
        <div class="form-group" style="margin-top: 10px">
          <button @click="close" class="btn btn-dark btn-lg btn-block close">
            Закрыть
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

import OkModal from "./OkModal.vue";
import Spinner from "../components/Spinner.vue";

export default {
  name: "OrgInfoModal",
  props: ["org", "id"],
  data() {
    return {
      phone: "",
      email: "",
      taxnumber: "",
      address: "",
      owner: "",
      showError: false,
      errorMessage: "",
      header: "Ошибка при сохранение данных",
      showSpinner: true,
      showAuthError: false,
      authMessage: "Доступ запрещен",
      authHeader: "Ошибка безопасности",
      items: [],
      neededNumberOfCommits: 5,
    };
  },
  methods: {
    hideErrorMessage() {
      this.showError = false;
    },
    hideAuthMessage() {
      this.showAuthError = false;
    },
    getInfo() {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      var url = this.$BASE_URL + "/api/get_info/";
      axios.post(url, { id: this.id }, { headers }).then((response) => {
        if (!response.data[0].auth_fail) {
          this.items = response.data[0].result;
          if (this.items.length == 0) {
            this.showSpinner = false;
          }
          for (var i in this.items) {
            if (this.items[i]["key"] == "address") {
              this.address = this.items[i]["value"];
            }
            if (this.items[i]["key"] == "owner") {
              this.owner = this.items[i]["value"];
            }
            if (this.items[i]["key"] == "phone") {
              this.phone = this.items[i]["value"];
            }
            if (this.items[i]["key"] == "email") {
              this.email = this.items[i]["value"];
            }
            if (this.items[i]["key"] == "taxnumber") {
              this.taxnumber = this.items[i]["value"];
            }
          }
          this.showSpinner = false;
        }
      });
    },

    saveInfo(key, value, description) {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      const url = this.$BASE_URL + "/api/update_info/";
      axios
        .post(
          url,
          {
            id: this.id,
            key: key,
            value: value,
            description: description,
          },
          { headers }
        )
        .then((response) => {
          if (!response.data[0].auth_fail) {
            this.numberOfCommits++;
            if (this.numberOfCommits == this.neededNumberOfCommits) {
              this.$emit("info-close", {});
            }
          } else {
            this.showAuthError = true;
          }
        });
    },
    close(e) {
      this.$emit("info-close", {});
      e.preventDefault();
    },
    save(e) {
      this.numberOfCommits = 0;
      this.saveInfo("phone", this.phone, "Контактный телефон");
      this.saveInfo("email", this.email, "Адрес электронной почты");
      this.saveInfo("taxnumber", this.taxnumber, "ИНН");
      this.saveInfo("address", this.address, "Адрес");
      this.saveInfo("owner", this.owner, "Учредитель");
      e.preventDefault();
    },
  },
  mounted() {
    this.getInfo();
  },
  components: {
    OkModal,
    Spinner,
  },
};
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s ease;
}

.modal-window {
  border: rgb(169, 255, 202);
  background-color: white;
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -350px;
  margin-left: -250px;
  width: 500px;
  height: 500px;
  z-index: 100;
}

.header {
  height: 30px;
  width: 100%;
  background-color: rgb(143, 150, 150);
  text-align: center;
  font-weight: bold;
}

.body {
  font-weight: bolder;
  color: black;
  text-align: center;
}

.close {
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.save {
  position: absolute;
  bottom: 10px;
  left: 10px;
}
</style>
