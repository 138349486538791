<template>
  <div>
    <div>
      <div class="body">
        <OkModal
          v-if="showAuthError"
          v-bind:message="authMessage"
          v-bind:header="authHeader"
          v-on:confirm="hideAuthMessage"
        />
        <Spinner v-if="showSpinner" />
        <div style="margin: 20px">Импорт данных будет произведен на {{new Date().getFullYear()}} год</div>
        <div style="margin: 20px">Перед импортом следует удалить все месторождения из базы</div>
        <input
          class="form-control form-control-lg"
          style="float: left; width: 50%; margin-left: 30px;"
          type="file"
          id="file"
          ref="file"
        />
        <button @click="handleFileUpload()" style="float:left; margin-left: 40px;" class="btn btn-dark btn-lg btn-block btn-add">
          Произвести импорт данных на {{new Date().getFullYear()}} год
        </button>
        <br/>
        <div class="errors">
          <div class="error" v-for="e in errors" v-bind:key="e">
            {{e}}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import axios from "axios";
import Spinner from "../components/Spinner.vue";
import OkModal from "../components/OkModal.vue";
//import BootstrapIcon from "@dvuckovic/vue3-bootstrap-icons";

export default {
  name: "Import",
  props: [],
  data() {
    return {
      files: [],
      failed: false,
      file: null,
      showSpinner: false,
      showAuthError: false,
      authMessage: "Не выбран файл",
      authHeader: "Ошибка при импорте данных"
    };
  },
  methods: {
    handleFileUpload() {
      this.file = this.$refs.file.files[0];
      if (!this.file) {
        this.showAuthError = true;
      } else {
        this.showSpinner = true;
        this.uploadFile();
      }
    },
    hideAuthMessage() {
      this.showAuthError = false;
    },
    uploadFile() {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + token,
      };

      const formData = new FormData();
      formData.append("file", this.file);
      const url =
        this.$BASE_URL +
        "/api/import/";
      axios.post(url, formData, { headers }).then((response) => {
        if (!response.data[0].auth_fail) {
          this.errors = response.data[0]["errors"];
        }
        this.showSpinner = false;
        this.$refs.file.value = null;
      });
    },
  },
  components: {
    //BootstrapIcon,
    Spinner,
    OkModal,
  },
  mounted() {
    this.showSpinner = false;
  },
};

</script>

<style scoped>
.modal-mask {
  position: fixed;
  display: flex;
  z-index: 9997;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s ease;
}

.modal-window {
  border: rgb(169, 255, 202);
  background-color: white;
  position: fixed;
  width: 1040px;
  height: 600px;
  z-index: 100;
  top: 20%;
}

.body {
  font-weight: bolder;
  color: black;
  text-align: center;
}

.close {
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.save {
  position: absolute;
  bottom: 10px;
  left: 10px;
}
.errors {
  float: left;
  color: rgb(94, 0, 0);
  text-align: left;
  margin-left: 50px;
}
</style>
