import { createApp } from "vue";
import App from "./App.vue";
import "bootstrap/dist/css/bootstrap.min.css";
import "@/assets/css/main.css";
import router from "./router";

const app = createApp(App);
//app.config.globalProperties["$BASE_URL"] = "https://balans.strangebit.io";
app.config.globalProperties["$BASE_URL"] = "https://balans.uzgeolcom.uz";
//app.config.globalProperties["$BASE_URL"] = "http://localhost:5006";
//app.config.globalProperties["$BASE_URL"] = "http://194.93.25.247:443";
app.use(router);
app.mount("#app");
