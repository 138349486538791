<template>
  <div style="width: 100%">
    <input
      type="text"
      id="item"
      class="form-control form-control-lg"
      v-model="currentValue"
      aria-label="Номер лицензии"
      style="width: 100%; float: right"
      @input="onSearch()"
    />
    <!-- div class="searchbox" style="height: 100px;">

        </div -->
  </div>
</template>

<script>
//import axios from "axios";

export default {
  name: "DropDownSearchBox",
  props: ["items", "maxHeight"],
  watch: {},
  data() {
    return {
      selectedItemId: null,
      selectedItemValue: null,
      currentValue: "",
    };
  },
  methods: {
    onSearch() {},
  },
};
</script>

<style scoped>
.searchbox {
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: 50px;
  border: 1px solid;
  border-color: black;
}

.searchbox:hover {
}
</style>
