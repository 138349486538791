<template>
  <div class="modal-mask">
    <div class="modal-window">
      <Spinner v-if="showSpinner" />
      <OkModal
        v-if="showError"
        v-bind:message="errorMessage"
        v-bind:header="header"
        v-on:confirm="hideErrorMessage"
      />
      <div class="header">Параметры для запуска балансового запроса</div>
      <div class="body">
        <form class="form-inline">
          <table style="border: none !important; width: 100%">
            <tr>
              <td>
                <div class="form-group col-xs-12">
                  <label>Отчетный год</label>
                  <select
                    class="form-select form-control-lg"
                    v-model="selectedReportYear"
                    aria-label="Год"
                  >
                    <option
                      v-for="year in years"
                      v-bind:value="year"
                      v-bind:key="year"
                    >
                      {{ year }}
                    </option>
                  </select>
                </div>
              </td>
            </tr>
            <!-- tr>
              <td>
                <div class="form-group col-xs-12">
                  <label>Направление использования</label>
                  <select
                    class="form-select form-control-lg"
                    v-model="selectedUsage"
                    aria-label="Направление использования"
                    @change="onUsageChanged()"
                  >
                    <option
                      v-for="usage in usages"
                      v-bind:value="usage.id"
                      v-bind:key="usage.description"
                    >
                      {{ usage.description }}
                    </option>
                  </select>
                </div>
              </td>
            </tr -->
            <tr>
              <td>
                <div class="form-group col-xs-12">
                  <label>Группы итогов</label>

                  <autocomplete 
                      :items="groups" 
                      :search-by="'group_name'" 
                      :search-value="'id_group'" 
                      v-on:select="(ev) => onSelectAutocomplete(ev, 'group_id')">
                    </autocomplete>

                  <select
                    class="form-select form-control-lg"
                    v-model="selectedGroupId"
                    aria-label="Группы итогов"
                  >
                    <option
                      v-for="group in groups"
                      v-bind:value="group.id_group"
                      v-bind:key="group.group_name"
                    >
                      {{ group.group_name }}
                    </option>
                  </select>
                </div>
              </td>
            </tr>
          </table>
          <div class="form-group" style="margin-top: 10px">
            <button
              @click="xlsExport"
              class="btn btn-dark btn-lg btn-block excel"
            >
              Экспорт в Excel
            </button>
          </div>
          <!--div class="form-group" style="margin-top: 10px">
            <button
              @click="htmlExport"
              class="btn btn-dark btn-lg btn-block html"
            >
              HTML
            </button>
          </div -->
          <div class="form-group" style="margin-top: 10px">
            <button @click="close" class="btn btn-dark btn-lg btn-block close">
              Отменить
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import OkModal from "../components/OkModal.vue";
import Spinner from "../components/Spinner.vue";
import Autocomplete from "./Autocomplete.vue";
import { toRaw } from "vue";

export default {
  name: "BalansReportWithGroupParamModal",
  props: [],
  data() {
    return {
      selectedComponent: null,
      selectedUsage: null,
      selectedGroupId: null,
      components: [],
      usages: [],
      groups: [],
      showBalansReportParamsModal: false,
      showError: false,
      header: "Параметры для конструктора отчетов",
      errorMessage: "",
      failed: false,
      showSpinner: false,
      selectedReportYear: new Date().getFullYear(),
      /*years: [2000, 2001, 2002, 2003, 2004, 2005, 2006, 2007, 
        2008, 2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016, 
        2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026,
        2027, 2028, 2029, 2030, 2031, 2032, 2033, 2034, 2035, 2036,
        2037, 2038, 2039, 2040, 2041, 2042, 2043, 2044, 2045, 2046],*/
      years: [new Date().getFullYear()],
    };
  },
  methods: {
    onUsageChanged() {
      this.selectGroups();
    },
    hideModal() {},
    hideErrorMessage() {
      this.showError = false;
      this.$emit("report-done");
    },
    close(e) {
      this.showBalansReportParamsModal = false;
      this.$emit("report-cancel");
      e.preventDefault();
    },
    xlsExport(e) {
      this.showSpinner = true;
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };

      var url = this.$BASE_URL + "/api/balans_report_with_group_xlsx/";

      axios
        .post(
          url,
          {
            group_id: this.selectedGroupId,
            year: this.selectedReportYear,
          },
          { headers }
        )
        .then((response) => {
          if (!response.data[0].auth_fail) {
            if (response.data[0].result) {
              var filename = response.data[0].result.filename;

              this.showSpinner = false;
              this.header = "Результаты выполнения запроса";
              this.showError = true;
              this.errorMessage = "Отчет готов";

              var url =
                this.$BASE_URL + "/api/download_xlsx_report/" + filename;

              axios
                .get(url, {
                  headers: headers,
                  responseType: "blob",
                })
                .then((response) => {
                  const link = document.createElement("a");
                  link.href = window.URL.createObjectURL(
                    new Blob([response.data])
                  );
                  link.setAttribute("download", "report.xlsx");
                  document.body.appendChild(link);
                  link.click();
                });
            } else {
              this.showSpinner = false;
              this.header = "Ошибка при выполнение запроса";
              this.showError = true;
              this.errorMessage = response.data[0].reason;
            }
          }
        });
      e.preventDefault();
    },
    selectUsage() {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      var url = this.$BASE_URL + "/api/get_dictionary_contents/";
      axios
        .post(
          url,
          { category: "SPR_PRIM", limit: 10000, offset: 0 },
          { headers }
        )
        .then((response) => {
          if (!response.data.auth_fail) {
            if (!response.data.result) {
              this.showError = true;
              this.errorMessage = response.data.reason;
            } else {
              this.usages = response.data.result;
            }
          }
        });
    },
    selectGroups() {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      var url = this.$BASE_URL + "/api/get_groups/";
      axios.post(url, { limit: 100000 }, { headers }).then((response) => {
        if (!response.data[0].auth_fail) {
          if (!response.data[0].result) {
            this.showError = true;
            this.errorMessage = response.data[0].reason;
          } else {
            this.groups = response.data[0].result;
          }
        }
      });
    },
    onSelectAutocomplete(selected, searchedVar) {
      switch (searchedVar) {
        case 'group_id':
          this.selectedGroupId = toRaw(selected).id_group;
          break;
        default:
          break;
      }
    }
  },
  components: {
    OkModal,
    Spinner,
    Autocomplete
  },
  mounted() {
    this.selectUsage();
    this.selectGroups();
  },
};
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 100009;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s ease;
}

.modal-window {
  border: rgb(169, 255, 202);
  background-color: white;
  position: fixed;
  width: 400px;
  height: 400px;
  top: 50%;
  left: 50%;
  margin-top: -100px;
  margin-left: -200px;
  z-index: 1000092;
}

.header {
  height: 30px;
  width: 100%;
  background-color: rgb(143, 150, 150);
  text-align: center;
  font-weight: bold;
}

.message {
  font-weight: bolder;
  color: black;
  text-align: center;
}

.close {
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.excel {
  position: absolute;
  bottom: 10px;
  left: 10px;
}

.html {
  position: absolute;
  bottom: 10px;
  left: 30px;
}

td {
  margin-left: 100px !important;
}
table {
  border-spacing: 20px !important;
}
</style>
