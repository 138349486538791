<template>
  <div class="main_content">
    <div class="licenses">
      <div style="font-weight: bold; text-align: center">Список лицензий</div>
      <LicensesTable v-if="loaded" />
    </div>
  </div>
</template>

<script>
import LicensesTable from "../components/LicensesTable.vue";

export default {
  name: "Licenses",
  data() {
    return {
      loaded: true,
    };
  },
  methods: {},
  components: {
    LicensesTable,
  },
  mounted() {},
};
</script>

<style scoped>
.licenses {
  width: 100%;
  height: 100%;
  float: left;
  display: block;
  margin-left: 0px;
  margin-right: 0px;
}
</style>
